import * as pdfjs from 'pdfjs-dist/webpack';

/* editableimagearray directive */
angular.module('editableimagearray',[]).directive('editableimagearray', ['ngDraggable', function (ngDraggable) {
    return {
		scope: { images: '=' },
		restrict: 'C',
		templateUrl: assetsURL+'directives/imageeditor/templates/editableimagearray.html?t='+RootSeed,
		link: function (scope, elem, attrs) {			
			scope.unsetImage = function(imageid) {
				for( var i=0, found = false; !found && i<scope.images.length;i++ ) {
					if( scope.images[i].id === imageid ) {
						found = true;
						scope.images.splice(i,1);
					}
				}
			};
			scope.addNewImage = function(imageid, caption) {
				if( scope.images.length >= 20  ) { return; }
				scope.images.push({id:imageid, caption:caption});
			};
            scope.onDropComplete = function (index, obj, evt) {
                var otherObj = scope.images[index];
                var otherIndex = scope.images.indexOf(obj);
                scope.images[index] = obj;
                scope.images[otherIndex] = otherObj;
            }
		}
	};
}]);


/* editableimage directive */
angular.module('editableimage',[]).directive('editableimage', ['$uibModal','$timeout', function ($uibModal, $timeout) {
    return {
		scope: { imageid : '=',  caption: '=',  height:'@', imagewidth: '@', imageheight:'@', posturl:'@', cancelaction:'&', okaction:'&',  unsetaction:'&', mode:'@', canunset:'@', cancaption:'@', hidedroparea:'=?' },
		restrict: 'C',
		templateUrl: assetsURL+'directives/imageeditor/templates/editableimage.html?t='+RootSeed,
		link: function (scope, elem, attrs) {
			scope.hidedroparea = (scope.hidedroparea === undefined ? false : scope.hidedroparea );
			scope.lastmodified = (new Date()).getTime();
			scope.$watchCollection('[imageid, lastmodified]', function(value) {
				if( scope.imageid !== null) {
					//var val = 'urlappURL+'/utils/images/' + scope.imageid +'?'+scope.lastmodified+')';
					var val = "url('"+ImageBucket + scope.imageid + (scope.lastmodified ? "?"+scope.lastmodified:'')+"')";
					$timeout( function() {
						$(elem.find(".editableimage-thumbnail")[0]).css({
							'background-image': val,
							'background-size' : 'contain',
							'background-repeat' : 'no-repeat'
						});
					},1);
				}
			});
        	scope.error = null;
        	scope.editcaption = false;
			// Pick imagefile
			scope.pickImage = function(event) { 
				$timeout( function() { (elem.find("input:file"))[0].click(); },0 ); 
			};
			scope.unsetImage = function() {
				if( scope.unsetaction() ) { 
					scope.unsetaction(); 
				} else {
					scope.imageid=null;
				}
			};
			// open modal for caption edit
		  	scope.editCaption = function() {
				var modalInstance = $uibModal.open({
					templateUrl: assetsURL+'directives/imageeditor/templates/texteditorModal.html?t='+RootSeed,
					controller: ['$scope', '$uibModalInstance', 'text', function (scope, $uibModalInstance, text) {
						scope.data = {text: text};
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.ok = function() {
							$uibModalInstance.close(scope.data.text);
						};
					}],
					
					resolve: {
						text: function () { return scope.caption; }
					}
				});
				modalInstance.result.then(
					function successCallback(text) {
						scope.caption = text;
					}, function errorCallback() {}
				);
		  	};
			// Open editableimage modal for edit
			scope.editImage = function( imageUrl, pdfimage ) {
				var modalInstance = $uibModal.open({
					windowClass: 'imageeditormodal',
					keyboard : false,
					templateUrl: assetsURL+'directives/imageeditor/templates/imageeditorModal.html?t='+RootSeed,
					controller: ['$scope', '$uibModalInstance', 'imageid', 'imageurl', 'imagewidth', 'imageheight', 'posturl', 'mode','pdfimage', function (scope, $uibModalInstance, imageid, imageurl, imagewidth, imageheight, posturl, mode, pdfimage ) {
						scope.data = {	imageid: imageid, 
										imageurl: imageurl,
										imagewidth: imagewidth || 1600, 
										imageheight: imageheight || 1600,
										posturl: posturl || appURL+'/utils/images',
										mode: mode || 1,
										pdfimage : pdfimage };
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.ok = function() {
							$uibModalInstance.close(scope.data.imageid);
							if( scope.okaction && scope.okaction() ) { scope.okaction(); }
						};
					}],
					
					resolve: {
						imageid: function () { return scope.imageid; },
						imageurl: function() { return imageUrl; },
						imagewidth: function () { return scope.imagewidth; },
						imageheight: function () { return scope.imageheight; },
						posturl: function () { return scope.posturl; },
						mode: function () { return scope.mode; },
						pdfimage: function () { return pdfimage; }
					}
				});
				modalInstance.result.then(
					function successCallback(imageid) {
						if( scope.okaction && scope.okaction() ) { 
							scope.okaction()(imageid, '');
						} else {
							scope.imageid = imageid;
							scope.lastmodified = (new Date()).getTime();
						}
					}, function errorCallback() {}
				);
			};
			// Check if image has been picked or dropped and pass it to editImage
	        scope.prepareImage = function(event) {
	        	var file = null;
				if( event.dataTransfer ) { // drag and drop
		        	if( !event.dataTransfer.files ) return;
		        	if( !event.dataTransfer.files.length ) return;
					file = event.dataTransfer.files[0];
	        	} else if( event.target ) { // select file
		        	if( !event.target.files ) return;
		        	if( !event.target.files.length ) return;
					file = event.target.files[0];
	        	}
	        	
				if( file ) { // new selected or dropped file
					if( file.type=="image/jpeg" || file.type=="image/png") {
						var reader = new FileReader();
						reader.onloadend = function() {
							scope.editImage(reader.result);
						}
						scope.$apply(function () {
							reader.readAsDataURL(file);
						});
					} else if( file.type=="application/pdf" ) {
						var reader = new FileReader();
						reader.onloadend = function() {
							scope.editImage(reader.result, true);
						}
						scope.$apply(function () {
							reader.readAsArrayBuffer(file);
						});
	        		} else {
						alert("Il documento dev'essere nel formato PDF, JPEG o PNG");
					}
				} 
			};
			
			// Bind change event to input file and drag&drop to drop area
			elem.find("input:file").bind('change', scope.prepareImage);
			var droptarget = (elem.find('.editableimage-droparea'))[0];
			droptarget.addEventListener("dragover", function(e){
				e.preventDefault();
				elem.find('.editableimage-droparea').addClass('dragover');
			}, true);
			droptarget.addEventListener("dragleave", function(e){
				e.preventDefault();
				elem.find('.editableimage-droparea').removeClass('dragover');
			}, true);
			droptarget.addEventListener("drop", function(e){
				e.preventDefault();
				var files = e.dataTransfer.files||e.target.files;
				elem.find('.editableimage-droparea').removeClass('dragover');
				if( files && files.length ) {
					scope.prepareImage(e);
				} 
			}, true);
		}
	};
}]);
	   
/* imageeditor directive */
angular.module( 'imageeditor',[] ).directive('imageeditor', ['$http','$uibModal','$window','$timeout','$rootScope', function ($http, $uibModal, $window, $timeout, $rootScope) {
    return {
      scope: { imageid : '=', imageurl : '=', imagewidth: '@', imageheight:'@', posturl:'@', mode:'@', cancelaction:'&', okaction:'&', pdfimage: '='},
      restrict: 'AEC',
	  templateUrl: assetsURL+'directives/imageeditor/templates/imageeditor.html?t='+RootSeed,
      link: function (scope, elem, attrs) {
	    scope.spinwheelDiv = elem.find("#spinwheel");
	    scope.hintDiv = elem.find("#tooltip");
      	//scope.colors = ['255,255,255', '0,0,0', '200,200,200', '255,0,0', '0,255,0', '0,0,255', '255,255,0', '128,0,128'];
      	scope.richColors = [['100,179,223', '156,225,89', '255,224,97', '255,192,114', '255,95,94', '157,69,184'], ['73,155,201', '110,192,56', '241,209,48', '255,169,58','255,45,33','108,32,133'], ['54,125,162', '121,174,61', '226,184,1', '236,159,46', '207,35,43', '85,19,108'], ['23,87,120', '87,135,38', '198,147,0', '209,127,21', '174,25,22', '60,10,73'], ['255,255,255', '191,191,191', '127,127,127', '64,64,64', '32,32,32', '0,0,0']];
      	scope.linewidths = [1,2,5,10,20];
      	scope.linedashes = [{name:'-----------' ,dash:[5,0]}, {name:'- - - - - -' ,dash:[5,5]},{name:'. . . . . .' ,dash:[1,5]}];
      	scope.opacities = [0.125,0.25,0.375,0.50,0.625,0.75,0.875,1.0];
      	scope.tools = [ { name:'Selezione' , icon:'imgeditor-puntatore',hint:'Fai doppio click su un testo per modificarlo'},{ name:'Pan' , icon:'imgeditor-sposta' }, {  name:'Zoom' , icon:'imgeditor-cerca' }, {  name:'Ritaglio' , icon:'imgeditor-ritaglia' }, {  name:'Ruota' , icon:'imgeditor-ruota' }, { name:'Elimina' , icon:'imgeditor-cestina'}, { name:'Linea' , icon:'imgeditor-linea', hint:'Tieni premuto il tasto sinistro del mouse mentre ti sposti, rilascia per terminare. Tieni premuto il tasto Alt per disegnare una spezzata e rilascialo per terminare'}, { name:'Rettangolo' , icon:'imgeditor-rettangolo',hint:'Tieni premuto il tasto sinistro del mouse mentre ti sposti, rilascia per terminare. Tieni premuto il tasto Shift per disegnare un quadrato'}, { name:'Ellisse' , icon:'imgeditor-ellisse',hint:'Tieni premuto il tasto sinistro del mouse mentre ti sposti, rilascia per terminare. Tieni premuto il tasto Shift per disegnare un cerchio'}, { name:'Poligono' , icon:'imgeditor-forma', hint:'Tieni premuto il tasto sinistro del mouse mentre ti sposti, rilascia per terminare. Per terminare il poligono clicca sul punto iniziale o clicca sull\'ultimo vertice tendendo premuto il tasto Alt'}, { name:'Simbolo' , icon:'imgeditor-simbolo'}, { name:'Cartello' , icon:'txteditor-immagine'}, { name:'Testo' , icon:'imgeditor-casella-testo'}];
      	scope.fontsizes = [8,10,12,14,16,18,20,25,30];
      	scope.fonttypes = ['Arial','Times'];
      	scope.symbolscales = [25,50,75,125,150,200];
      	scope.symbols = [];
      	
      	scope.edit = { selection: {tool:scope.tools[0], border:{width:1, color:scope.richColors[4][5], dash:scope.linedashes[0].dash }, fill:{color:scope.richColors[4][0], opacity:1}, font:{type:'Arial', size:12, color:scope.richColors[4][5]}}, edit:{}, inAction: false, actionPoints:[], panClass : 'btn btn-primary', zoomClass : 'btn btn-default',  mouseDown: false, text:'Inserisci testo'};
		scope.type='websitefiles';
		scope.remoteShapes = undefined;
		scope.shapes = [];
		scope.limit=40;
		scope.multiselection = false;
      scope.image = null;
      scope.rotationHintAlredayDisplayed = false;
      scope.imagetype = 'image/jpeg';
      scope.imageCoords = {posX: 0.0, posY: 0.0, scale: 1.0, originX: 0.0, originY: 0.0, width:0.0, height:0.0};
      scope.mouseCoords = {posX: 0.0, posY: 0.0};
      scope.mouseClickTime = (new Date()).getTime();
      scope.canvas = null;
      scope.progress = 1;
      scope.uploading=false;
      scope.xhr = null;
      scope.tags = [];
	  	scope.error = undefined;
	  	
	  	scope.createCORSRequest = function() {
			var xhr = new XMLHttpRequest();
			if ("withCredentials" in xhr) {
				// Check if the XMLHttpRequest object has a "withCredentials" property.
				// "withCredentials" only exists on XMLHTTPRequest2 objects.
				return xhr;
			} else if (typeof XDomainRequest != "undefined") {
				// Otherwise, check if XDomainRequest.
				// XDomainRequest only exists in IE, and is IE's way of making CORS requests.
				xhr = new XDomainRequest();
				return xhr;
			} 
			return null;
		};
			  	
		scope.addSymbolFromShape = function(shape) {
		  	// Same mehtod as main canvas to avoid tainted canvas (crossdomain issue)
	        var xhr = scope.createCORSRequest();
			xhr.onload = function () {
				var url = URL.createObjectURL(this.response);
				var img = new Image();
			    img.onload = function() {
				    var ratio = (scope.imageCoords.scale*scope.image.width);
				    var maxsize = 100;
				    var width, height;

					scope.symbols[shape.url]=img;
					if( shape.points.length == 1 ) {
					    width = (img.width>img.height ? maxsize : img.width*maxsize/img.height);
					    height = (img.height>img.width ? maxsize : img.height*maxsize/img.width);
						shape.points[0].x = -width*0.5/ratio;
						shape.points[0].y = -height*0.5/ratio;
						shape.points.push({x:width*0.5/ratio, y:height*0.5/ratio});
						shape.srcwidth=width;
						shape.srcheight=height;
					}
					scope.updateCanvas(true);		
			    };
				img.src = url;
			};
			xhr.open('GET', ImageBucket+shape.url+'?t='+RootSeed, true);
			xhr.responseType = 'blob';
			xhr.send();		    
	  	};
	  	scope.copySymbol = function() {
		  if( scope.currentShape == null || scope.currentShape.type !== 'Simbolo' ) { return;}
		  var newShape = angular.copy(scope.currentShape);
		  var oldWidth =  scope.currentShape.points[1].x-scope.currentShape.points[0].x;
		  var oldHeight =  scope.currentShape.points[1].y-scope.currentShape.points[0].y;
		  var midPoint = {x: 0, y: 0};
		  var newPoint0 = {x : midPoint.x-oldWidth*0.5, y: midPoint.y-oldHeight*0.5};
		  var newPoint1 = {x : midPoint.x+oldWidth*0.5, y: midPoint.y+oldHeight*0.5};
		  newShape.points[0] = {x: newPoint0.x, y: newPoint0.y};
		  newShape.points[1] = {x: newPoint1.x, y: newPoint1.y};
		  scope.shapes.push(newShape);
		  scope.updateCanvas( true );
	  	};
		scope.bringToFrontSymbol = function() {
		  if( scope.currentShape == null || scope.currentShape.type !== 'Simbolo' ) { return;}
		  var symbolIndex = scope.shapes.indexOf(scope.currentShape);
		  
		  for( var i=symbolIndex+1; i<scope.shapes.length; i++ ) {
			  if( scope.shapes[i].type === 'Simbolo' ) {
				  var tmpObject = angular.copy(scope.shapes[i]);
				  scope.shapes[i] = scope.currentShape;
				  scope.shapes[symbolIndex]=tmpObject;
				  break;
			  }
		  }
		  scope.updateCanvas( true );
		}
		scope.bringToBackSymbol = function() {
		  if( scope.currentShape == null || scope.currentShape.type !== 'Simbolo' ) { return;}
		  var symbolIndex = scope.shapes.indexOf(scope.currentShape);
		  
		  for( var i=symbolIndex-1; i>=0; i-- ) {
			  if( scope.shapes[i].type === 'Simbolo' ) {
				  var tmpObject = angular.copy(scope.shapes[i]);
				  scope.shapes[i] = scope.currentShape;
				  scope.shapes[symbolIndex]=tmpObject;
				  break;
			  }
		  }
		  scope.updateCanvas( true );
		}
	  	scope.rotateSymbol = function(direction) {
		  if( scope.currentShape == null || scope.currentShape.type !== 'Simbolo' ) { return;}
		  if( scope.rotationHintAlredayDisplayed === false ) {
			  scope.rotationHintAlredayDisplayed = true;
	    	scope.hintDiv.html("Tieni premuto Alt per aumentare la velocità di rotazione");
		    scope.hintDiv.css({bottom:'50px'});
	    	$timeout( function() {
		    	scope.hintDiv.css({bottom:'-100px'});
	    	}, 3000);
		  }
		  var magnitude = event.altKey ? 10 : 1;
		  scope.currentShape.rotation += -1*direction*magnitude;
		  var oldWidth =  scope.currentShape.points[1].x-scope.currentShape.points[0].x;
		  var oldHeight =  scope.currentShape.points[1].y-scope.currentShape.points[0].y;
		  var midPoint = {x: (scope.currentShape.points[0].x+scope.currentShape.points[1].x)/2, y: (scope.currentShape.points[0].y+scope.currentShape.points[1].y)/2};
		  var newPoint0 = {x : midPoint.x-oldWidth*0.5, y: midPoint.y-oldHeight*0.5};
		  var newPoint1 = {x : midPoint.x+oldWidth*0.5, y: midPoint.y+oldHeight*0.5};
		  scope.currentShape.points[0] = {x: newPoint0.x, y: newPoint0.y};
		  scope.currentShape.points[1] = {x: newPoint1.x, y: newPoint1.y};
		  scope.updateCanvas( true );
	  	};
	  	scope.scaleSymbol = function( scale ) {
		  if( scope.currentShape == null || scope.currentShape.type !== 'Simbolo' ) { return;}
		  var oldWidth =  scope.currentShape.points[1].x-scope.currentShape.points[0].x;
		  var oldHeight =  scope.currentShape.points[1].y-scope.currentShape.points[0].y;
		  var midPoint = {x: (scope.currentShape.points[0].x+scope.currentShape.points[1].x)/2, y: (scope.currentShape.points[0].y+scope.currentShape.points[1].y)/2};
		  var newPoint0 = {x : midPoint.x-oldWidth*0.5*scale/100, y: midPoint.y-oldHeight*0.5*scale/100};
		  var newPoint1 = {x : midPoint.x+oldWidth*0.5*scale/100, y: midPoint.y+oldHeight*0.5*scale/100};
		  scope.currentShape.points[0] = {x: newPoint0.x, y: newPoint0.y};
		  scope.currentShape.points[1] = {x: newPoint1.x, y: newPoint1.y};
		  scope.updateCanvas( true );
	  	};
	  	scope.editText = function() {
			var modalInstance = $uibModal.open({
				templateUrl: assetsURL+'directives/imageeditor/templates/texteditorModal.html?t='+RootSeed,
				controller: ['$scope', '$uibModalInstance', 'text', function (scope, $uibModalInstance, text) {
					scope.data = {text: text};
					scope.cancel = function () {
						$uibModalInstance.dismiss('cancel');
					};
					scope.ok = function() {
						$uibModalInstance.close(scope.data.text);
					};
				}],
				
				resolve: {
					text: function () { return scope.currentShape.text; }
				}
			});
			modalInstance.result.then(
				function successCallback(text) {
					scope.currentShape.text = text;
					scope.updateCanvas(true);
				}, function errorCallback() {}
			);
	  	};
	  	scope.editKeyPlanObject = function(object) {
		  	if( !object ) { return; }
			var modalInstance = $uibModal.open({
				templateUrl: assetsURL+'directives/imageeditor/templates/editkeyplanobjectModal.html?t='+RootSeed,
				controller: ['$scope', '$uibModalInstance', 'object', function(scope, $uibModalInstance, object) {
					scope.data = {isNew: object.description===undefined, description: (object.description ? object.description : '')};
					scope.delete = function () {
						$uibModalInstance.close(null);
					};
					scope.cancel = function () {
						$uibModalInstance.dismiss('cancel');
					};
					scope.ok = function() {
						$uibModalInstance.close(scope.data.description);
					};
				}],
				
				resolve: {
					object: function () { return object; }
				}
			});
			modalInstance.result.then(
				function successCallback(description) {
					if( description === null ) {
						object.description = undefined;
					} else {
						object.description = description;
					}
				}, function errorCallback() {}
			);
	  	};
		// open modal for symbol picking
	  	scope.insertSymbol = function(isSign) {
		  	var symbolType = (isSign === true ? 'signs' : 'symbols');
			var modalInstance = $uibModal.open({
				templateUrl: assetsURL+'directives/imageeditor/templates/symbolpickerModal.html?t='+RootSeed,
				controller: ['$scope', '$uibModalInstance', function (scope, $uibModalInstance) {
					scope.selectedsymbolindex = -1;
					scope.selectedcategory = null;
					scope.loading = true;
					scope.categories = [];
					scope.symbols = [];
					scope.ImageBucket = ImageBucket;
					scope.selectcategory = function(idx) {
						if( idx>=0 && idx<scope.categories.length ) {
							if( scope.selectedcategory == scope.categories[idx]._id ) { // deselect
								scope.selectedcategory = null;
								return;
							}
							scope.selectedcategory = scope.categories[idx]._id;
							$http.get(appURL+'/crud/'+symbolType+'?category='+scope.selectedcategory ).then( 
								function successCallback(response) {
									scope.symbols = response.data;
									$timeout(function() {scope.loading=false;},1);
								}, function errorCallback(response) {
									alert("Errore generico. Riprova.");
									$uibModalInstance.dismiss('cancel');
								}
							);
						}
					};
					scope.selectsymbol = function(idx) {
						if( idx>=0 && idx<scope.symbols.length ) {
							scope.selectedsymbolindex = idx;
						}
					};
					scope.getcategories = function() {
						$http.get(appURL+'/crud/'+symbolType+'categories').then( 
							function successCallback(response) {
								var data=response.data;
								scope.categories = data;
								if( scope.categories.length ) {
									//scope.selectedcategory = scope.categories[0]._id;
									scope.loading = false;
								} else {
									alert("Nessuna categoria di simboli");
									$uibModalInstance.dismiss('cancel');
								}
							}, function errorCallback(response) {
								alert("Errore generico. Riprova.");
								$uibModalInstance.dismiss('cancel');
							}
						);
					};
					
					scope.cancel = function () {
						$uibModalInstance.dismiss('cancel');
					};
					scope.ok = function() {
						var symbol = scope.selectedsymbolindex != -1 ? scope.symbols[scope.selectedsymbolindex] : null;
						$uibModalInstance.close(symbol);
					};
					scope.getcategories();
				}],
				
				resolve: {}
			});
			modalInstance.result.then(
				function successCallback(symbol) {
		      		var currentShape={type:'Simbolo', border: angular.copy(scope.edit.selection.border), fill: angular.copy(scope.edit.selection.fill), points:[{x:0, y:0}], url:symbol.image, name: symbol.name, rotation: 0 };
		      		scope.shapes.push(currentShape);
		      		scope.addSymbolFromShape(currentShape);
				  	scope.edit.selection.tool = scope.tools[0];
				}, function errorCallback() {}
			);
	  	};
		scope.isClean = function() {		
			if( scope.remoteShapes === undefined ) return false;
			return angular.equals(scope.remoteShapes, scope.shapes);
		};
      scope.cancel = function() {
			$rootScope.instantsupport.inimage=false;
	      if( scope.canvas ) {
				var ctx = scope.canvas.getContext("2d");
				ctx.clearRect(0,0,scope.canvas.width, scope.canvas.height);
			}
			scope.image = null;
			scope.edit = { selection: {tool:scope.tools[0], border:{width:1, color:'0,0,0', dash:[5,0] }, fill:{color:'255,255,255', opacity:1}, font:{type:'Arial', size:12, color:'0,0,0'}}, edit:{}, inAction: false, actionPoints:[], panClass : 'btn btn-primary', zoomClass : 'btn btn-default', mouseDown: false};
			scope.imageCoords = {posX: 0.0, posY: 0.0, scale: 1.0};
			scope.mouseCoords = {posX: 0.0, posY: 0.0};
			scope.xhr = null;
			scope.uploading=false;
			scope.error = undefined;
			if( scope.cancelaction && scope.cancelaction() ) { scope.cancelaction(); }
      };
      scope.abort = function() {
			if( scope.xhr ) {
				scope.xhr.abort();
				scope.uploading=false;
				scope.progress = 1;
				scope.error = undefined;
			}
		};
   	scope.changeTool = function( tool ) {
	    	scope.edit.selection.tool = tool;
	    	if( tool.hint !== undefined ) {
		    	scope.hintDiv.html(tool.hint);
			    scope.hintDiv.css({bottom:'50px'});
		    	$timeout( function() {
			    	scope.hintDiv.css({bottom:'-100px'});
		    	}, 3000);
			} else {
		    	scope.hintDiv.html('');
			}
	    	if( tool.name=='Ruota' ) {
		    	scope.rotateImage();
	    	} else if( tool.name=='Simbolo' ) {
		    	scope.insertSymbol();
	    	} else if( tool.name=='Cartello' ) {
		    	scope.insertSymbol(true);
	    	}else if( tool.name=='Elimina' ) {
				if( scope.currentShape ) {
					var idx = scope.shapes.indexOf(scope.currentShape);
					if( idx != -1 ) {
						scope.currentShape = undefined;
						scope.inAction = false;
						scope.hasMoved = false;
						scope.shapes.splice(idx, 1);
						scope.updateCanvas(true);
						
					}
				}
				scope.edit.selection.tool = scope.tools[0];
	    	}
   	};
   	scope.doMouseDown = function( event ) {
   		scope.mouseIsDown = true;
   		// PLEASE NOTE: All shapes uses a reference point system X->[-0.5,0.5] and Y->[-0.5,0.5] in order to correctly redraw after window resizes
   		
   		scope.mouseCoords = scope.getNormalizedCoords( event );
   		var lastMouseClickTime =  scope.mouseClickTime;
   		scope.mouseClickTime = (new Date()).getTime();
      	var ctx = scope.canvas.getContext('2d');
      	var newPoint ={x: scope.mouseCoords.x, y: scope.mouseCoords.y};
      	if( scope.edit.selection.tool.name=="Pan" || scope.edit.selection.tool.name=="Zoom" ) {
	      	return;
      	}
      	if( scope.edit.selection.tool.name=="Selezione" ) {
      		scope.currentHandle = undefined;
      		if( scope.currentShape ) {
	      		for( var i=scope.currentShape.points.length-1;i>=0;i-- ) {
		      		var curPoint = scope.currentShape.points[i];
		      		if( ((newPoint.x-curPoint.x)*(newPoint.x-curPoint.x)+(newPoint.y-curPoint.y)*(newPoint.y-curPoint.y))<(20/(scope.canvas.width*scope.canvas.width)) ) {
			      		scope.currentHandle = scope.currentShape.points[i];
			      		break;
		      		}
	      		}
	      		if( scope.currentHandle ) { 
	      			return; 
	      		}
      		}
      		var lastSelectedShape = scope.currentShape;
      		var shapeWasAlreadySelected = (scope.currentShape ? true : false); 
      		scope.currentShape = undefined;
      		for( var i=scope.shapes.length-1; i>=0; i-- ) {	      			
      			if( scope.currentShape ) { 
      				scope.shapes[i].selected=false; 
      			} else {
	      			var shape = scope.shapes[i];
	      			var found = false;
	      			
		      		switch(shape.type) {
		      			case "Testo":
		      				if( newPoint.x>=shape.points[1].x && newPoint.x<=(shape.points[1].x+shape.width) && newPoint.y>=(shape.points[1].y) && newPoint.y<=(shape.points[1].y+shape.height) ) {
			      				found = true;
		      				}
		      				break;
		      			case "Linea":
			      			if( newPoint.x>=Math.min(shape.points[0].x,shape.points[1].x) && newPoint.x <=Math.max(shape.points[0].x, shape.points[1].x) && newPoint.y>= Math.min(shape.points[0].y, shape.points[1].y) && newPoint.y <= Math.max(shape.points[0].y, shape.points[1].y) ) {
			      				var dst = (shape.points[1].x-shape.points[0].x)*(newPoint.y-shape.points[0].y)-(shape.points[1].y-shape.points[0].y)*(newPoint.x-shape.points[0].x);
			      				dst /= Math.sqrt((shape.points[1].x-shape.points[0].x)*(shape.points[1].x-shape.points[0].x)+(shape.points[1].y-shape.points[0].y)*(shape.points[1].y-shape.points[0].y));
			      				found = (Math.abs(dst) <= (3/scope.image.width) ? true : false);
							}
		      				break;
		      			case "Ellisse":
		      				var size = {rx: Math.abs(shape.points[1].x-shape.points[0].x)*0.5, ry: Math.abs(shape.points[1].y-shape.points[0].y)*0.5};
		      				var center = {x:(shape.points[0].x+shape.points[1].x)*0.5, y: (shape.points[0].y+shape.points[1].y)*0.5};
		      				var dst = (newPoint.x-center.x)*(newPoint.x-center.x)/ (size.rx*size.rx)+(newPoint.y-center.y)*(newPoint.y-center.y)/(size.ry*size.ry);
		      				found = (dst <=1 ? true : false);
		      				break;
		      			case "Poligono":
		      				var j=shape.points.length-1;
		      				var oddNodes = false;
		      				
		      				for( var l=0;l<shape.points.length;l++ ) {
								if ((shape.points[l].y < newPoint.y && shape.points[j].y >= newPoint.y || shape.points[j].y < newPoint.y && shape.points[l].y >= newPoint.y) && (shape.points[l].x <= newPoint.x || shape.points[j].x <= newPoint.x)) {
									oddNodes ^= (shape.points[l].x + (newPoint.y-shape.points[l].y ) /(shape.points[j].y - shape.points[l].y )*(shape.points[j].x - shape.points[l].x ) < newPoint.x); 
								}
								j=l; 
							}
							found = (oddNodes ? true : false);
								break;
			      		case "Rettangolo":
			      		case "Simbolo":
			      			if( newPoint.x>=Math.min(shape.points[0].x,shape.points[1].x) && newPoint.x <=Math.max(shape.points[0].x, shape.points[1].x) && newPoint.y>= Math.min(shape.points[0].y, shape.points[1].y) && newPoint.y <= Math.max(shape.points[0].y, shape.points[1].y) ) {
			      				found = true;
			      			}
			      			break;
			      		default:
			      			break;
		      		}
		      		
		      		if( found ) {
		      			scope.currentShape = scope.shapes[i];
		      			shape.selected = true;
		      			if( scope.currentShape.type == 'Testo' && scope.currentShape == lastSelectedShape ) {
		      				if( (scope.mouseClickTime - lastMouseClickTime) < 200 ) {
				      			scope.editText();
					  			return;
					  		}
		      			}
		      			if( !shapeWasAlreadySelected ) {
		      				
		      				scope.edit.selection.savedBorder = angular.copy( scope.edit.selection.border ); 
			  				scope.edit.selection.savedFill = angular.copy( scope.edit.selection.fill );
		      			}
			  			scope.$apply(function() {
		      				scope.edit.selection.border = angular.copy(scope.currentShape.border);
			  				scope.edit.selection.fill = angular.copy(scope.currentShape.fill);
			  				if( scope.currentShape.font ) {
			  					scope.edit.selection.font = angular.copy(scope.currentShape.font);
			  				}
			  			});
		      		}
		      		if( scope.currentShape === undefined ) { shape.selected=false; }
		      	}
      		}
      		if( shapeWasAlreadySelected && scope.currentShape === undefined ) {
	  			scope.$apply(function() {
      				scope.edit.selection.border = angular.copy(scope.edit.selection.savedBorder);
	  				scope.edit.selection.fill = angular.copy(scope.edit.selection.savedFill);
	  			});
      		}
	  		scope.updateCanvas(true);
	      	return;
      	}
  		if( !scope.edit.inAction ) { 
  			scope.edit.inAction=true;
  			scope.edit.hasMoved = false;
  			scope.currentImageData = ctx.getImageData(0,0, scope.canvas.width, scope.canvas.height);
      		scope.currentShape={type:scope.edit.selection.tool.name, border: angular.copy(scope.edit.selection.border), fill: angular.copy(scope.edit.selection.fill), points:[{x:scope.mouseCoords.x, y:scope.mouseCoords.y}] };
  			if( scope.edit.selection.tool.name=="Testo" ) {
  				scope.edit.hasMoved = true;
	  			scope.currentShape['text'] = 'Nuovo testo';
	  			
	  			scope.currentShape['font'] = { size:scope.edit.selection.font.size, type:scope.edit.selection.font.type, color: scope.edit.selection.font.color};
   				scope.currentShape.points.push({x:scope.currentShape.points[0].x, y: scope.currentShape.points[0].y });
	      		ctx.putImageData(scope.currentImageData, 0,0);
	      		ctx.save();
		  		scope.setShapeContext(ctx);
		  		scope.renderShape(scope.currentShape);
		  		ctx.restore();
  			}
  		} else {
	  		if( scope.edit.hasMoved == true ) {
		  		scope.edit.hasMoved = false;
		  		scope.currentShape.points.pop();
	  		}
      		if( event.shiftKey ) { // force square
      			switch(scope.currentShape.type) {
      				case 'Rettangolo':
      				case 'Simbolo':
      				case 'Ellisse':
      				case 'Ritaglio':
			      		var offsetX = newPoint.x-scope.currentShape.points[scope.currentShape.points.length-1].x;
			      		var offsetY = (newPoint.y-scope.currentShape.points[scope.currentShape.points.length-1].y);
			      		var delta = Math.min(Math.abs(offsetX), Math.abs(offsetY));
				  		newPoint.x = scope.currentShape.points[scope.currentShape.points.length-1].x + delta*(offsetX>=0?1:-1);
				  		newPoint.y = scope.currentShape.points[scope.currentShape.points.length-1].y + delta*(offsetY>=0?1:-1);
				  		break;
				  	case 'Poligono':
				  	case 'Linea':
			      		var offsetX = newPoint.x-scope.currentShape.points[scope.currentShape.points.length-1].x;
			      		var offsetY = (newPoint.y-scope.currentShape.points[scope.currentShape.points.length-1].y);
			      		var delta = Math.min(Math.abs(offsetX), Math.abs(offsetY));
				  		newPoint.x = Math.abs(offsetY) < Math.abs(offsetX) ? newPoint.x : scope.currentShape.points[scope.currentShape.points.length-1].x;
				  		newPoint.y = Math.abs(offsetX) < Math.abs(offsetY) ? newPoint.y : scope.currentShape.points[scope.currentShape.points.length-1].y;
				  		break;
	      			default:
	      				break;
      			}
      		}
      		/*
      		newPoint.x = Math.min(Math.max(-0.5, newPoint.x),0.5);
      		newPoint.y = Math.min(Math.max(-0.5, newPoint.y),0.5);*/
	  		scope.currentShape.points.push(newPoint);
      		ctx.putImageData(scope.currentImageData, 0,0);
      		ctx.save();
	  		scope.setShapeContext(ctx);
	  		scope.renderShape(scope.currentShape);
	  		ctx.restore();
  		}
   	};
   	
   	scope.doMouseUp = function( event ) { 
   		scope.mouseIsDown = false;
   		if( scope.edit.selection.tool.name=="Pan" ||  scope.edit.selection.tool.name=="Zoom") {
      		return;
   		}
   		switch(scope.edit.selection.tool.name) {
   			case 'Selezione':
   				scope.edit.inAction=false;
  				break;
  			case 'Rettangolo':
   				scope.edit.inAction=false;
  				if( scope.currentShape.points.length==2 ) 
  				{
   					scope.currentShape.points = [{x:Math.min(scope.currentShape.points[0].x, scope.currentShape.points[1].x), y:Math.min(scope.currentShape.points[0].y, scope.currentShape.points[1].y)}, {x:Math.max(scope.currentShape.points[0].x, scope.currentShape.points[1].x), y:Math.max(scope.currentShape.points[0].y, scope.currentShape.points[1].y)}];
  				} else {
	  				scope.currentShape = undefined;
  				}
  				break;	  			
  			case 'Linea':
  			case 'Ellisse':
   				scope.edit.inAction=false;
  				if( scope.currentShape.points.length!=2 ) {
	  				scope.currentShape = undefined;
  				} 
   				break;
  			case 'Testo':
   				scope.edit.inAction=false;
  				break;
  			case 'Poligono':
  				if( scope.currentShape.points.length>1 ) {
  					var p1=scope.currentShape.points[0];
  					var p2=scope.currentShape.points[scope.currentShape.points.length-1];
	  				if( ((p2.x-p1.x)*(p2.x-p1.x)+(p2.y-p1.y)*(p2.y-p1.y))<(20/(scope.canvas.width*scope.canvas.width)) || event.altKey ) {
   						scope.edit.inAction=false;
	  				}
  				} 
  				break;
      		case 'Ritaglio':
      			if( scope.currentShape.points.length==2 ) {
	      			if( confirm('Sei sicuro di voler rifilare l\'immagine?') ) {
				  		scope.cropImage();
	      				scope.edit.inAction=false;
			      		scope.currentShape = undefined;
				  		scope.edit.hasMoved = false;
				  		scope.edit.selection.tool = scope.tools[0];
	      			}
      			} 
   				scope.edit.inAction=false;
	      		scope.currentShape = undefined;
		  		scope.edit.hasMoved = false;
		  		scope.edit.selection.tool = scope.tools[0];
		  		return;
      		default:
      			break;
   		}
   		if( scope.edit.inAction==false && scope.edit.selection.tool.name != 'Selezione') {
      		if( scope.currentShape ) {
      			scope.shapes.push(angular.copy(scope.currentShape));
      		}
      		if( scope.edit.selection.tool.name == 'Testo' ) {
	      		scope.edit.selection.tool = scope.tools[0];
      		}
      		if( event.altKey && scope.edit.selection.tool.name == 'Linea' && scope.currentShape && scope.currentShape.points ) {
	      		scope.edit.inAction = true;
		  		var ctx = scope.canvas.getContext('2d');
		  		scope.currentImageData = ctx.getImageData(0,0, scope.canvas.width, scope.canvas.height);
	      		scope.currentShape.points = [angular.copy(scope.currentShape.points[scope.currentShape.points.length-1])];
      		} else {
	      		scope.currentShape = undefined;
      		}
	      	scope.edit.hasMoved = false;
   		}
   	};
   	scope.doMouseOut = function( event ) { scope.mouseIsDown = false; };
   	scope.getNormalizedCoords = function( event ) {
	  		var canvasX = event.pageX - $(scope.canvas).offset().left,
	  			canvasY = event.pageY - $(scope.canvas).offset().top;
	  		var coords = { 
		  		x: (canvasX-scope.canvas.width*0.5) / (scope.imageCoords.scale*scope.image.width)
		  		- scope.imageCoords.originX / (scope.imageCoords.scale*scope.image.width),
		  		y: (canvasY-scope.canvas.height*0.5) / (scope.imageCoords.scale*scope.image.width) - scope.imageCoords.originY / (scope.imageCoords.scale*scope.image.width)
	  		};
	  		return {x: coords.x, y: coords.y};
   	};
   	scope.setShapeContext = function(ctx, cvs) {
        if( cvs === undefined ) {
        	ctx.translate(scope.canvas.width*0.5+scope.imageCoords.originX, scope.canvas.height*0.5+scope.imageCoords.originY);
      		ctx.scale(scope.image.width*scope.imageCoords.scale, scope.image.width*scope.imageCoords.scale);
	    } else {
        	ctx.translate(cvs.width*0.5, cvs.height*0.5);
      		ctx.scale(cvs.width, cvs.width);
	    } 
   	};
   	scope.doMouseMove = function( event ) {
      	if( !scope.image ) { return; } // modal is closing
      	if( !scope.mouseIsDown && !scope.edit.inAction ) { return; }
   		event.preventDefault();
   		var lastMouseCoords = {x:scope.mouseCoords.x, y:scope.mouseCoords.y };
   		
   		scope.mouseCoords = scope.getNormalizedCoords(event);
  		var delta = { x: (scope.mouseCoords.x-lastMouseCoords.x) * (scope.imageCoords.scale* scope.image.width), 	y : (scope.mouseCoords.y-lastMouseCoords.y) * (scope.imageCoords.scale* scope.image.width ) };
   		if( scope.edit.selection.tool.name=="Pan" && scope.mouseIsDown ) {
      		if( delta.y > 0 && scope.imageCoords.posY<0) {
	      		if( scope.imageCoords.originY+delta.y > (-scope.imageCoords.posY) ) {
		      		scope.imageCoords.originY = -scope.imageCoords.posY;
	      		} else {
		      		scope.imageCoords.originY += delta.y;
	      		}
      		} else if( delta.y <0 && scope.imageCoords.posY<0 ) {
	      		if( scope.imageCoords.originY+delta.y < scope.imageCoords.posY ) {
		      		scope.imageCoords.originY = scope.imageCoords.posY;
	      		} else {
		      		scope.imageCoords.originY += delta.y;
	      		}
      		}
      		if( delta.x > 0 && scope.imageCoords.posX<0) {
	      		if( scope.imageCoords.originX+delta.x > (-scope.imageCoords.posX) ) {
		      		scope.imageCoords.originX = -scope.imageCoords.posX;
	      		} else {
		      		scope.imageCoords.originX += delta.x;
	      		}
      		} else if( delta.x <0 && scope.imageCoords.posX<0 ) {
	      		if( scope.imageCoords.originX+delta.x < scope.imageCoords.posX ) {
		      		scope.imageCoords.originX = scope.imageCoords.posX;
	      		} else {
		      		scope.imageCoords.originX += delta.x;
	      		}
      		}
      		scope.mouseCoords = scope.getNormalizedCoords( event );
      		scope.updateCanvas(true);
      		return;
   		} 
   		if( scope.edit.selection.tool.name=="Zoom" && scope.mouseIsDown ) {	
      		var minimumScale =  Math.min(scope.canvas.width / scope.image.width, scope.canvas.height / scope.image.height);     		
      		scope.imageCoords.scale = scope.imageCoords.scale - delta.x/scope.canvas.width-delta.y/scope.canvas.height;
      		scope.imageCoords.scale = Math.min(Math.max(scope.imageCoords.scale, minimumScale), 5*minimumScale);
	  		scope.imageCoords.posX=0.5*(scope.canvas.width-scope.image.width*scope.imageCoords.scale);
	  		scope.imageCoords.posY=0.5*(scope.canvas.height-scope.image.height*scope.imageCoords.scale);
	  		scope.imageCoords.originX = 0.0;
	  		scope.imageCoords.originY = 0.0;
	  		scope.imageCoords.width = Math.min(scope.canvas.width, scope.imageCoords.scale*scope.image.width);
	  		scope.imageCoords.height = Math.min(scope.canvas.height, scope.imageCoords.scale*scope.image.height);
   			scope.updateCanvas(true);
      		return;
   		}
   		
   		
   		if( scope.edit.selection.tool.name=="Selezione" && scope.currentShape && scope.mouseIsDown ) {
		  	var delta = {x:scope.mouseCoords.x-lastMouseCoords.x, y:scope.mouseCoords.y-lastMouseCoords.y};
      		if( scope.currentHandle ) { 
		  		scope.currentHandle.x += delta.x;
		  		scope.currentHandle.y += delta.y;
		  		
      		} else { // move shape
		  		scope.currentShape.points.forEach(function(c) {
			  		c.x += delta.x;
			  		c.y += delta.y;
		  		});
	      	}
	  		scope.updateCanvas(true);
      		return;
   		}
   		if( scope.edit.inAction ) {
   			//if( scope.edit.selection.tool.name =="Testo" ) { return; }
      		var ctx = scope.canvas.getContext('2d');
      		var newPoint ={x: scope.mouseCoords.x, y: scope.mouseCoords.y};
      		
      		ctx.putImageData(scope.currentImageData, 0,0);
      		if( scope.edit.hasMoved ) {
	      		scope.currentShape.points.pop();
      		} else {
		  		scope.edit.hasMoved = true;
      		}
      		if( event.shiftKey ) { // force square
      			switch(scope.currentShape.type) {
      				case 'Rettangolo':
      				case 'Simbolo':
      				case 'Ellisse':
      				case 'Ritaglio':
			      		var offsetX = newPoint.x-scope.currentShape.points[scope.currentShape.points.length-1].x;
			      		var offsetY = (newPoint.y-scope.currentShape.points[scope.currentShape.points.length-1].y);
			      		var delta = Math.min(Math.abs(offsetX), Math.abs(offsetY));
				  		newPoint.x = scope.currentShape.points[scope.currentShape.points.length-1].x + delta*(offsetX>=0?1:-1);
				  		newPoint.y = scope.currentShape.points[scope.currentShape.points.length-1].y + delta*(offsetY>=0?1:-1);
				  		break;
				  	case 'Poligono':
				  	case 'Linea':
			      		var offsetX = newPoint.x-scope.currentShape.points[scope.currentShape.points.length-1].x;
			      		var offsetY = (newPoint.y-scope.currentShape.points[scope.currentShape.points.length-1].y);
			      		var delta = Math.min(Math.abs(offsetX), Math.abs(offsetY));
				  		newPoint.x = Math.abs(offsetY) < Math.abs(offsetX) ? newPoint.x : scope.currentShape.points[scope.currentShape.points.length-1].x;
				  		newPoint.y = Math.abs(offsetX) < Math.abs(offsetY) ? newPoint.y : scope.currentShape.points[scope.currentShape.points.length-1].y;
				  		break;
	      			default:
	      				break;
      			}
      		}/*
      		newPoint.x = Math.min(Math.max(-0.5, newPoint.x),0.5);
      		newPoint.y = Math.min(Math.max(-0.5, newPoint.y),0.5);*/
	  		scope.currentShape.points.push(newPoint);
	  		ctx.save();
	  		scope.setShapeContext(ctx);
	  		scope.renderShape(scope.currentShape);
	  		ctx.restore();
   		}
   	};
   	scope.renderShape = function(shape, cvs) {
      	if( shape === undefined) { return; }
   		var canvas = (cvs === undefined ? scope.canvas : cvs);
   		var imagewidth = (cvs===undefined ? scope.image.width*scope.imageCoords.scale : cvs.width);
   		
      	var ctx = canvas.getContext('2d');
      	var drawHandles = function(context, points) {
      		context.save();
			context.lineWidth=1/canvas.width;
			context.fillStyle='rgba(255,0,0,0.3)';
			context.strokeStyle='rgba(255,0,0,1)';
			var hS = 4/imagewidth;
			points.forEach(function(c) {
	      		context.beginPath();
		      	context.arc(c.x, c.y, hS, 0,2*Math.PI, false);
				context.fill();
				context.stroke(); 
			});	
			context.closePath();
			context.restore();
      	};
      	
      	var canDraw = false;
      	switch(shape.type) {
      		case 'Testo':
	      		if( shape.points.length<2 ) { return; } 
      			ctx.font = shape.font.size+"px "+shape.font.type;
      			var textlines = shape.text.split('\n');
      			var metrics = ctx.measureText(textlines[0]);
	      		var scalefactor=(cvs===undefined ? scope.imageCoords.scale/scope.imageCoords.fitallScale : 1);
	      		var deltaX = (cvs===undefined ? scope.imageCoords.originX : 0);
	      		var deltaY = (cvs===undefined ? scope.imageCoords.originY : 0);
      			var lineHeight = shape.font.size*1.5*scalefactor;
      			for( var k=1;k<textlines.length;k++ ) { 
      				var curMetrics = ctx.measureText(textlines[k]);
	      			metrics = (metrics.width > curMetrics.width ? metrics : curMetrics);
      			}
      			shape.width = (metrics.width+6*2)/ imagewidth * scalefactor;
      			shape.height = (shape.font.size*1.6*textlines.length)/imagewidth*scalefactor;
		  		ctx.lineWidth=shape.border.width/imagewidth;
		  		ctx.setLineDash(scope.rescaleLineDash(shape.border.dash));
		  		ctx.strokeStyle = 'rgba('+shape.border.color+',1)';
		  		ctx.fillStyle = 'rgba('+shape.border.color+',1)';
		  		if( (shape.points[0].x-shape.points[1].x)*(shape.points[0].x-shape.points[1].x)+(shape.points[0].y-shape.points[1].y)*(shape.points[0].y-shape.points[1].y) > 0.005 ) {
			  		ctx.beginPath();
			  		ctx.moveTo(shape.points[0].x, shape.points[0].y);
			  		ctx.lineTo(shape.points[1].x+shape.width*0.5, shape.points[1].y+shape.height*0.5);
			  		ctx.stroke();
			  		ctx.beginPath();
		      		ctx.arc(shape.points[0].x, shape.points[0].y, 4/(scope.image.width*scope.imageCoords.scale), 0,2*Math.PI, false);
		      		ctx.fill();
		  		}
      			ctx.beginPath();
      			ctx.rect(shape.points[1].x, shape.points[1].y, shape.width, shape.height );
	      		ctx.fillStyle='rgba('+shape.fill.color+','+shape.fill.opacity+')';
	      		ctx.fill();
	      		ctx.stroke();
      			ctx.closePath();
      			ctx.restore();
      			ctx.fillStyle = 'rgb('+shape.font.color+')';
      			ctx.font = (shape.font.size*scalefactor)+"px "+shape.font.type;
      			ctx.textAlign='center';
      			for( var k=0;k<textlines.length;k++ ) {
	      			ctx.fillText(textlines[k], (shape.points[1].x * imagewidth + canvas.width*0.5 + deltaX  + shape.width*0.5*imagewidth ), (shape.points[1].y * imagewidth + canvas.height*0.5 + deltaY + lineHeight-4)+k*lineHeight);
	      		}
	      		ctx.save();
	      		scope.setShapeContext(ctx, cvs);
	      		break;
	      	case 'Linea':
	      		if( shape.points.length==2 ) {
		      		canDraw = true;
		      		ctx.beginPath();
		      		ctx.moveTo(shape.points[0].x, shape.points[0].y);
		      		ctx.lineTo(shape.points[1].x, shape.points[1].y);
			  		ctx.lineWidth=shape.border.width/canvas.width;
			  		ctx.setLineDash(scope.rescaleLineDash(shape.border.dash));
			  		ctx.strokeStyle = 'rgba('+shape.border.color+',1)';
		      		ctx.stroke();
		      		ctx.closePath();
	      		}
	      		break;
	      	case 'Rettangolo':
	      		if( shape.points.length==2 ) {
		      		canDraw = true;
		      		ctx.beginPath();
		      		ctx.rect(shape.points[0].x, shape.points[0].y, shape.points[1].x-shape.points[0].x, shape.points[1].y-shape.points[0].y);
		      		ctx.fillStyle='rgba('+shape.fill.color+','+shape.fill.opacity+')';
		      		ctx.fill();
			  		ctx.lineWidth=shape.border.width/canvas.width;
			  		ctx.setLineDash(scope.rescaleLineDash(shape.border.dash));
			  		ctx.strokeStyle = 'rgba('+shape.border.color+',1)';
		      		ctx.stroke();
		      		ctx.closePath();
	      		}
	      		break;
	      	case 'Ritaglio':
	      		if( shape.points.length==2 ) {
		      		canDraw = true;
		      		ctx.save();
		      		ctx.fillStyle='rgba(30,30,30,0.5)';
		      		ctx.fillRect(Math.min(shape.points[1].x, shape.points[0].x), Math.min(shape.points[1].y, shape.points[0].y), Math.abs(shape.points[1].x-shape.points[0].x), Math.abs(shape.points[1].y-shape.points[0].y));  
		      		ctx.restore();
	      		}
	      		break;
	      	case 'Ellisse':
	      		if( shape.points.length==2 ) {
		      		canDraw = true;
		      		ctx.save();
		      		var radius=Math.abs(shape.points[1].x-shape.points[0].x)*0.5;
		      		var scale=Math.abs(shape.points[1].y-shape.points[0].y)/Math.abs(shape.points[1].x-shape.points[0].x);
		      		var center={x:shape.points[0].x+(shape.points[1].x-shape.points[0].x)*0.5,y:(shape.points[0].y+(shape.points[1].y-shape.points[0].y)*0.5)/scale};
		      		ctx.scale(1,scale);
		      		ctx.beginPath();
		      		ctx.arc(center.x, center.y, radius, 0,2*Math.PI, false);
		      		ctx.fillStyle='rgba('+shape.fill.color+','+shape.fill.opacity+')';
		      		ctx.fill();
			  		ctx.lineWidth=shape.border.width/canvas.width;
			  		ctx.setLineDash(scope.rescaleLineDash(shape.border.dash));
			  		ctx.strokeStyle = 'rgba('+shape.border.color+',1)';
		      		ctx.stroke();
		      		ctx.closePath();
		      		ctx.restore();
	      		}
	      		break;
	      	case 'Poligono':
	      		if( shape.points.length>=2 ) {
		      		canDraw = true;
		      		ctx.beginPath();
		      		ctx.moveTo(shape.points[0].x, shape.points[0].y);
		      		for( var i=1;i<shape.points.length;i++ ) {
		      			ctx.lineTo(shape.points[i].x, shape.points[i].y);
		      		}
		      		ctx.closePath();
		      		ctx.fillStyle='rgba('+shape.fill.color+','+shape.fill.opacity+')';
		      		ctx.fill();
			  		ctx.lineWidth=shape.border.width/canvas.width;
			  		ctx.setLineDash(scope.rescaleLineDash(shape.border.dash));
			  		ctx.strokeStyle = 'rgba('+shape.border.color+',1)';
		      		ctx.stroke();
		      		ctx.closePath();
	      		}
	      		break;
	      	case 'Simbolo':
	      		if( shape.points.length==2 ) {
		      		var img = scope.symbols[shape.url];
		      		if( img ) {
			      		var midPoint = {};
			      		var w, h;
						midPoint.x = (shape.points[0].x+shape.points[1].x)/2;
						midPoint.y = (shape.points[0].y+shape.points[1].y)/2;
						w = (shape.points[1].x-shape.points[0].x);
						h = (shape.points[1].y-shape.points[0].y);
				        ctx.save();
				        if( shape.rotation === undefined ) { shape.rotation=0;}
						ctx.translate(midPoint.x, midPoint.y);
				        ctx.rotate(shape.rotation/180*Math.PI);
				  		ctx.drawImage( scope.symbols[shape.url], 0,0, img.width, img.height, -w*0.5, -h*0.5, w, h);
				        ctx.restore();
				  	}
		      	}
	      		break;
	      	default:
	      		break;
      	}
      	if( shape.selected && canvas == scope.canvas ) {
	      	drawHandles(ctx, shape.points);
      	}
   	};
   	scope.doKeyDown = function(event) {
   		if( scope.edit.inSelection==true ) {
	    	if( event.keyCode == 8 ) { // backward
				if( scope.currentShape ) {
					var index = scope.shapes.indexOf(scope.currentShape);
					var ctx = scope.canvas.getContext('2d');
					scope.currentShape=undefined;
					scope.currentHandle=undefined;
					scope.shapes.splice(index,1);
					scope.updateCanvas(true);
				}
	    	}
   		} else if( scope.edit.inAction==true ) {
	    	if( event.keyCode == 27 ) {
      			var ctx = scope.canvas.getContext('2d');
	  			var offset = $('.drawcanvas').offset();
	  			ctx.putImageData(scope.currentImageData, 0,0);
	  			scope.currentShape = undefined;
	  			scope.edit.inAction=false;
	  			scope.edit.hasMoved=false;
	    	} 
    	}
    	return false;
   	};
      	
      	/* canvas resize logic */
	  	var w = angular.element($window);
		scope.getWindowWidth = function () { return w.width(); };
		scope.rescaleLineDash = function(dash) {
			if( !scope.image ) { return dash; }
			var newDash = [];
			for( var i=0;i<dash.length; i++ ) {
				newDash.push(dash[i]/(scope.image.width*scope.imageCoords.scale));
			}
			return newDash;
		};
		scope.recalculateCanvasSize = function() {
		    var viewport = {}, 
		    	isModal = (elem.parent().prop('className').indexOf('modal-body')!=-1),
		    	toolbarAndMarginHeight = $(elem.find(".btn-toolbar")).height()+(30+40);
		   
		    if( isModal ) {
			    viewport.w = elem.innerWidth();
			    viewport.h = $( window ).height() - toolbarAndMarginHeight /*toolbar*/;
		    } else {
			    viewport.w = elem.innerWidth();
			    viewport.h = $( window ).height() - toolbarAndMarginHeight /*toolbar*/;
		    }

	        scope.canvas.width = viewport.w;
	        scope.canvas.height = viewport.h;
	        scope.imageCoords.fitallScale = Math.min(viewport.w / scope.image.width, viewport.h / scope.image.height);
			scope.imageCoords.scale = scope.imageCoords.fitallScale;
			scope.imageCoords.width = scope.image.width*scope.imageCoords.scale;
			scope.imageCoords.height = scope.image.height*scope.imageCoords.scale;
			scope.imageCoords.posX=0.5*(viewport.w-scope.imageCoords.width);
			scope.imageCoords.posY=0.5*(viewport.h-scope.imageCoords.height);
		};
		scope.$watch(scope.getWindowWidth, function (newWidth, oldWidth) {
			if( scope.canvas && scope.image ) {
				scope.recalculateCanvasSize();
				scope.updateCanvas( true )
			}
		});
        w.bind('resize', function () {
            scope.$apply();
        });
        /* end canvas resize logic */
      	scope.$watch('edit.selection', function(value) {
      		if( scope.edit.selection.tool.name=="Selezione" ) {
      			scope.edit.inSelection = true;
      			if( scope.edit.inAction ) { // cleanup
	      			scope.shapes.pop();
	      			scope.currentShape = undefined;
		  			scope.edit.hasMoved = false;
		  			scope.edit.inAction=false;
      			}
      			if( scope.currentShape ) {
		      		scope.currentShape.border = scope.edit.selection.border;
		      		scope.currentShape.fill = scope.edit.selection.fill;
			  		if( scope.currentShape.font ) {
		      			scope.currentShape.font = scope.edit.selection.font;
			  		}
		      		scope.updateCanvas(true);
		  		}
      		} else if( scope.edit.inSelection === true ) {
	      		scope.edit.inSelection = false;
	      		scope.currentHandle = undefined;
	      		scope.currentShape = undefined;
	      		scope.shapes.forEach(function(c) {
		      		c.selected = false;
	      		});
	      		scope.updateCanvas(true);
	      		if( scope.edit.selection.savedBorder ) {
	      			scope.edit.selection.border = angular.copy(scope.edit.selection.savedBorder);
		  			scope.edit.selection.fill = angular.copy(scope.edit.selection.savedFill);
		  		}
      		}		
      		// update preview canvas
      		scope.updateTools();
      	}, true);
      	scope.updateCanvas = function( drawShapes ) {
	      	if( !scope.image ) { return; }
	        var tempW = scope.image.width * scope.imageCoords.scale;
	        var tempH = scope.image.height * scope.imageCoords.scale;	
	        var ctx = scope.canvas.getContext("2d");
			ctx.fillStyle = "#333333";
			ctx.fillRect( 0, 0, scope.canvas.width, scope.canvas.height);
	        ctx.drawImage(scope.image, scope.imageCoords.posX + scope.imageCoords.originX, scope.imageCoords.posY + scope.imageCoords.originY, tempW, tempH);
	        ctx.save();
		  	scope.setShapeContext(ctx);
	        if( drawShapes ) {
		        scope.shapes.forEach(function(c) { scope.renderShape(c);});
	        }
	        ctx.restore();
      	};
      	scope.updateTools = function() {
      		if( !scope.fillcanvas) { return;}
		  	var ctx = scope.fillcanvas.getContext('2d');
		  	ctx.clearRect(0,0,scope.fillcanvas.width, scope.fillcanvas.height);
      		ctx.fillStyle = 'rgba('+scope.edit.selection.fill.color+','+scope.edit.selection.fill.opacity+')';
      		ctx.lineWidth=scope.edit.selection.border.width;
      		ctx.setLineDash(scope.rescaleLineDash(scope.edit.selection.border.dash));
      		ctx.strokeStyle = 'rgba('+scope.edit.selection.border.color+',1)';
		  	ctx.rect(5,0,scope.fillcanvas.width-10,scope.fillcanvas.height);
		  	ctx.fill();
      		ctx.stroke();
      	};
      	scope.cropImage = function() {
	      	var origin = {x: Math.floor(Math.min(scope.currentShape.points[0].x, scope.currentShape.points[1].x)*scope.image.width+scope.image.width*0.5), y:  Math.floor(Math.min(scope.currentShape.points[0].y, scope.currentShape.points[1].y)*scope.image.width+0.5*scope.image.height)};
	      	var size = { w: Math.abs(scope.currentShape.points[0].x-scope.currentShape.points[1].x), h: Math.abs(scope.currentShape.points[0].y-scope.currentShape.points[1].y)  }
		  	
			var tmpCanvas = document.createElement('canvas');
			tmpCanvas.width = size.w*scope.image.width;
			tmpCanvas.height = size.h*scope.image.width;
			
	        var ctx = tmpCanvas.getContext("2d");
	        ctx.clearRect(0,0,tmpCanvas.width, tmpCanvas.height);
	        ctx.drawImage(scope.image, origin.x, origin.y, tmpCanvas.width, tmpCanvas.height, 0,0, tmpCanvas.width, tmpCanvas.height);
		    scope.image = null;
		    scope.image = new Image();
		    scope.image.src = tmpCanvas.toDataURL(scope.imagetype);
		    ctx.clearRect(0,0,tmpCanvas.width, tmpCanvas.height);
		    
		    scope.image.onload = function() {
				scope.recalculateCanvasSize();
		        scope.updateCanvas(true);
	  			scope.currentImageData = ctx.getImageData(0,0, scope.canvas.width, scope.canvas.height);
	  			scope.$apply(function() {
		  			scope.edit.selection.tool = scope.tools[0];
	  			});
		    }
      	};

      	scope.rotateImage = function() {
			var tmpCanvas = document.createElement('canvas');
			tmpCanvas.width = scope.image.height;
			tmpCanvas.height = scope.image.width;
	        var ctx = tmpCanvas.getContext("2d");
	        ctx.clearRect(0,0,tmpCanvas.width, tmpCanvas.height);
	        ctx.save();
			ctx.translate(tmpCanvas.width/2,tmpCanvas.height/2);
	        ctx.rotate(0.5*Math.PI);
	        ctx.drawImage(scope.image, -scope.image.width/2,-scope.image.height/2);
	        ctx.restore();
		    scope.image = null;
		    scope.image = new Image();
		    scope.image.src = tmpCanvas.toDataURL(scope.imagetype);
		    ctx.clearRect(0,0,tmpCanvas.width, tmpCanvas.height);
		    
		    scope.image.onload = function() {
				scope.recalculateCanvasSize();
		        scope.updateCanvas(true);
	  			scope.currentImageData = ctx.getImageData(0,0, scope.canvas.width, scope.canvas.height);
	  			scope.$apply(function() {
		  			scope.edit.selection.tool = scope.tools[0];
	  			});
		    }
      	};
      	scope.resize = function( mode, max_width, max_height, drawShapes, tmpImage  ) {
			var tmpCanvas = document.createElement('canvas');
			var image = (tmpImage ? tmpImage : scope.image);
			var scale = 1.0;
	        var dstX = 0.0,
	        	dstY = 0.0,
	        	dstW = 0.0,
	        	dstH = 0.0;
			/* If mode is size to fit (only downwards) set no resize mode if image is smaller than dest */
			if( mode==4 ) {
				mode = (image.width>max_width || image.height>max_height) ? 2 : 0;
			}
			switch(mode) {
				case 0: // Keeps original scale and center the image. Crops if necessary
					tmpCanvas.width = max_width;
					tmpCanvas.height = max_height;
					break;
				case 1: // Size to fit only if image is bigger than max_width or max_height
					scale = Math.min(max_width/image.width, max_height/image.height, 1.0);
					tmpCanvas.width = image.width*scale;
					tmpCanvas.height = image.height*scale;
					break;
				case 2: // Size to fit in max_width and max:height: both upscale or downscale as needed 
					scale = Math.min(max_width/image.width, max_height/image.height);
					tmpCanvas.width = max_width;
					tmpCanvas.height = max_height;
					break;
				case 3: // Fill all
					scale = Math.max(max_width/image.width, max_height/image.height);
					tmpCanvas.width = max_width;
					tmpCanvas.height = max_height;
					break;
				case 4:
					break;
				case 5: // Crop to aspect ratio from max_width/max_height and scale only downwards
					var ratio = max_width / max_height;
					if( (image.width / image.height) > ratio ) {
						tmpCanvas.height = image.height;
						tmpCanvas.width = image.height*ratio;
					} else {
						tmpCanvas.width = image.width;
						tmpCanvas.height =  image.width/ratio;
					}
					scale = Math.min(max_width/tmpCanvas.width, max_height/tmpCanvas.height, 1.0);
					if( scale > 1 ) { // scale downwards
						tmpCanvas.width = tmpCanvas.width*scale;
						tmpCanvas.height = tmpCanvas.height*scale;
					} 
					break;
				default: // Do nothing: render original image
					tmpCanvas.width = image.width;
					tmpCanvas.height = image.height;
					break;
			}
			dstW = image.width*scale;
			dstH = image.height*scale;
			dstX = (tmpCanvas.width-dstW)*0.5;
			dstY = (tmpCanvas.height-dstH)*0.5;

	        var ctx = tmpCanvas.getContext("2d");
	        /*
	        console.log("**** SAVING **** ");
	        console.log("mode="+mode);
	        console.log("scale="+scale);
	        console.log("max_width="+max_width);
	        console.log("max_height="+max_height);
	        console.log("scope.canvas.width="+scope.canvas.width);
	        console.log("scope.canvas.height="+scope.canvas.height);
	        console.log("scope.image.width="+image.width);
	        console.log("scope.image.height="+image.height);
	        console.log("tmpCanvas.width="+tmpCanvas.width);
	        console.log("tmpCanvas.height="+tmpCanvas.height);
	        console.log("dstW="+dstW);
	        console.log("dstH="+dstH);
	        console.log("dstX="+dstX);
	        console.log("dstY="+dstY);
	        console.log("scope.imageCoords.originX="+scope.imageCoords.originX);
	        console.log("scope.imageCoords.originY="+scope.imageCoords.originY);
	        console.log("scope.imageCoords.scale="+scope.imageCoords.scale);
	        */
	        ctx.drawImage(image, 0, 0, image.width, image.height, dstX, dstY, dstW, dstH);
	        if( drawShapes === true ) {
		        ctx.save();
		        scope.setShapeContext(ctx, tmpCanvas);
				//ctx.translate(tmpCanvas.width*0.5, tmpCanvas.height*0.5);
		        //ctx.scale(tmpCanvas.width, tmpCanvas.width);
				scope.shapes.forEach(function(c) { 
					/*if( c.type === 'Simbolo' ) {
						scope.renderShape(c, tmpCanvas);
					}
					if( c.type === 'Linea' ) {
						scope.renderShape(c, tmpCanvas);
					}
					if( c.type === 'Poligono' ) {
						scope.renderShape(c, tmpCanvas);
					}
					if( c.type === 'Rettangolo' ) {
						scope.renderShape(c, tmpCanvas);
					}
					if( c.type === 'Testo' ) {
						scope.renderShape(c, tmpCanvas);
					}*/
					scope.renderShape(c, tmpCanvas);
				});
				ctx.restore();
	        }
	        if( tmpImage ) {
	    		var data = tmpCanvas.toDataURL(scope.imagetype);
	    		ctx.clearRect(0,0,tmpCanvas.width, tmpCanvas.height);
	    		return  data;
	    	} else {
	    		var data = scope.dataURItoBlob(tmpCanvas.toDataURL(scope.imagetype, 0.8));
	    		ctx.clearRect(0,0,tmpCanvas.width, tmpCanvas.height);
	    		return ({data: data, width: tmpCanvas.width, height: tmpCanvas.height});
	        }
      	};
      	scope.dataURItoBlob = function(dataURI) {
			var byteString, 
				mimestring 
			
			if(dataURI.split(',')[0].indexOf('base64') !== -1 ) {
				byteString = atob(dataURI.split(',')[1])
			} else {
				byteString = decodeURI(dataURI.split(',')[1])
			}
			
			mimestring = dataURI.split(',')[0].split(':')[1].split(';')[0]
			
			var content = new Array();
			for (var i = 0; i < byteString.length; i++) {
				content[i] = byteString.charCodeAt(i)
			}
			
			return new Blob([new Uint8Array(content)], {type: mimestring});
		};
		scope.awsImagePoster = function(signedurl, filetype, imagedata) {
	        var awsxhr = scope.createCORSRequest();
			awsxhr.upload.addEventListener("progress", scope.uploadProgress, false);
			awsxhr.addEventListener("load", scope.uploadComplete, false);
			awsxhr.addEventListener("error", scope.uploadFailed, false);
	        awsxhr.open('PUT', signedurl, true);
			awsxhr.setRequestHeader('Content-Type', filetype);
	        scope.xhr = awsxhr;
	        awsxhr.send(imagedata);
		};
      	scope.ok = function(event) {
	      	
			$timeout(function () { scope.$apply( function() { 
				$rootScope.instantsupport.inimage=false;
				scope.uploading = true;
			})}, 1);			
			scope.uploadresponse = {};
			
			var mode = (scope.mode ? scope.mode/1 : 1); // Divide by 1 to force conversion to Integer
			var imagewidth = (scope.imagewidth ? scope.imagewidth : 1600);
			var imageheight = (scope.imageheight ? scope.imageheight : 1600);
			var imageData = scope.resize(mode,imagewidth, imageheight, false); 
			var fd = new FormData();
			fd.append('image', JSON.stringify({width:imageData.width, height:imageData.height, type: scope.imagetype }));
			var finalImageData = null;
			if( scope.shapes.length ) {
				finalImageData = scope.resize(mode, imagewidth, imageheight, true);
				fd.append('editedimage', JSON.stringify({width:finalImageData.width, height:finalImageData.height, type: scope.imagetype }));
				fd.append('shapes', JSON.stringify(scope.shapes));
			}
			if( scope.imageid ) {
				fd.append('_id', scope.imageid);
			}
			
		    var xhr = new XMLHttpRequest();
		    xhr.open("POST", scope.posturl);
		    xhr.onreadystatechange = function(){
		        if(xhr.readyState === 4){
		            if(xhr.status === 200){
		                scope.uploadresponse = JSON.parse(xhr.responseText);
		                if( !scope.uploadresponse.files || !scope.uploadresponse.files.length ) {
			                
			                scope.uploadresponse = {};
							scope.$apply(function () {
								scope.error = "Errore generico. Riprova.";
								$timeout(function () { scope.$apply( function() {
									scope.error=undefined;
									scope.uploading=undefined;
								})}, 5000 );
							});
		                } else {
			                scope.uploadresponse.totalsize = (finalImageData ? finalImageData.data.size : 0)+(scope.uploadresponse.uploadsrc ? imageData.data.size : 0);
							if( scope.uploadresponse.files.length == 1 ) { // no edited image
								scope.uploadresponse.id = scope.uploadresponse.files[0]._id;
								scope.awsImagePoster(scope.uploadresponse.files[0].signedurl, scope.uploadresponse.files[0].filetype, imageData.data);
							} else {
								var srcidx= scope.uploadresponse.files[0].issource ? 0:1;
								scope.uploadresponse.id = scope.uploadresponse.files[1-srcidx]._id;
								if( scope.uploadresponse.uploadsrc ) {
									scope.uploadresponse.next = {signedurl: scope.uploadresponse.files[srcidx].signedurl, filetype: scope.uploadresponse.files[srcidx].filetype, data: imageData.data};
									scope.uploadresponse.processnext = true;
								}
								scope.awsImagePoster(scope.uploadresponse.files[1-srcidx].signedurl, scope.uploadresponse.files[1-srcidx].filetype, finalImageData.data);
							}
		                 }
		            } else {
			        	scope.uploadresponse = {};
						scope.$apply(function () {
							scope.error = "Errore generico. Riprova.";
							$timeout(function () { scope.$apply( function() {
								scope.error=undefined;
								scope.uploading=undefined;
							})}, 5000 );
						});
		            }
		        }
		    };
		    xhr.send(fd);
	    };
		scope.uploadProgress = function(evt) {
	        if (evt.lengthComputable) {
			  scope.$apply(function () {
			  	scope.progress = Math.round(evt.loaded * 100 / scope.uploadresponse.totalsize );
			  	(elem.find($("div[data-role='progressbar']"))[0]).style.width = scope.progress+"%";
			  });
	        }
		};
		scope.uploadComplete = function(evt) {
			scope.$apply( function() {
				if( scope.xhr.status !=200 ) {
					scope.error =  "Errore generico. Riprova.";
					$http.post(appURL+'/utils/images', {deleteid: scope.uploadresponse.id}).then(
						function successCallback(response) {},
						function errorCallback() {}
					);
			        scope.uploadresponse = {};
					$timeout(function () { scope.$apply( function() {
						scope.uploading=undefined;
						scope.error=undefined;
					} )}, 5000 );
				} else {
					if( scope.uploadresponse.processnext ) {
						var signedurl = scope.uploadresponse.next.signedurl;
						var filetype = scope.uploadresponse.next.filetype;
						var data = scope.uploadresponse.next.data;
						scope.uploadresponse.processnext = false;
						scope.awsImagePoster(signedurl, filetype, data);
					} else {
						scope.uploading=false;
						scope.imageid = scope.uploadresponse.id;
				        scope.uploadresponse = {};
						$timeout(function () { scope.$apply( function() {
							if( scope.okaction && scope.okaction() ) {
								scope.okaction();
							}
						}, 1);});
					}
				}
			});
		};
		scope.uploadFailed = function(evt) {
			scope.$apply(function () {
				scope.error = "Errore generico. Riprova.";
		    	$http.post(appURL+'/utils/images', {deleteid: scope.uploadresponse.id}).then(
			    	function successCallback(response) {},
			    	function errorCallback() {}
		    	);
				$timeout(function () { scope.$apply( function() {
					scope.error=undefined;
					scope.uploading=undefined;
				})}, 5000 );
			});
		};
		
		scope.handlefocus = function(e){ 
			if(e.type=='mouseover') { 
				var yPos = window.pageYOffset || document.documentElement.scollTop;
				setTimeout(function() {window.scrollTo(0, yPos);},0);
				scope.canvas.focus(); 				
				return false; } 
			else if (e.type=='mouseout') { scope.canvas.blur(); return false; } 
			return true; 
		}; 

		scope.setupImage = function() {  
			scope.spinwheelDiv.css({opacity:1.0});
			var setupCanvas = function(result, isnewimage) {
				if( scope.canvas == null ) {
					scope.fillcanvas = elem.find('.fillcanvas')[0];
					scope.canvas = elem.find('.drawcanvas')[0];
					
					scope.canvas.addEventListener('touchstart', scope.doMouseDown, false);
					scope.canvas.addEventListener('touchend', scope.doMouseUp, false);
					scope.canvas.addEventListener('touchmove', scope.doMouseMove, false);
					scope.canvas.addEventListener('touchleave', scope.doMouseOut, false);
					scope.canvas.addEventListener('keydown', scope.doKeyDown, true);
					scope.canvas.addEventListener('mousedown', scope.doMouseDown, false);
					scope.canvas.addEventListener('mouseup', scope.doMouseUp, false);
					scope.canvas.addEventListener('mousemove', scope.doMouseMove, false);
					scope.canvas.addEventListener('mouseout', scope.doMouseOut, false);
					scope.canvas.addEventListener('mouseover',scope.handlefocus,true);  
					scope.canvas.focus();
				} 
				var loadimage = function(url) {
					var tmpImage = new Image();
					
					tmpImage.onload = function() {
						var mode = (scope.mode ? scope.mode/1 : 1); // Divide by 1 to force conversion to Integer
						var imagewidth = (scope.imagewidth ? scope.imagewidth : 1600);
						var imageheight = (scope.imageheight ? scope.imageheight : 1600);
						var imageData = scope.resize(mode, imagewidth, imageheight, false, tmpImage); 
						tmpImage = null;
						
						scope.image = new Image();
						scope.image.onload = function() {
							scope.spinwheelDiv.css({opacity:0.0, 'z-index':-1000});
							scope.recalculateCanvasSize();
							var symbolsFound = false;
							scope.shapes.forEach(function(c) {
								if( c.type == "Simbolo" ) {
									symbolsFound = true;
									scope.addSymbolFromShape(c);
								}
							});
							if( symbolsFound == false ) {
								scope.updateCanvas(true);
							}
						};
						scope.image.src = imageData;
					};
					if( url.indexOf('data:image/png')!=-1 ) {
						scope.imagetype = 'image/png';
					}
					tmpImage.src = url;
				};
		      // See http://stackoverflow.com/questions/16956295/ie10-and-cross-origin-resource-sharing-cors-issues-with-image-canvas
				if( isnewimage ) {
					loadimage(result);
				} else {
					var xhr = scope.createCORSRequest();
					xhr.onload = function () {
						var url = URL.createObjectURL(this.response);
						loadimage(url);
					};
					xhr.open('GET', result+"?t="+(new Date()).getTime(), true);
					xhr.responseType = 'blob';
					xhr.send();
				} 					
			};
			if( scope.imageurl ) {
				if( scope.pdfimage === true ) {
					pdfjs.getDocument(scope.imageurl).then( function successCallback(pdf) {
						scope.$apply(function() {
							pdf.getPage(1).then( function successCallback( page ) {
								var viewport = page.getViewport( 1.5 );
								var tmpCanvas = document.createElement('canvas');
								tmpCanvas.width = viewport.width;
								tmpCanvas.height = viewport.height;
								var renderContext = {
									canvasContext: tmpCanvas.getContext("2d"),
									viewport: viewport
								};
								var renderTask = page.render(renderContext);
								
								// Wait for rendering to finish
								renderTask.promise.then(function() {
									setupCanvas(tmpCanvas.toDataURL('image/jpeg'), true);
								});
							}, function errorCallback() {
								alert("Impossibile caricare PDF. Verifica che il file non sia corrotto.");
							});
						});
					}, function errorCallback(error){
							scope.$apply(function() {
								scope.spinwheelDiv.css({opacity:0.0, 'z-index':-1000});
								alert("Impossibile caricare PDF. Verifica che il file non sia corrotto.");
							});
					});
				} else {
					setupCanvas(scope.imageurl, true);
				}
			} else { // existing file: load shapes if any
				$http.post( appURL+'/utils/images', { shapesid: scope.imageid } ).then( 
					function successCallback( response ) {
						var data=response.data; 
						scope.shapes = data.shapes;
						scope.remoteShapes = angular.copy(scope.shapes); 
						setupCanvas(ImageBucket+data.source, false);
					}, function errorCallback() {}
 				);
			}
		};
        $rootScope.instantsupport.inimage=true;
        scope.setupImage();
    }
}
}]);
