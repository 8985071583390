angular.module('common',[]).directive('privilegeedit',[ function() {
	return {
		scope: { privileges: '=', keys: '@', top: '@', label: '@' },
		restrict: 'C',
		templateUrl: assetsURL+'directives/widgets/templates/privilegeedit.html?t='+RootSeed,
		link: function( scope, elem, attrs ) {
			scope.privilege = -1;
			scope.$watch('privilege', function(newVal) {
				if( newVal === undefined ) { return; }
				if( scope.privilege == -1 ) { 
					scope.keyArray = (scope.keys ? scope.keys.split(",") : []);
					if( scope.keyArray.length ) {
						scope.privilege = scope.privileges[scope.keyArray[0]];
					}
				}
				if( scope.keyArray ) {
					scope.keyArray.forEach(function(key) {
						scope.privileges[key]=scope.privilege;
					});
				}
			});
		}
	}
}]).directive('emailedit',['models', function (models) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/emailedit.html?t='+RootSeed,
        scope: { email: '=', emails:'=' },
		link: function (scope, elem, attrs) {
			scope.editingtag = false;
			scope.toggleTagEdit = function() {
				scope.editingtag = !scope.editingtag;
			};
			scope.addEmail = function() {
				if( scope.emails === undefined || scope.emails.length === undefined ) { return; }
				scope.emails.push(new models.Email('','ufficio'));
			};
			scope.deleteEmail = function() {
				if( scope.emails === undefined || scope.emails.length === undefined ) { return; }
				var idx = scope.emails.indexOf(scope.email);
				if( idx != -1 ) {
					scope.emails.splice(idx, 1);
				}
			};
		}
	}
}]).directive('phoneedit',['models', function (models) {
    return {
		transclude: true,
		templateUrl: assetsURL+'directives/widgets/templates/phoneedit.html?t='+RootSeed,
        scope: { phone: '=', phones:'=' },
		link: function (scope, elem, attrs) {
			scope.editingtag = false;
			scope.toggleTagEdit = function() {
				scope.editingtag = !scope.editingtag;
			};
			scope.addPhone = function() {
				if( scope.phones === undefined || scope.phones.length === undefined ) { return; }
				scope.phones.push(new models.Phone('','ufficio'));
			};
			scope.deletePhone = function() {
				if( scope.phones === undefined || scope.phones.length === undefined ) { return; }
				var idx = scope.phones.indexOf(scope.phone);
				if( idx != -1 ) {
					scope.phones.splice(idx, 1);
				}
			};
		}
	}
}]).directive('dateinput',[ function() {
    return {
		scope: { date: '=', format:'=?', required: '=?', disabled: '=?', name: '@', addontext: '@' },
		restrict: 'C',
		templateUrl: assetsURL+'directives/widgets/templates/dateinput.html?t='+RootSeed,
		link: function (scope, elem, attrs) {
			scope.name = (scope.name === undefined ? 'date': scope.name);
			scope.format = (scope.format === undefined ? 'dd-MM-yyyy' : scope.format);
			scope.required = (scope.required === undefined ? false : scope.required);
			scope.disabled = (scope.disabled === undefined ? false : scope.disabled);
			scope.dateOptions = {
				dateDisabled: false,
				formatYear: 'yyyy',
				maxDate: new Date(2050, 5, 22),
				minDate: new Date(1900,1,1),
				startingDay: 1
			};
			scope.isopened = false;
			scope.open = function() {
				scope.isopened = true;
			}
			scope.updateTime = function() {
				if( scope.date && scope.date.getTime ) {
					scope.date.setHours(12);
					scope.date.setMilliseconds(0);
				}
			}
		}
	};
}]).directive('daterangefilter',[ function() {
	return {
		restrict: 'A',
        transclude: true,
        replace: true,
        scope: { startdate: '=', enddate: '=', rangetag: '=', skip:'='},
        templateUrl: assetsURL+'directives/widgets/templates/daterangefilter.html?t='+RootSeed,
		link: function (scope, elem, attrs) {
			scope.showdatefields = false;
			scope.timerange = {from: (new Date(scope['startdate'])), to:(new Date(scope['enddate']))};
			scope.editRange = function() {
				scope.showdatefields = true;
				scope.timerange = {from: (new Date(scope['startdate'])), to:(new Date(scope['enddate']))};
			}
			scope.updateTimerange = function() {
				scope.showdatefields=false;
				scope.startdate = new Date(scope.timerange.from);
				scope.enddate = new Date(scope.timerange.to);
				scope.rangetag = '';
			};
			scope.today = new Date();
			scope.setQuarter = function() {
				scope.rangetag = "Trimestre";
				var quarter = Math.floor((scope.today.getMonth() + 3) / 3);
				scope.startdate = new Date((new Date (scope.today.getFullYear(), (quarter-1) * 3, 1,0,0,0,0)).toISOString());
				scope.enddate = new Date((new Date (scope.today.getFullYear(), (quarter * 3)-1, (quarter==1||quarter==4 ? 31:30),23,59,59,999)).toISOString());
			};
			scope.setLastQuarter = function() {
				scope.rangetag = "Ultimo trimestre";
				var quarter = (Math.floor((scope.today.getMonth() + 3) / 3))-1;
				var year = scope.today.getFullYear();
				if( !quarter ) { quarter = 4; year--; }
				scope.startdate = new Date((new Date (year, (quarter-1) * 3, 1,0,0,0,0)).toISOString());
				scope.enddate = new Date((new Date (year, (quarter * 3)-1, (quarter==1||quarter==4 ? 31:30),23,59,59,999)).toISOString());
			};
			scope.setYear = function() {
				scope.rangetag = "Anno";
				scope.startdate = new Date((new Date(scope.today.getFullYear(),0,1)).toISOString());
				scope.enddate = new Date((new Date(scope.today.getFullYear(),11,31)).toISOString());
			};
			scope.setLastYear = function() {
				scope.rangetag = "Ultimo anno";
				scope.startdate = new Date((new Date(scope.today.getFullYear()-1,0,1)).toISOString());
				scope.enddate = new Date((new Date(scope.today.getFullYear()-1,11,31)).toISOString());
			};
		}
	}
}]).directive('richtexteditor',['$timeout', 'textAngularManager', function ($timeout, textAngularManager) {
    return {
		transclude: true,
		scope: { text: '=', placeholder:'@', disableimages: '@', maxlength: '@' },
		restrict: 'C',
		templateUrl: assetsURL+'directives/widgets/templates/richtexteditor.html?t='+RootSeed,
		link: function (scope, elem, attrs) {
			var textangularName = elem.find('input')[0].name;
			
			scope.normalizeFormat = function ($html) {
				return String($html).replace(/<img[^>]+>/gm,'').replace(/style[^>]+/gm,'');
			};
			scope.imglinksupdated = false;
			
			var maxlengthreached = false;
			
			if( scope.maxlength === undefined ) {
				scope.maxlength = 150000;
			}
			if( scope.placeholder === undefined ) {
				scope.placeholder = "inserisci testo";
			}
			if( scope.disableimages ) { 
				textAngularManager.removeTool('insertRImage');
			}
            var editor, maxLength = parseInt(scope.maxlength);
            var getTruncatedContent = function(content) {
                return $.truncate(content, {
                    length: maxLength + 1,
                    ellipsis: ''
                });
            };

            var getEditor = function() {
                return editor.scope.displayElements.text[0];
            };

            var getContentLength = function() {
                return angular.element(getEditor()).text().length;
            };

            var isNavigationKey = function(keyCode) {
                return ((keyCode >= 33) && (keyCode <= 40)) || ([8, 46].indexOf(keyCode) !== -1);
            };

            var isCopying = function(event) {
                return event.ctrlKey && ([65, 67, 88].indexOf(event.keyCode) !== -1);
            };
            /* Next lines for compatibility with previous docs using images on AWS S3 */
            scope.$watch('text', function(newVal, oldVal) {
				if( newVal === oldVal ) { return; }
				if( scope.imglinksupdated ) { return; }
				scope.imglinksupdated = true;
				if( scope.text ) {
					scope.text = scope.text.replace(/https:\/\/psconlinedev\.s3\.amazonaws\.com\//g,ImageBucket );
				}
            });
            /* */
            scope.$watch(function() {
                var editorInstance = textAngularManager.retrieveEditor(textangularName);
                if((editorInstance !== undefined) && (editor === undefined)) {
                    editor = editorInstance;
                    getEditor().addEventListener('keydown', function(e) {
                        if(!isNavigationKey(e.keyCode) && !isCopying(e) && (getContentLength() >= maxLength)) {
                            e.preventDefault();
                            return false;
                        }
                    });
                }

                return editorInstance === undefined ? '' : editor.scope.html;
            }, function(modifiedContent) {
                if( getContentLength() > maxLength) {
	                if( maxlengthreached == false) {
		                maxlengthreached = true;
		                alert('Hai raggiunto la massima lunghezza di testo consentita!');
	                }
                    $timeout(function() {
                        editor.scope.html = getTruncatedContent(modifiedContent);
                    });
                } else {
	                maxlengthreached = false;
                }
                
            });
		}
	}
}]).factory('ngClipboard', ['$compile', '$rootScope', '$document', function($compile, $rootScope, $document) {
	return {
		toClipboard: function(element) {
			
			var copyElement = angular.element('<span id="ngClipboardCopyId">'+element+'</span>');
			var body = $document.find('body').eq(0);
			body.append($compile(copyElement)($rootScope));
			
			var ngClipboardElement = angular.element(document.getElementById('ngClipboardCopyId'));
			var range = document.createRange();
			
			range.selectNode(ngClipboardElement[0]);
			
			window.getSelection().removeAllRanges();
			window.getSelection().addRange(range);
			
			var successful = document.execCommand('copy');
			
			window.getSelection().removeAllRanges();
			
			copyElement.remove();
		}
}
}]).directive('copyable', function() {
	return {
		restrict: 'A',
		link:link
	};
	function link(scope, element, attrs) {
		element.bind('click',function() {
			var range = document.createRange();
			range.selectNode(element[0]);
			window.getSelection().removeAllRanges();
			window.getSelection().addRange(range);
			var successful = document.execCommand('copy');
			
			window.getSelection().removeAllRanges();
		});
	}
}).service('warningService', ['$uibModal',function($uibModal) {
	var self = this;
	this.modalInstance = null;
	this.showModal = function(type, message, cancelbutton, okbutton, cancelcallback, okcallback, otherbutton, otherbuttoncallback ) {
		if( self.modalInstance ) {
			self.modalInstance.dismiss('cancel');
			self.modalInstance = null;
		}
		self.modalInstance = $uibModal.open({
			templateUrl: assetsURL+'partials/templates/warningModal.html?t='+RootSeed,
			controller: ['$scope', '$uibModalInstance', 'type','message', 'cancelbutton', 'okbutton','cancelcallback','okcallback','otherbutton', 'otherbuttoncallback', function (scope, $uibModalInstance, type, message, cancelbutton, okbutton, cancelcallback, okcallback, otherbutton, otherbuttoncallback) {
				scope.type = type;
				scope.message = message;
				scope.cancelbutton = cancelbutton;
				scope.okbutton = okbutton;
				scope.otherbutton = otherbutton;
				scope.cancel = function () {
					$uibModalInstance.dismiss('cancel');
					if( cancelcallback ) { cancelcallback(); }
				};
				scope.ok = function () {
					$uibModalInstance.close(okcallback);
				};
				scope.otherbuttonaction = function () {
					$uibModalInstance.close(otherbuttoncallback);
				};
			}],
			
			resolve: { 
				type: function () { return type; },
				message: function () { return message; },
				cancelbutton: function () { return cancelbutton; },
				okbutton: function () { return okbutton; },
				cancelcallback: function () { return cancelcallback; },
				okcallback: function () { return okcallback; },
				otherbutton: function () { return otherbutton; },
				otherbuttoncallback: function () { return otherbuttoncallback; }
			}
		});
	
		self.modalInstance.result.then(
			function successCallback(callback) {
				if( callback ) { callback(); }
			}, function errorCallback() {}
		);
	};
}]);
