angular.module('worksiteReports', [])
.directive('worksiteReport',['models','$http', '$uibModal','lettertextService', function (models,$http, $uibModal, lettertextService) {
    return {
		restrict: 'C',
		templateUrl: assetsURL+'components/worksite-reports/templates/worksite-report.template.html?t='+RootSeed,
        scope: { report: '=', worksite:'=', reporttypes : '=', mailinglist : '=' },
		link: function (scope, elem, attrs) {
			scope.categoryreports = [];
			scope.categoryreportname = '';
			scope.addRecipients = false;
			scope.originalText = null;
			scope.enterpriseeditindex = -1;
			scope.tmpenterpriseeditcurrentwork = "";
			scope.tmpenterpriseeditplace = "";
			
			scope.setRecipients = function() {
				var modalInstance = $uibModal.open({
					templateUrl: assetsURL+'components/worksite-reports/templates/worksite-report-setRecipientsModal.html',
					controller: ['$scope', '$uibModalInstance', 'mailinglist','recipients',  function ( scope, $uibModalInstance, mailinglist, recipients ) {
						// Update recipients
						scope.availableRecipients = JSON.parse(JSON.stringify(mailinglist));
						var recipientsGraph = {};
						recipients.forEach(function(recipient) {
							if( recipient.contact && recipient.contact._id ) {
								recipientsGraph[recipient.contact._id]=recipient;
							}
						});
						
						scope.availableRecipients.forEach(function(recipient) {
							if( recipient.contact && recipient.contact._id ) {
								hashedRecipient = recipientsGraph[recipient.contact._id];
								if( hashedRecipient ) {
									hashedRecipient.contact.emails.forEach(function(email) {
										for( var i=0;i<recipient.contact.emails.length; i++ ) {
											if( email.email == recipient.contact.emails[i].email ) {
												recipient.contact.emails[i].selected = email.selected;
												break;
											}
										}
									});
								}
								recipient.selected = false;
								recipient.contact.emails.forEach(function(email) {
									if( email.selected ) {recipient.selected=true;}
								});
							}
						});

						scope.selectAll = function() {
							scope.availableRecipients.forEach(function(recipient) {
								recipient.contact.emails.forEach(function(email) {
									email.selected = true;
								})
							});
						};
						scope.deselectAll = function() {
							scope.availableRecipients.forEach(function(recipient) {
								recipient.contact.emails.forEach(function(email) {
									email.selected = false;
								})
							});
						};
						
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.ok = function () {
							scope.availableRecipients.forEach(function(recipient) {
								var atLeastOneSelected = false;
								recipient.contact.emails.forEach(function(email) {
									if( email.selected ) { atLeastOneSelected = true; }
								});
								recipient.selected = atLeastOneSelected;
							});
							$uibModalInstance.close(scope.availableRecipients);
						};												
					}],
					resolve: { 
						mailinglist: function() { return scope.mailinglist; },
						recipients: function() { return scope.report.recipients; }
					}
				});
				modalInstance.result.then(
					function successCallback(data) {
						scope.report.recipients = data;
					}, function errorCallback() {
					}
				);
			};
			
			scope.addEnterprise = function() {
				var modalInstance = $uibModal.open({
					templateUrl: assetsURL+'components/worksite-reports/templates/worksite-report-addEnterpriseModal.html',
					controller: ['$scope', '$uibModalInstance', 'enterprises','refenterprises',  function ( scope, $uibModalInstance, enterprises, refenterprises ) {
						scope.availableEnterprises = [];
						var hashedEnterprisesById = {};
						var availableEnterprises = [];

						scope.selectAll = function() {
							scope.availableEnterprises.forEach(function(enterprise) {
								enterprise.selected = true;
							});
						};
						scope.deselectAll = function() {
							scope.availableEnterprises.forEach(function(enterprise) {
								enterprise.selected = false;
							});
						};
						
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.ok = function () {
							scope.availableEnterprises.forEach(function(enterprise) {
								if( enterprise.selected ) {
									refenterprises.push(enterprise);
								}
							});
							$uibModalInstance.close(null);
						};
						
						refenterprises.forEach(function(refenterprise) {
							hashedEnterprisesById[refenterprise.refId]=refenterprise;
						});
						for( var i=0;i<enterprises.length;i++ ) {
							var enterprise = enterprises[i];
							if( enterprise.referencedenterprise && enterprise.referencedenterprise._id && enterprise.referencedenterprise.status == 2 ) {
								if( hashedEnterprisesById[enterprise.referencedenterprise._id] === undefined ) {
									var newrefenterprise = { companyname: enterprise.contact.companyname, refId: enterprise.referencedenterprise._id, work:enterprise.work, currentwork:"", place:"", selected: false, parentcontractor: enterprise.parentcontractor };
									availableEnterprises.push(newrefenterprise);
								}
							}
						}
						scope.availableEnterprises = availableEnterprises;
						
					}],
					resolve: { 
						enterprises: function() { return scope.worksite.enterprises; },
						refenterprises: function() { return scope.report.enterprises; }
					}
				});
				modalInstance.result.then(
					function successCallback(data) {
					}, function errorCallback() {
					}
				);
			};
			scope.removeEnterprise = function(index) {
				if( index < 0 || index >= scope.report.enterprises.length ) { return; }
				scope.report.enterprises.splice(index, 1);
			};
			scope.editEnterprise = function(index) {
				if( index < 0 || index >= scope.report.enterprises.length ) { return; }
				scope.tmpenterpriseeditcurrentwork = scope.report.enterprises[index].currentwork;
				scope.tmpenterpriseeditplace = scope.report.enterprises[index].place;
				scope.enterpriseeditindex = index;
			};
			scope.cancelEnterpriseEdit = function() {
				scope.report.enterprises[scope.enterpriseeditindex].currentwork=scope.tmpenterpriseeditcurrentwork;
				scope.report.enterprises[scope.enterpriseeditindex].place=scope.tmpenterpriseeditplace;
				scope.enterpriseeditindex = -1;
			};
			scope.closeEnterpriseEdit = function() {
				scope.enterpriseeditindex = -1;
			};
			scope.updateReport = function() {
				if( scope.report.text && scope.report.text.length>1 ) {
					scope.report.text = lettertextService.substituteClasses(scope.report.text, {report: scope.report, doc: scope.worksite});
				} else if( scope.originalText ) {
					scope.report.text = lettertextService.substituteTags(scope.originalText, {report: scope.report, doc: scope.worksite});
				}
			};
			scope.updateReportCategory = function( ) {
				scope.report.text = "";
				scope.report.type = null;
				if( scope.reporttypes === undefined ) { return; }
				scope.reporttypes.forEach(function(reporttype) {
					if( reporttype.category == scope.report.category ) {
						scope.categoryreports = reporttype.reports;
					}
				});
			};
			scope.updateReportType= function( ) {
				scope.report.text = null;
				scope.originalText = null;
			};

			// Next two trigger if category is changed
			scope.$watch('report.category', function(newVal, oldVal) {
				if( newVal === undefined ) { return; }
				if( scope.reporttypes === undefined ) { return; }
				scope.reporttypes.forEach(function(reporttype) {
					if( reporttype.category === scope.report.category ) {
						scope.categoryreports = reporttype.reports;
					}
				});
			});
			scope.$watch('reporttypes', function(newVal, oldVal) {
				if( newVal === undefined ) { return; }
				if( scope.report === undefined ) { return; }
				scope.reporttypes.forEach(function(reporttype) {
					if( reporttype.category === scope.report.category ) {
						scope.categoryreports = reporttype.reports;
					}
				});
			}, true);
			
			// Next triggers if type is changed
			scope.$watchGroup(['report.type', 'categoryreports'], function(newVal, oldVal) {
				if( newVal === undefined ) { return; }
				//if( angular.equals(newVal, oldVal ) ) { return; }
				scope.categoryreports.forEach(function(c) {
					if( c.name === scope.report.type) {
						scope.addRecipients = c.addRecipients;
					}
				});
				if( scope.report.type == "" ) {
					scope.report.text = "";
					scope.originalText = null;
					return;
				}
				if( scope.report.type && scope.report.type.length ) {
					$http.get(appURL+'/crud/reporttexts/type/'+encodeURIComponent(scope.report.type), {}).then( 
						function successCallback(response) {
							var data=response.data;
							if( data && data.text) {
								scope.originalText = data.text;
								scope.report.title = data.description;
								scope.updateReport();
							}
						}, function errorCallback(response) {
						}
					);
				}
			});
			
			// Next trigger if data are changed

			scope.$watch('report.recipients', function(newVal, oldVal) {
				if( newVal === undefined ) { return; }
				if( angular.equals(newVal, oldVal ) ) { return; }
				scope.updateReport();
			}, true);
			scope.$watchGroup(['report.issuedate'], function(newVal, oldVal) {
				if( newVal === undefined ) { return; }
				if( angular.equals(newVal, oldVal ) ) { return; }
				scope.updateReport();
			});

			scope.showRecipients = function() {
				return scope.report.category && scope.report.type && scope.addRecipients;
			};
			
		}
	};
}]).directive('worksiteReports', ['$timeout', '$http','$uibModal', 'warningService','models', function ($timeout, $http, $uibModal, warningService, models) {
    return {
        scope: { reports: '=', docs: '=', type:'@', worksite : '=', mailinglist : '=', saveaction: '&' },
		restrict: 'C',
		templateUrl: assetsURL+'components/worksite-reports/templates/worksite-reports.template.html?t='+RootSeed,
		link: function (scope, elem, attrs) {
			scope.reporttypes = [];
			scope.addReport= function() {
				$http.post(appURL+'/services/worksites-reports/new', {'worksiteId':scope.worksite._id, 'type':scope.type }).then(
					function successCallback(response){							
						var newObject = new models.Report();
						newObject._id = response.data._id;
						newObject.prescriptions_prev = response.data.prescriptions;
						newObject.prescriptions_prev.forEach(function(prescription) {
							prescription.prescriptiondeadline = new Date(prescription.prescriptiondeadline);
						});
						if( response.data.layouts && response.data.layouts.length ) {
							newObject.addlayoutsection = true;
							newObject.layouts = response.data.layouts;
						}
						scope.editReport(-1, newObject);
					}, function errorCallback(response){
					}
				);
			};
			scope.sendEmail = function(index) {
				if( index<0 || index >= scope.reports.length ) { return; }
				var modalInstance = $uibModal.open({
					size: 'lg',
					templateUrl: assetsURL+'components/worksite-reports/templates/worksite-report-sendEmailModal.html',
					controller: ['$scope', '$http', '$uibModalInstance', 'worksite', 'report', 'mailinglist',  function ( scope, $http, $uibModalInstance, worksite, report, mailinglist ) {
						scope.loading = false;
						scope.curstep = 0;
						scope.data = { worksiteId: worksite._id, reportId: report._id, fullname:"", customText:"", subject:"", emails: [] };
						if( worksite.messagesender ) { scope.data.fullname = worksite.messagesender; }
						scope.data.subject = report.title + (worksite.place ? " -- Cantiere in " + worksite.place.address + " a " + worksite.place.city : "");
						scope.data.customText = "Si trasmette "+report.title;
						
						// Update recipients
						scope.availableRecipients = JSON.parse(JSON.stringify(mailinglist));
						var recipientsGraph = {};
						report.recipients.forEach(function(recipient) {
							if( recipient.contact && recipient.contact._id ) {
								recipientsGraph[recipient.contact._id]=recipient;
							}
						});
						
						scope.availableRecipients.forEach(function(recipient) {
							if( recipient.contact && recipient.contact._id ) {
								hashedRecipient = recipientsGraph[recipient.contact._id];
								if( hashedRecipient ) {
									hashedRecipient.contact.emails.forEach(function(email) {
										for( var i=0;i<recipient.contact.emails.length; i++ ) {
											if( email.email == recipient.contact.emails[i].email ) {
												recipient.contact.emails[i].selected = email.selected;
												break;
											}
										}
									});
								}
								recipient.selected = false;
								recipient.contact.emails.forEach(function(email) {
									if( email.selected ) {recipient.selected=true;}
								});
							}
						});

						scope.selectAll = function() {
							scope.availableRecipients.forEach(function(recipient) {
								recipient.contact.emails.forEach(function(email) {
									email.selected = true;
								})
							});
						};
						scope.deselectAll = function() {
							scope.availableRecipients.forEach(function(recipient) {
								recipient.contact.emails.forEach(function(email) {
									email.selected = false;
								})
							});
						};
						scope.goForward = function() { 
							scope.curstep = 1;
						};
						scope.goBack = function() { 
							scope.curstep = 0;
						};
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.ok = function () {
							scope.loading = true;
							scope.data.customText += "\nPuoi scaricare il documento a questo link:\n";
							scope.data.customText += appURL+"/services/worksites-docs/download/"+report.file.fileId;
							
							scope.availableRecipients.forEach(function(recipient) {
								recipient.contact.emails.forEach(function(email) {
									if( email.selected ) {
										scope.data.emails.push({name:(recipient.contact.iscompany ? recipient.contact.companyname : recipient.contact.firstname+" "+recipient.contact.lastname), email:email.email});
									}
								});
							});
							$http.post(appURL+'/services/worksites-messages/new', scope.data).then(
								function successCallback(response){							
									$uibModalInstance.close();
								}, function errorCallback(response){
									$uibModalInstance.dismiss('cancel');
								}
							);
						};												
					}],
					resolve: { 
						worksite: function() { return scope.worksite; },
						report: function() { return scope.reports[index]; },
						mailinglist: function() { return scope.mailinglist; }
					}
				});
				modalInstance.result.then(
					function successCallback(data) {
						alert("Comunicazione inviata");
					}, function errorCallback() {
					}
				);
			};

			scope.deleteReport = function(index) {
				if( index<0 || index >= scope.reports.length ) { return; }
				scope.reports.splice(index, 1);
			};
			scope.editReport = function(index, newObject) {
				var report = newObject ? newObject : scope.reports[index];
				var isNew = newObject ? true : false;
				var modalInstance = $uibModal.open({
					size: 'lg',
					templateUrl: assetsURL+'components/worksite-reports/templates/worksite-report-editReportModal.html?t='+RootSeed,
					controller: ['$http', '$scope', '$uibModalInstance', 'reports', 'docs', 'index', 'report','reporttypes','worksite','mailinglist', 'type', 'isNew','saveaction', function ($http, scope, $uibModalInstance, reports, docs, index, report, reporttypes, worksite, mailinglist, type, isNew, saveaction ) {
						scope.isSaving = false;
						scope.report = JSON.parse(JSON.stringify(report));
						scope.report.issuedate = new Date(scope.report.issuedate);
						if( isNew ) {
							scope.report.reportindex = index+1;
						}
						var prescriptions = [scope.report.prescriptions_prev, scope.report.prescriptions_A, scope.report.prescriptions_B, scope.report.prescriptions_C];
						for( var j=0; j<prescriptions.length; j++ ) {
							prescriptions[j].forEach(function(prescription) {
								prescription.prescriptiondeadline = new Date(prescription.prescriptiondeadline);
							});
						}
						
						scope.reporttypes = reporttypes;
						scope.worksite = worksite;
						scope.mailinglist = mailinglist;
						scope.isNew = isNew;
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.ok = function () {
							scope.isSaving = true;

							const filename = (worksite.filename ? worksite.filename : worksite.job+"_VR"+("000" + (index+1)).substr(-3)+"_"+report.title);
							const destObjKeyPath = type.substr(6); // Remove custom from field name
							if( isNew ) {
								reports.push(scope.report);
							} else {
								reports[index] = scope.report;
							}
							var successCb = function() {
								$http.post(appURL+'/services/worksites/print', { 'worksiteId':scope.worksite._id, 'objType':'report', destObjKeyPath:destObjKeyPath, 'srcObjKeyPath': type, 'srcObjId': scope.report._id, 'filename': filename }).then(
									function successCallback(response){																
										const data = response.data;
										
										var newDoc = {};
										newDoc.creationdate = new Date();
										newDoc.fileId = data.fileId;
										newDoc.type = 'application/pdf';
										newDoc.description = filename;
										newDoc.issuedate = new Date();
										newDoc.revision = (scope.report.file.fileId !== undefined && scope.report.file.revision !== undefined) ? scope.report.file.revision+1:0;
										newDoc.notes = "";
										newDoc.filename = filename;
										docs.docs.push(newDoc);
										
										scope.report.file = { fileId:data.fileId, status:'success', error:null, revision:newDoc.revision };
										var successSaveCallback = function() {
											scope.isSaving = false;
											$uibModalInstance.close(null);
										};
										
										var errorSaveCallback = function() {
											scope.isSaving = false;
											$uibModalInstance.dismiss('cancel');
											alert("Attenzione! Non e' stato possibile salvare le modifiche. Riprova")
										};
										saveaction()(successSaveCallback, errorSaveCallback);
									}, function errorCallback(response){
										scope.isSaving = false;
										$uibModalInstance.dismiss('cancel');
										alert("Attenzione! Non e' stato possibile generare il PDF. Riprova")
									}
								);
							};
							var errorCb = function() {
								scope.isSaving = false;
								$uibModalInstance.dismiss('cancel');
								alert("Attenzione! Non e' stato possibile salvare le modifiche. Riprova")
							};
							saveaction()(successCb, errorCb);
						};
					}], resolve: { 
						reports:  function() { return scope.reports; },
						docs:  function() { return scope.docs; },
						index:  function() { return index; },
						report: function() { return report; },
						reporttypes: function() { return scope.reporttypes; },
						worksite: function() { return scope.worksite; },
						mailinglist: function() { return scope.mailinglist; },
						type: function() { return scope.type; },
						isNew: function() { return isNew; },
						saveaction: function () { return scope.saveaction; }
					}
				});
				modalInstance.result.then(
					function successCallback(data) {
					}, function errorCallback() {
					}
				);
			};
			
			scope.getReports = function() {
				$http.get(appURL+'/crud/reporttexts/?nolimit=true', {}).then( 
					function successCallback(response) {
						var data=response.data;
						if( data && data.length) {
							var reportsByCategory = {};
							var category;
							data.forEach(function(c) {
								if( c.category && c.category.length ) {
									if( reportsByCategory[c.category] === undefined ) { 
										reportsByCategory[c.category] = []; 
									}
									category = c.category;
								} else {
									if( reportsByCategory['Categoria non definita']=== undefined ) {
										reportsByCategory['Categoria non definita'] = [];
									}
									category = 'Categoria non definita';
								}
								reportsByCategory[category].push(c);
							});
							var sortedKeys = Object.keys(reportsByCategory).sort();
							var reporttypes = [];
							sortedKeys.forEach(function(key) {
								reporttypes.push({category:key, reports:reportsByCategory[key]});
							});
							scope.reporttypes = reporttypes;
						}
					}, function errorCallback(response) {
						alert('Si è verificato un errore. Si prega di riprovare');
					}
				);
			}
			scope.getReports();
		}
	}
}]);
		