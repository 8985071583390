angular.module('customcolorpicker',[]).directive('customcolorpicker',['$timeout', function($timeout) {
  'use strict';

  return {
	scope: { srccolor : '=' },
	restrict: 'AEC',
	templateUrl: assetsURL+'directives/customcolorpicker/templates/customcolorpicker.html?t='+RootSeed,
    link: function (scope, element, attrs) {
	    scope.color = '';
		scope.colors = [['rgb(100,179,223)', 'rgb(156,225,89)', 'rgb(255,224,97)', 'rgb(255,192,114)', 'rgb(255,95,94)', 'rgb(157,69,184)'], ['rgb(73,155,201)', 'rgb(110,192,56)', 'rgb(241,209,48)', 'rgb(255,169,58)','rgb(255,45,33)','rgb(108,32,133)'], ['rgb(54,125,162)', 'rgb(121,174,61)', 'rgb(226,184,1)', 'rgb(236,159,46)', 'rgb(207,35,43)', 'rgb(85,19,108)'], ['rgb(23,87,120)', 'rgb(87,135,38)', 'rgb(198,147,0)', 'rgb(209,127,21)', 'rgb(174,25,22)', 'rgb(60,10,73)'], ['rgb(220,220,220)', 'rgb(191,191,191)', 'rgb(127,127,127)', 'rgb(64,64,64)', 'rgb(32,32,32)', 'rgb(0,0,0)']];
		scope.$watch('color', function(newvalue) {
			if( newvalue ) {
				var parts = newvalue.match(/^rgb\((.+)\)$/);
				if( !parts || parts.length<2 ) { return;}
				if( scope.srccolor != parts[1] ) {
					scope.srccolor = parts[1];
				}
			}
		});
		scope.$watch('srccolor', function(newvalue) {
			if( newvalue ) {
				var colornewvalue = 'rgb('+scope.srccolor+')';
				if( scope.color != colornewvalue ) {
					scope.color = 'rgb('+scope.srccolor+')';
				}
			}
		});
		scope.setColor = function(newcolor) {
			scope.color = newcolor;
		}
    }
  };
}]).directive('editorcolorpicker',['$timeout', function($timeout) {
  'use strict';

  return {
    require: '?ngModel',
	restrict: 'AEC',
	templateUrl: assetsURL+'directives/customcolorpicker/templates/editorcolorpicker.html?t='+RootSeed,
    link: function (scope, element, attrs, ngModel) {
	    scope.color = '#000000';
		scope.colors = [['rgb(100,179,223)', 'rgb(156,225,89)', 'rgb(255,224,97)', 'rgb(255,192,114)', 'rgb(255,95,94)', 'rgb(157,69,184)'], ['rgb(73,155,201)', 'rgb(110,192,56)', 'rgb(241,209,48)', 'rgb(255,169,58)','rgb(255,45,33)','rgb(108,32,133)'], ['rgb(54,125,162)', 'rgb(121,174,61)', 'rgb(226,184,1)', 'rgb(236,159,46)', 'rgb(207,35,43)', 'rgb(85,19,108)'], ['rgb(23,87,120)', 'rgb(87,135,38)', 'rgb(198,147,0)', 'rgb(209,127,21)', 'rgb(174,25,22)', 'rgb(60,10,73)'], ['rgb(220,220,220)', 'rgb(191,191,191)', 'rgb(127,127,127)', 'rgb(64,64,64)', 'rgb(32,32,32)', 'rgb(0,0,0)']];
		scope.$watch('color', function(newvalue) {
			if( newvalue ) {
				var parts = newvalue.match(/^rgb\((\d+),(\d+),(\d+)\)$/);
				if( !parts || parts.length<4 ) { return;}
                if(ngModel) {
	                var newColor = "#"+scope.dec2hex(parts[1])+scope.dec2hex(parts[2])+scope.dec2hex(parts[3]);
                	ngModel.$setViewValue(newColor);
                }
			}
		});
		scope.dec2hex = function(d) {
			return  ("0"+(Number(d).toString(16))).slice(-2).toUpperCase();
		};
		scope.setColor = function(newcolor) {
			scope.color = newcolor;
		}
    }
  };
}]);
