'use strict';
(function() {
angular.module('http-auth-interceptor-buffer', [])
.factory('httpBuffer', ['$injector', function($injector) {
    var buffer = [];

    var $http;

    function retryHttpRequest(config, deferred) {
      function successCallback(response) {
        deferred.resolve(response);
      }
      function errorCallback(response) {
        deferred.reject(response);
      }
      $http = $http || $injector.get('$http');
      $http(config).then(successCallback, errorCallback);
    }

    return {
      append: function(config, deferred) {
        buffer.push({
          config: config,
          deferred: deferred
        });
      },

      rejectAll: function(reason) {
        if (reason) {
          for (var i = 0; i < buffer.length; ++i) {
            buffer[i].deferred.reject(reason);
          }
        }
        buffer = [];
      },

      retryAll: function(updater) {
        for (var i = 0; i < buffer.length; ++i) {
          retryHttpRequest(updater(buffer[i].config), buffer[i].deferred);
        }
        buffer = [];
      }
    };
  }]);

angular.module('http-auth-interceptor', ['http-auth-interceptor-buffer'])
.factory('authService', ['$rootScope','httpBuffer', function($rootScope, httpBuffer) {
    return {
      loginConfirmed: function(data, configUpdater) {
        var updater = configUpdater || function(config) {return config;};
        $rootScope.$broadcast('event:auth-loginConfirmed', data);
        httpBuffer.retryAll(updater);
      },

      loginCancelled: function(data, reason) {
        httpBuffer.rejectAll(reason);
        $rootScope.$broadcast('event:auth-loginCancelled', data);
      }
    };
  }])
.factory('errorHandler', ['$rootScope', '$q', 'httpBuffer',  '$injector', function($rootScope, $q, httpBuffer, $injector) {  
    var httpAuthInterceptor = {
        responseError: function(response) {
	        if (response.status === 401 && !response.config.ignoreAuthModule) { // login required
				var deferred = $q.defer();
				httpBuffer.append(response.config, deferred);
				$rootScope.$broadcast('event:auth-loginRequired', response);
				return deferred.promise;
	        }
	        if( response.status === 502 || response.status === 503 ) { // failed node app or database connection
				var deferred = $q.defer();
				httpBuffer.append(response.config, deferred);
				$rootScope.$broadcast('event:manteinance', response);
				return deferred.promise;
	        }
	        // otherwise, default behaviour 
	        return $q.reject(response);
        }
    };
    return httpAuthInterceptor;
}])
.config(['$httpProvider', function($httpProvider) {  
    $httpProvider.interceptors.push('errorHandler');
}]);
})();