import * as pdfjs from 'pdfjs-dist/webpack';

angular.module('enterpriseDocuments', [])
.directive('enterpriseDocument', ['$timeout','$uibModal','models','$http','warningService', function ($timeout, $uibModal, models, $http, warningService) {
    return {
		scope: { doc : '=', doctype: '=', saveaction: '&' },
		restrict: 'C',
		templateUrl: assetsURL+'components/enterprise-documents/templates/enterprise-document.template.html',
		link: function (scope, elem, attrs) {
			scope.isValid = true;
			scope.error = null;
			scope.validate = function() {
				if( scope.doctype.required ) {
					if( scope.doc.files.length ) {
						if( scope.doc.validity.type > 0 ) {
							var expirydate = scope.doc.expirydate ? new Date(scope.doc.expirydate) : new Date("2000-01-01");
							var now = new Date();
							if( expirydate.getTime() < now.getTime() ) {
								scope.error = "Documento scaduto";
								scope.isValid = false;
								return;
							}
						} 
					} else {
						scope.isValid = false;
						scope.error = "Documento obbligatorio";
						return;
					}
				} else {
					if( scope.doc.files.length ) {
						if( scope.doc.validity.type > 0 ) {
							var expirydate = scope.doc.expirydate ? new Date(scope.doc.expirydate) : new Date("2000-01-01");
							var now = new Date();
							if( expirydate.getTime() < now.getTime() ) {
								scope.isValid = false;
								scope.error = "Documento scaduto";
								return;
							}
						} 
					}
				}
				scope.isValid = true;
				scope.error = null;
			};

			scope.deleteDocFile = function(index) {
				if( index >= 0 && index < scope.doc.files.length ) {
					var deletingDoc = scope.doc.files[index];
					var confirmDeleteDoc = function() {
						deletingDoc.deleting = true;
						$http.post(appURL+'/services/enterprises-docs/delete', { docfile: deletingDoc._id} ).then( 
							function successCallback() {
								if( scope.saveaction ) {
									const tmpFile = angular.copy(scope.doc.files[index]);
									scope.doc.files.splice(index,1);
									scope.validate();
									var successSaveCallback = function() {};
									
									var errorSaveCallback = function() {
										tmpFile.deleting = false;
										scope.doc.files.push(tmpFile);
										scope.validate();
										alert("Attenzione! Non è stato possibile salvare le modifiche. Riprova pi&ugrave; tardi")
									};
									scope.saveaction()(successSaveCallback, errorSaveCallback);	
								} else {
									scope.doc.files.splice(index,1);
									scope.validate();
								}
							}, function errorCallback() {
								deletingDoc.deleting = false;
								alert("Si è verificato un errore. Si prega di riprovare");
							}
						);
					};
					warningService.showModal("warning", "Il documento sarà eliminato e non potrà essere recuperato. Sei sicuro di voler continuare?", "Annulla", "Elimina", null, confirmDeleteDoc );
				}
			};
			scope.showDocFile = function(index) {
				// TO BE IMPLEMENTED
			};
			scope.downloadDocFile = function(index) {
				if( index >= 0 && index < scope.doc.files.length ) {
					var downloadingDoc = scope.doc.files[index];
					$timeout(function () {
						var a = document.createElement('a');
						document.body.appendChild(a);
						a.download = downloadingDoc.name;
						a.href = appURL+"/services/enterprises-docs/download/"+downloadingDoc._id;
						a.click();
						document.body.removeChild(a);
					}, 200);
				}
			};

			scope.editDoc = function(file) {
				var modalInstance = $uibModal.open({
					templateUrl: assetsURL+'components/enterprise-documents/templates/enterprise-document-editModal.html',
					controller: ['$http', '$scope', '$uibModalInstance', 'doc','file','doctype','saveaction','validate',  function ($http, scope, $uibModalInstance, doc, file, doctype, saveaction, validate ) {						
						scope.progress = 1;
						scope.uploading=false;
						scope.xhr = null;
						scope.uploadresponse = {};
						scope.pageRendering = false;
						scope.pageNumPending = null,
						scope.pdfDoc = null;
						scope.imageDoc = null;
						scope.renderCanvas = null;
						scope.imageWidth = 1200;
						scope.imageHeight = 1600;
						
						scope.isNew = file ? true : false;
						scope.data = { validity: {}, uploadedPDFId:null, uploadedImagesIds:[] };
						if( file ) {
							const re = /(?:\.([^.]+))?$/;
							const ext = re.exec(file.name)[1]; 
							scope.data.file = file;
							scope.data.filename = (doctype.type.length ? doctype.type+"."+ext : file.name);
							scope.data.filetype = (file.type == 'image/jpeg' || file.type == 'image/png') ? 'image/jpeg' : 'application/pdf';
						}
						
						scope.data.description = (doc.description ? doc.description : "");
						scope.data.issuedate = (doc.issuedate ? (doc.issuedate.getTime ? doc.issuedate : new Date(doc.issuedate)): null);
						scope.data.expirydate = (doc.expirydate ? (doc.issuedate.getTime ? doc.expirydate : new Date(doc.expirydate)) : null);
						scope.data.validity.type = (doc.validity && doc.validity.type !== undefined ? doc.validity.type : (doctype.validity && doctype.validity.type !== undefined ? doctype.validity.type : 0));
						scope.data.validity.duration = (doc.validity && doc.validity.duration ? doc.validity.duration : (doctype.validity && doctype.validity.duration ? doctype.validity.duration : 1));
						scope.data.validity.units = (doc.validity && doc.validity.units ? doc.validity.units : (doctype.validity && doctype.validity.units ? doctype.validity.units : 'Anni'));
						scope.$watchGroup(['data.validity.type','data.validity.duration','data.validity.units','data.issuedate'], function(newValue, oldValue) {
							if( newValue === undefined ) { return; }
							if( scope.data.validity.type == 1) {
								if( scope.data.issuedate != null && scope.data.validity.duration > 0 )  {
									var duration = scope.data.validity.units == 'Giorni' ? 1 : (scope.data.validity.units == 'Mesi' ? 30 : 365);										scope.data.expirydate = new Date(scope.data.issuedate.getTime()+scope.data.validity.duration*86400*1000*duration);
								}
							}
						}, true);

					  	scope.createCORSRequest = function() {
							var xhr = new XMLHttpRequest();
							if ("withCredentials" in xhr) {
								return xhr;
							} else if (typeof XDomainRequest != "undefined") {
								xhr = new XDomainRequest();
								return xhr;
							} 
							return null;
						};

						scope.gcpFilePoster = function(signedurl, filetype, data) {
							var gcpxhr = scope.createCORSRequest();
							gcpxhr.upload.addEventListener("progress", scope.uploadProgress, false);
							gcpxhr.addEventListener("load", scope.uploadComplete, false);
							gcpxhr.addEventListener("error", scope.uploadFailed, false);
							gcpxhr.open('PUT', signedurl, true);
							gcpxhr.setRequestHeader('Content-Type', filetype);
							scope.xhr = gcpxhr;
							gcpxhr.send(data);
						};

						scope.cancelUpload = function(errorText) {
							var filesIds = scope.data.uploadedImagesIds;
							if( scope.data.uploadedPDFId ) {
								filesIds.push(scope.data.uploadedPDFId);
							} 
							filesIds.push(scope.uploadresponse.id);		
							$http.post(appURL+'/services/enterprises-docs/delete', { files: filesIds } ).then( 
								function successCallback(data) {
									scope.uploading=false;
									scope.xhr = null;
									scope.uploadresponse = {};
									alert(errorText);
									$uibModalInstance.dismiss('cancel');
								}, function errorCallback(response) {
									scope.uploading=false;
									scope.xhr = null;
									scope.uploadresponse = {};
									alert(errorText);
									$uibModalInstance.dismiss('cancel');
								}
							);
						}
						scope.abortUpload = function() {
							if( scope.xhr ) {
								scope.xhr.abort();
								scope.cancelUpload("Operazione interrotta");
							}
						};
						
						scope.uploadProgress = function(evt) {
							if (evt.lengthComputable) {
								scope.$apply(function () {
									if( scope.data.filetype == 'application/pdf' ) {
										if( scope.pageNumPending == null ) { 
											// Loading source file
											scope.progress = Math.round(0.5*evt.loaded * 100 / scope.uploadresponse.totalsize );
										} else {
											scope.progress = Math.round(50+50/scope.pdfDoc.numPages*((scope.pageNumPending-1)+evt.loaded / scope.uploadresponse.totalsize ));
										}
									} else {
										scope.progress = Math.round(evt.loaded * 100 / scope.uploadresponse.totalsize );
									}
								});
							}
						};
						
						scope.uploadComplete = function(evt) {
							scope.$apply( function() {
								if( scope.xhr.status !=200 ) {
									scope.cancelUpload("Errore generico di caricamento. Riprova.");
								} else {
									if( scope.data.filetype == 'application/pdf' ) {
										if( scope.pageNumPending == null ) { 
											// Loading source file
											scope.data.uploadedPDFId = scope.uploadresponse.id;
										} else {
											scope.data.uploadedImagesIds.push(scope.uploadresponse.id);
										}
									} else {
										scope.data.uploadedImagesIds.push(scope.uploadresponse.id);
									}
									scope.uploadresponse.callback();
								}
							});
						};
						
						scope.uploadFailed = function(evt) {
							scope.$apply( function() {
								scope.cancelUpload("Errore generico di caricamento. Riprova.");
							});
						};

						scope.uploadImageData = function( imageData, callback ) {
							$http.post(appURL+'/services/enterprises-docs/signedurl', { filetype:'image/jpeg', name: file.name, width:imageData.width, height:imageData.height } ).then( 
								function successCallback(response) {
									scope.uploadresponse.id = response.data._id;
									scope.uploadresponse.totalsize = imageData.data.size;
									scope.uploadresponse.callback = callback
									scope.gcpFilePoster(response.data.signedurl, 'image/jpeg', imageData.data );
								}, function errorCallback(response) {
									scope.cancelUpload("Errore generico di caricamento. Riprova.");
								}
							);
						};
						
						scope.uploadPDFData = function( file, callback ) {
							$http.post(appURL+'/services/enterprises-docs/signedurl', { filetype:'application/pdf', name: file.name } ).then( 
								function successCallback(response) {
									scope.uploadresponse.id = response.data._id;
									scope.uploadresponse.totalsize = file.size;
									scope.uploadresponse.callback = callback
									var reader = new FileReader();
									reader.onloadend = function() {
										scope.uploadresponse.pdfdata = reader.result;
										scope.gcpFilePoster(response.data.signedurl, file.type, reader.result );
									}
									reader.readAsArrayBuffer(file);
								}, function errorCallback(response) {
									scope.cancelUpload("Errore generico di caricamento. Riprova.");
								}
							);
						};
						
						scope.resizeImage = function() {
							scope.renderCanvas = document.createElement('canvas');
							var widthRatio = scope.imageDoc.width / scope.imageWidth;
							var heightRatio = scope.imageDoc.height / scope.imageHeight;
							if( widthRatio > 1 || heightRatio > 1 ) {
								var scale = Math.max(widthRatio, heightRatio);
								scope.renderCanvas.width = scope.imageDoc.width / scale;
								scope.renderCanvas.height = scope.imageDoc.height / scale;
							} else {
								scope.renderCanvas.width = scope.imageDoc.width;
								scope.renderCanvas.height = scope.imageDoc.height;
							}
							var ctx = scope.renderCanvas.getContext('2d');
							ctx.drawImage(scope.imageDoc, 0, 0, scope.renderCanvas.width, scope.renderCanvas.height );
							var data = scope.dataURItoBlob(scope.renderCanvas.toDataURL(scope.data.filetype, 0.5));
							ctx.clearRect(0,0,scope.renderCanvas.width, scope.renderCanvas.height);
							return ({data: data, width: scope.renderCanvas.width, height: scope.renderCanvas.height});
						};
						scope.dataURItoBlob = function(dataURI) {
							var	byteString, mimestring;
							
							if(dataURI.split(',')[0].indexOf('base64') !== -1 ) {
								byteString = atob(dataURI.split(',')[1])
							} else {
								byteString = decodeURI(dataURI.split(',')[1])
							}
							
							mimestring = dataURI.split(',')[0].split(':')[1].split(';')[0]
							
							var content = new Array();
							for (var i = 0; i < byteString.length; i++) {
								content[i] = byteString.charCodeAt(i)
							}
							
							return new Blob([new Uint8Array(content)], {type: mimestring});
						};

						scope.ok = function () {
							var successSaveCallback = function() {
								scope.uploading = false;
								$uibModalInstance.close(null);
							};
							
							var errorSaveCallback = function() {
								scope.uploading = false;
								$uibModalInstance.dismiss('cancel');
								alert("Attenzione! Non è stato possibile salvare le modifiche. Riprova pi&ugrave; tardi")
							};
							
							var finalize = function() {
								const data = scope.data;
								const file = new models.EnterpriseDocFile(data.filename, data.filetype, data.uploadedPDFId , data.uploadedImagesIds);
								$http.post(appURL+'/services/enterprises-docs/file', file ).then( 
									function successCallback(response) {
										file._id = response.data._id;
										if( doctype.allowmultiplefiles || !doc.files.length ) {
											doc.files.push(file);
											validate();
											saveaction()(successSaveCallback, errorSaveCallback);
										} else {
											$http.post(appURL+'/services/enterprises-docs/delete', { file: scope.doc.files[0]._id} ).then( 
												function successCallback() {
													doc.files[0] = file;
													validate();
													saveaction()(successSaveCallback, errorSaveCallback);
	
												}, function errorCallback() {
													validate();
													scope.uploading = false;
													$uibModalInstance.dismiss('cancel');
													alert("Errore generico di connessione");
												}
											);
										}
									}, function errorCallback(response) {
										validate();
										scope.uploading = false;
										$uibModalInstance.dismiss('cancel');
										alert("Errore generico di connessione");
									}
								);
	
							};
							var uploadsCompleted = function() {
								$timeout(function () { scope.$apply( function() {
									scope.uploadresponse = {};
									finalize();
								}, 1000);});
							};
							
							var uploadNextPDFPage = function() {
								$timeout(function () { scope.$apply( function() {
									if( scope.pageNumPending == scope.pdfDoc.numPages ) {
										scope.pageNumPending = null;
										scope.uploadresponse = {};
										finalize();
									} else {
										scope.pageNumPending++;
										getPDFPage(scope.pageNumPending, renderPDFPage );
									}
								}, 1);});
							};
							
							var uploadPDFPages = function() {
								const loadingTask = pdfjs.getDocument(scope.uploadresponse.pdfdata);
								loadingTask.promise.then(pdf => {
									scope.$apply(function() {
										scope.pdfDoc = pdf;
										scope.pageNumPending = 1;
										getPDFPage(scope.pageNumPending, renderPDFPage );
									});
								}, error => {
									scope.cancelUpload("Impossibile caricare PDF. Verifica che il file non sia corrotto.");
								});
							};
							
							var getPDFPage = function( pageNum, callback ) {
								scope.pageRendering = true;
								scope.pdfDoc.getPage(pageNum).then( callback );
							};
							var renderPDFPage = function( page ) {
								scope.renderCanvas = document.createElement('canvas');
								var viewport = page.getViewport({scale: 1.5} );
								var widthRatio = viewport.width / scope.imageWidth;
								var heightRatio = viewport.height / scope.imageHeight;
								if( widthRatio > 1 || heightRatio > 1 ) {
									var scale = Math.max(widthRatio, heightRatio);
									scope.renderCanvas.width = viewport.width / scale;
									scope.renderCanvas.height = viewport.height / scale;
								} else {
									scope.renderCanvas.width = viewport.width;
									scope.renderCanvas.height = viewport.height;
								}
								var renderContext = {
									canvasContext: scope.renderCanvas.getContext('2d'),
									viewport: viewport
								};
								var renderTask = page.render(renderContext);
								renderTask.promise.then(loadPDFPage);
							}
							
							var loadPDFPage = function() {
								scope.pageRendering = false;
								var data = scope.dataURItoBlob(scope.renderCanvas.toDataURL('image/jpeg', 0.7));
								var ctx = scope.renderCanvas.getContext('2d');
								ctx.clearRect(0,0,scope.renderCanvas.width, scope.renderCanvas.height);
								var imageData = {data: data, width: scope.renderCanvas.width, height: scope.renderCanvas.height};
								scope.uploadImageData(imageData, uploadNextPDFPage);
							};

							var data = scope.data;
							if( doc ) {
								doc.description = data.description;
								doc.issuedate = data.issuedate;
								doc.expirydate = data.expirydate;
								doc.validity.type = data.validity.type;
								doc.validity.duration = data.validity.duration;
								doc.validity.units = data.validity.units;
							}
							if( !file ) {
								validate();
								saveaction()(successSaveCallback, errorSaveCallback);
								return;
							}
							
							$timeout( function () { scope.$apply( function() { scope.uploading = true;})}, 1);			
							scope.uploadresponse = {};
							
							if( file.type == 'image/jpeg' || file.type == 'image/png' ) {
								var reader = new FileReader();
								reader.onloadend = function() {
									scope.imageDoc = new Image();
									scope.imageDoc.onload = function() {
										var imageData = scope.resizeImage(); 
										scope.uploadImageData(imageData, uploadsCompleted);
									};
									scope.imageDoc.src = reader.result;
								}
								reader.readAsDataURL(file);
							} else if( file.type == 'application/pdf' ) {
								scope.uploadPDFData(file, uploadPDFPages);
							}
						};
						
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
					}],
					resolve: { 
						doc: function() { return scope.doc; },
						doctype: function() { return scope.doctype; },
						file: function() { return file; },
						saveaction: function () { return scope.saveaction; },
						validate: function() { return scope.validate; }
					}
				});
				modalInstance.result.then(
					function successCallback(data) {
						elem.find("input:file").val(null);
					}, function errorCallback() {
						elem.find("input:file").val(null);
					}
				);
			};
		

			scope.loadDocFile = function(event) {
				if( !event ) { return; }
	        	var file = null;
				if( event.target ) { // select file
		        	if( !event.target.files ) return;
		        	if( !event.target.files.length ) return;
					file = event.target.files[0];
	        	}
				if( file ) { // new selected or dropped file
					var re = /(?:\.([^.]+))?$/;
					var ext = re.exec(file.name)[1]; 
					if( ext === undefined ) {
						elem.find("input:file").val(null);
						alert("Il nome del file non ha estensione. Aggiungi l'estensione per identificare il tipo di file.");
						return;
					} 
					var filesize = ((file.size/1024)/1024).toFixed(1); // MB
					if( filesize > 20 ) {
						elem.find("input:file").val(null);
						alert("Il documento ("+filesize+"Mb) non deve superare i 20Mb di dimensione.");
						return;
					}
					scope.editDoc(file);
				} 
			};

			scope.addDocFile = function(event) { 
				if( !scope.doc.files.length || scope.doctype.allowmultiplefiles ) {
					$timeout( function() { (elem.find("input:file"))[0].click(); },0 ); 
				}
			};
			elem.find("input:file").bind('change', scope.loadDocFile);
			scope.validate();
		}
	};
}]).directive('enterpriseDocuments', ['$timeout', '$http','$uibModal', 'warningService','models', function ($timeout, $http, $uibModal, warningService, models) {
    return {
		scope: { documents: '=', section:'@', canadd:'@', saveaction: '&'},
		restrict: 'C',
		templateUrl: assetsURL+'components/enterprise-documents/templates/enterprise-documents.template.html',
		link: function (scope, elem, attrs) {
			scope.doctypes = {};
			scope.viewtype = 'list';
			scope.loading = true;
			scope.setViewType = function(type) {
				scope.viewtype = type;
			}
			scope.addOther = function() {
				if( scope.canadd ) {
					scope.documents.push(new models.EnterpriseDoc("Altro"));
				}
			};
			scope.$watch('documents', function(newVal, oldVal) {
				if( newVal != oldVal || scope.loading == true ) {
					$http.get(appURL+'/crud/enterprisedoctypes?section='+scope.section).then(
						function successCallback(response) {
							var doctypes = response.data;
							
							// Create hash for easy access
							var tmpDocumentsHash = {};
							scope.documents.forEach(function(doc) {
								tmpDocumentsHash[doc.type]=true;
							});
							
							// Check that all documents are present, otherwise add them
							doctypes.forEach(function(doctype) {
								scope.doctypes[doctype.type]=doctype;
								if( tmpDocumentsHash[doctype.type] === undefined ) {
									scope.documents.push(new models.EnterpriseDoc(doctype.type));
								}
							});
							scope.loading = false;
						}, function errorCallback(response) {
							scope.loading = false;
							alert('Si è verificato un errore. Si prega di riprovare');
						}
					);
				}
			});
			scope.asyncSaveAction = function(successCallback, errorCallback) {
				if( scope.saveaction ) {
					scope.saveaction()(successCallback, errorCallback);
				}
			}
		}
	};
}]);

