angular.module('ganttchart',[]).directive('ganttchart', ['$http','$uibModal','$window','$timeout','$interval','$filter', 'models', 'worksheetService', function ($http,$uibModal,$window,$timeout,$interval, $filter, models, worksheetService) {
	return {
		scope: { sourcezones : '=', startdate : '=?', specifystartdate : '=?', contractors : '&?', calendar : '=?', options: '=?' },
		restrict: 'AEC',
		templateUrl: assetsURL+'directives/ganttchart/templates/ganttchart.html?t='+RootSeed,
		link: function (scope, elem, attrs) {
			scope.values = { startdate : new Date(), calendarisopened : false };
			scope.options = angular.isDefined(scope.options) ? scope.options : new models.CalendarOptions(models.POSCALENDAR);
			if( scope.options.zoneswidth != 8 ) { scope.options.zoneswidth = 8; }
			if( scope.options.zonetitleheight != 25 ) { scope.options.zonetitleheight = 25; }
			scope.weekdays = angular.isDefined(scope.calendar) && scope.calendar.weekdays!==undefined ? scope.calendar.weekdays : models.defaultWeekdays();
			scope.specifystartdate = angular.isDefined(scope.specifystartdate) ? scope.specifystartdate : true;
			if( scope.specifystartdate === false ) {
				scope.options.showdaycounts = true;
				scope.options.showcalendar = false;
				scope.options.shownonworkingdays = false;
			}
			scope.holidays = angular.isDefined(scope.calendar) && scope.calendar.holidays ? scope.calendar.holidays : models.defaultHolidays();
			scope.workingdates = angular.isDefined(scope.calendar) && scope.calendar.workingdates ? scope.calendar.workingdates : [];
			scope.nonworkingdates = angular.isDefined(scope.calendar) && scope.calendar.nonworkingdates ? scope.calendar.nonworkingdates : [];
			scope.contractors = angular.isDefined(scope.contractors) ? scope.contractors : [];
			scope.interferences = scope.calendar.interferences;
			scope.worktagsletters = ['a','b','c','d','e','f','g', 'h','i','j','k','l','m','n','o', 'p','q','r','s', 't','u','v','w','x','y','z'];
			scope.worktags = [];
			scope.zonesizes = [];
			scope.calendarFormat = 'dd-MM-yyyy';
			scope.dateOptions = {
				dateDisabled: false,
				formatYear: 'yyyy',
				maxDate: new Date(2030, 5, 22),
				minDate: new Date(2015,1,1),
				startingDay: 1
			};
			scope.openCalendar = function() {
				scope.values.calendarisopened = true;
			}

			scope.weekdays.forEach(function(weekday) {
				if( weekday.name.length == 11  ) {
					weekday.name = weekday.name.replace(/&(.)*/, "&igrave;");
				}
			});
			if( !angular.isDefined(scope.startdate) || scope.startdate.getTime() === undefined || isNaN(scope.startdate.getTime())) {
				scope.startdate = new Date();
			}
			scope.values.startdate = scope.startdate;
			scope.$watch('values.startdate', function(newval) {
				if( newval !== undefined ) {
					scope.startdate = newval;
				}
			});		
			// inner state vars
			scope.canvas = elem.find('canvas')[0];
			scope.popoverisshown = false;
			scope.selection = {type:0};
			scope.workdays = [];
			scope.totalRowsCount = {}; // it's an hash object in order to manage multiple calendars
			scope.visibleRowsCount = {}; // it's an hash object in order to manage multiple calendars
			scope.showcontextmenu = false;
			scope.contextmenustyle = {'left':100, 'top':-100};
			var ctxmenubtnsize = {w: 95, h: 30};
			scope.zones =scope.sourcezones;
			var popover = $(elem.find('#popoverbutton'));
			popover.popover({placement:'top', content:'', html:true,  animation:false});
			
			scope.updateZoneRowsCount = function(c) {
				var hash = scope.options._id;
				scope.visibleRowsCount[c._id] = 0;
				scope.totalRowsCount[c._id] = 0;
				if( !c.works ) { c.works=[]; return;}
				c.works.forEach(function(d) {
					scope.totalRowsCount[c._id]++;
					if( d.visible[hash] ) {scope.visibleRowsCount[c._id]++;}
					if( d.subworks ) {
						d.subworks.forEach(function(e) {
							scope.totalRowsCount[c._id]++;
							if( e.visible[hash] ) {scope.visibleRowsCount[c._id]++;}
						});
					}
				});
				scope.updateWorksWidth();
			};
			scope.updateWorkdays = function(fromScratch, limit) {
				var fromDay, toDay, curDay, curStatus;
				if( fromScratch === undefined || fromScratch==true ) {
					scope.workdays = [];
					fromDay = 0;
					toDay = limit | 365;
				} else {
					fromDay = scope.workdays.length;
					toDay = fromDay+(limit | 100);
				}
				curDay = new Date(scope.startdate.getTime()+86400000*fromDay);
				curDay.setHours(12);
				var curState;
				for( var i=fromDay;i<toDay;i++ ) {
					curStatus = scope.weekdays[curDay.getDay()].isworking;
					if( curStatus == true ) {
						scope.holidays.forEach( function(dt) {
							var d = dt.date;
							if( d.getMonth()==curDay.getMonth() && d.getDate()==curDay.getDate()) {
								if( d.getFullYear() == 2000 ) {
									curStatus = false;
								} else if( d.getFullYear()==curDay.getFullYear() ) {
									curStatus = false;
								}
							}
						});
						if( curStatus == true ) {
							scope.nonworkingdates.forEach(function(d) {
								if( d.getFullYear()==curDay.getFullYear() && d.getMonth()==curDay.getMonth() && d.getDate()==curDay.getDate()) {
									curStatus = false;
								}
							});
						} else {
							scope.workingdates.forEach(function(d) {
								if( d.getFullYear()==curDay.getFullYear() && d.getMonth()==curDay.getMonth() && d.getDate()==curDay.getDate()) {
									curStatus = true;
								}
							});
						}
					} else {
						scope.workingdates.forEach(function(d) {
							if( d.getFullYear()==curDay.getFullYear() && d.getMonth()==curDay.getMonth() && d.getDate()==curDay.getDate()) {
								curStatus = true;
							}
						});
					}
					scope.workdays.push(curStatus);
					curDay = new Date(curDay.getTime()+86400000);
				}
				
			};
			
			/* view management */
			scope.zoomin = function() {
				scope.options.colwidth = (scope.options.colwidth==80?480:scope.options.colwidth*2);
				if( scope.options.colwidth > 480 ) {
					scope.options.colwidth = 480;
				}
				scope.updateCanvas();
			};
			scope.zoomout = function() {
				scope.options.colwidth = (scope.options.colwidth==480?80:scope.options.colwidth*0.5);
				if( scope.options.colwidth < 5 ) {
					scope.options.colwidth = 5;
				}
				scope.updateCanvas();
			};
			scope.toggleFullscreen = function() {
				elem.toggleClass('fullscreen');
				var div = elem[0];
				if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement ) {  // current working methods
					if (div.requestFullscreen) {
					  div.requestFullscreen();
					} else if (div.msRequestFullscreen) {
					  div.msRequestFullscreen();
					} else if (div.mozRequestFullScreen) {
					  div.mozRequestFullScreen();
					} else if (div.webkitRequestFullscreen) {
					  div.webkitRequestFullscreen();
					} else if( div.webkitEnterFullscreen) {
					  div.webkitEnterFullscreen();
					}
				} else {
				    if (document.exitFullscreen) {
				      document.exitFullscreen();
				    } else if (document.msExitFullscreen) {
				      document.msExitFullscreen();
				    } else if (document.mozCancelFullScreen) {
				      document.mozCancelFullScreen();
				    } else if (document.webkitExitFullscreen) {
				      document.webkitExitFullscreen();
				    }
				}
			};
			scope.$watch('startdate', function(newVal) {
				if( newVal === null ) {
					scope.startdate = new Date();
				}
			});
			scope.$watch('options.search', function(newVal) {
				if( newVal===undefined) { return;}
				var hash = scope.options._id;
				var pattern = new RegExp(scope.options.search, "i");
				scope.zones.forEach(function(c) {
					scope.visibleRowsCount[c._id] = 0;
					c.works.forEach(function(d) {
						var atLeastOneSubworkVisible = false;
						d.subworks.forEach(function(e) {
							e.visible[hash] = pattern.test(e.name) || (e.contractor && e.contractor.name && pattern.test(e.contractor.name));
							scope.visibleRowsCount[c._id] += (e.visible[hash] ? 1 : 0) 
							atLeastOneSubworkVisible |= e.visible;
						});
						d.visible[hash] = pattern.test(d.name) || (d.contractor && d.contractor.name && pattern.test(d.contractor.name)) || atLeastOneSubworkVisible;
						scope.visibleRowsCount[c._id] += (d.visible[hash] ? 1 : 0);
					});
				});
				scope.updateCanvas();
			});

	      	/* canvas resize logic */
		  	var w = angular.element($window);
			scope.getWindowWidth = function () { return w.width(); };
			scope.getParentWidth = function () { 
				var parentElement = elem.find('.canvascontainer')[0];
				return $(parentElement).width();			
			};
			scope.getTotalHeight = function() {
				var totalHeight = 0;
				var hash = scope.options._id;
				if( scope.zones ) {
					scope.zones.forEach(function(c) {
						if( totalHeight ) { totalHeight+=10;}
						var rows = 0;
						if( c.visible[hash] ) {
							var totalRows = 0;
							var visibleRows = 0;
							c.works.forEach(function(d) {
								totalRows++;
								if( d.visible[hash] ) { visibleRows++; }
								d.subworks.forEach(function(e) {
									totalRows++;
									if( e.visible[hash] ) { visibleRows++; }
								});
							});
							rows = (scope.options.removehiddenzones ? visibleRows : totalRows);
							totalHeight += scope.options.zonetitleheight;
							totalHeight += (rows+(scope.options.showcalendar?2:0)+(scope.options.showdaycounts?1:0))*scope.options.rowheight;
						}
					});
				}
				return totalHeight;
			};
			scope.updateViewOptions = function() {
				var totalHeight = scope.getTotalHeight(); 
				if( !scope.options.avoidRefresh || (scope.options.avoidRefresh===true && scope.canvas.height != totalHeight) ) {
					scope.canvas.height = totalHeight;
					scope.updateCanvas();
				}
			};
			scope.$watch('zones', function (newZones, oldZones) {
				var totalHeight = scope.getTotalHeight(); 
				if( !scope.options.avoidRefresh || (scope.options.avoidRefresh===true && scope.canvas.height != totalHeight) ) {
					scope.canvas.height = totalHeight;
					var parentElement = elem.find('.canvascontainer')[0];
					scope.canvas.width = $(parentElement).width();
					scope.updateCanvas();
				}
			}, true);
			scope.$watch(scope.getWindowWidth, function (newWidth, oldWidth) {
				if( scope.canvas && newWidth != oldWidth ) {
					var parentElement = elem.find('.canvascontainer')[0];
					scope.canvas.width = $(parentElement).width()/*-parseInt($(parentElement).css('padding-left'))-parseInt($(parentElement).css('padding-right'))*/;
					scope.updateCanvas();
				}
			});
			scope.$watch(scope.getParentWidth, function (newWidth, oldWidth) {
				if( scope.canvas && newWidth != oldWidth ) {
					var parentElement = elem.find('.canvascontainer')[0];
					scope.canvas.width = $(parentElement).width()/*-parseInt($(parentElement).css('padding-left'))-parseInt($(parentElement).css('padding-right'))*/;
					scope.updateCanvas();
				}
			});
	        w.bind('resize', function () {
	            scope.$apply();
	        });
	        /* end canvas resize logic */
	        
	        scope.resetSelection = function() {
				scope.selection = {type:0};
				scope.showcontextmenu = false;
	        };
	        /* mouse events */
	        scope.doMouseDown = function(jqevent) {
		        var event = jqevent.pageX ? jqevent : (jqevent.originalEvent.touches[0] || jqevent.originalEvent.changedTouches[0]);
	        	var offset = $(scope.canvas).offset();
	        	var coords = {x: event.pageX-offset.left, y: event.pageY-offset.top};
				var headerheight = ((scope.options.showcalendar ? 2 : 0 ) +(scope.options.showdaycounts ? 1 : 0 ))*scope.options.rowheight;
				var ctx = scope.canvas.getContext("2d");
				var hash = scope.options._id;
				scope.resetSelection();
				scope.mouseDown = true;
				/*
				if( coords.x <=(scope.options.zoneswidth+3) && coords.x >=(scope.options.zoneswidth-3) && coords.y>headerheight) { // zone width
    				scope.showcontextmenu = false;
	        		scope.selection = {type:'zoneswidth', startx:coords.x };
	        		elem.css('cursor','grabbing');
					elem.css('cursor','-webkit-grabbing');
					return;
				}
				*/
				var zoneHovered = -1;
				var zoneSize;
				for( var i=0;i<scope.zonesizes.length;i++ ) {
					if( coords.y >= scope.zonesizes[i].headerY && coords.y <= scope.zonesizes[i].calendarY ) {
						zoneHovered = i;
						break;
					}
				}
				
				if( coords.x <=(scope.options.zoneswidth+scope.options.workswidth+4) && coords.x >=(scope.options.zoneswidth+scope.options.workswidth-4) && coords.y<headerheight) { // works width
	        		scope.selection = {type:'workswidth', startx:coords.x };
					scope.showcontextmenu = false;
	        		elem.css('cursor','grabbing');
					elem.css('cursor','-webkit-grabbing');
					return;
				}
	      		if( zoneHovered>=0 && coords.x>(scope.options.workswidth+scope.options.zoneswidth)) { 
		      		zoneSize = scope.zonesizes[zoneHovered];
    				scope.showcontextmenu = false;
		      		//if( !scope.options.showcalendar || coords.y<scope.options.rowheight ) { return; }
		      		var interferencesY = zoneSize.calendarY-scope.options.rowheight;
		      		if( coords.y>=interferencesY && coords.y<=(interferencesY+scope.options.rowheight*0.4) && scope.options.caneditinterferences ) {
			      		var found = false;
			      		var startX = (-scope.options.startday)*scope.options.colwidth+(scope.options.zoneswidth+scope.options.workswidth);
			      		/* Disabled 
	        			for( var q=0;q<scope.interferences.length; q++ ) {
		        			var curInt = scope.interferences[q];
		        			if( coords.x>=(startX+curInt.day*scope.options.colwidth) && coords.x<=(startX+(curInt.day+curInt.duration)*scope.options.colwidth) ) {
			        			scope.selection = {type:'contractorsinterference', interference: curInt};
								found=true;
								break;
			        		}
						}
						*/
			      		if( found ) {
				      		return;
			      		}
		      		}
		      		if( scope.options.caneditcalendar == false ) { return; }
		      		scope.highlighteddays = null;
			  		scope.updateCanvas();
	        		var startday = Math.floor((coords.x-(scope.options.workswidth+scope.options.zoneswidth))/scope.options.colwidth)+scope.options.startday;
		        	scope.selection = {type:'day', startday:startday};
        			ctx.save();
        			ctx.beginPath();
        			ctx.fillStyle='rgba(255,0,0,0.2)';
        			var startX = Math.floor((coords.x-(scope.options.workswidth+scope.options.zoneswidth))/scope.options.colwidth)*scope.options.colwidth+(scope.options.workswidth+scope.options.zoneswidth);
        			scope.zonesizes.forEach(function(z) {
        				ctx.rect(startX, z.headerY, scope.options.colwidth, z.worksY-z.headerY);
						ctx.fill();
        			});
        			ctx.closePath();
        			ctx.restore();
		      		return; 
		      	} 
		      	if( scope.highlighteddays ) {
		      		scope.highlighteddays = null;
		      	}
			  	scope.updateCanvas();
	        	var removeHiddenZones = scope.options.removehiddenzones;
    			if( scope.popoverisshown ) {
        			popover.popover('hide');
        			scope.popoverisshown = false;
    			}
    			// Select zone
				zoneHovered=-1;
				for( var i=0;i<scope.zonesizes.length;i++ ) {
					if( coords.y >= scope.zonesizes[i].startY && coords.y <= scope.zonesizes[i].startY+scope.options.zonetitleheight ) {
						zoneHovered = i;
						break;
					}
				}
    			
	        	if( zoneHovered>=0 ) { 
		      		var canDrag = !removeHiddenZones && (scope.zones.filter(function(e) {return e.visible[hash]}).length==scope.zones.length);
		      		if( !canDrag ) { 
			      		elem.css('cursor','no-drop');
				  	} else {
	        			elem.css('cursor','grabbing');
						elem.css('cursor','-webkit-grabbing');
				  	}
					scope.showcontextmenu = true;
					scope.contextmenustyle = {left: 25, top:scope.zonesizes[zoneHovered].headerY+10};
        			ctx.save();
        			ctx.fillStyle='rgba(255,0,0,0.2)';
        			ctx.fillRect(0, scope.zonesizes[zoneHovered].startY, scope.canvas.width, scope.options.zonetitleheight);
        			ctx.restore();
        			
        			var tmpCanvas = document.createElement('canvas');
        			tmpCanvas.width = scope.canvas.width-2;
        			tmpCanvas.height = scope.options.zonetitleheight-2;
        			var tmpCtx = tmpCanvas.getContext('2d');
        			tmpCtx.putImageData( ctx.getImageData(0,scope.zonesizes[zoneHovered].startY, scope.canvas.width, scope.options.zonetitleheight), 0,0);
        			var tmpImage = new Image();
        			tmpImage.src = tmpCanvas.toDataURL();
					scope.selection = {type:'zone', zone:scope.zones[i], hasmoved: false};
        			scope.selection.image = tmpImage;
        			tmpCtx.clearRect(0,0,tmpCanvas.width, tmpCanvas.height);
					scope.currentImageData = ctx.getImageData(0,0, scope.canvas.width, scope.canvas.height);

					return;
	        	} 

				// Select work or timerange
	        	if( coords.x>=scope.canvas.width ) {
					scope.showcontextmenu = false;
		        	return;
		        }
				var timerangeStartX = scope.options.zoneswidth+scope.options.workswidth;
        		var curY = headerheight;
        		var nextY = curY;
        		
        		// Click on interference
        		if( coords.x>timerangeStartX && scope.options.caneditinterferences ) {
	        		var found=false;
					var startX = (-scope.options.startday)*scope.options.colwidth+(scope.options.zoneswidth+scope.options.workswidth);
					for( var i=0, zoneHeight=0;i<scope.zones.length; i++ ) {
        				var curZone = scope.zones[i];
						zoneHeight = (!removeHiddenZones ?  scope.totalRowsCount[scope.zones[i]._id] : scope.visibleRowsCount[scope.zones[i]._id] )*scope.options.rowheight;
						if( !curZone.interferences.length ) { continue;}
						if( coords.y<scope.zonesizes[i].calendarY || coords.y>scope.zonesizes[i].worksY ) { continue; }
						for( var j=0;j<curZone.interferences.length;j++ ) {
							var interference = curZone.interferences[j];
							if( (coords.x>=(startX+interference.day*scope.options.colwidth) && coords.x<=(startX+(interference.day)*scope.options.colwidth)+2) || (coords.x>=(startX+(interference.day+interference.duration)*scope.options.colwidth)-2 && coords.x<=(startX+(interference.day+interference.duration)*scope.options.colwidth)) ) {
			        			scope.selection = {type:'zoneinterference', zone: curZone, interference: interference};
								found=true;
								break;
								
							}
						}
					}
	        		if( found ) { return; }
        		}
        		curY = headerheight;
        		nextY = curY;
        		elem.css('cursor','grabbing');
        		elem.css('cursor','-webkit-grabbing');
        		for( var i=0;i<scope.zones.length; i++ ) {
        			if( !scope.zones[i].visible[hash] ) { continue;}
        			var curZone = scope.zones[i];
        			var visibleRowsCount = (!removeHiddenZones ?  scope.totalRowsCount[scope.zones[i]._id] : scope.visibleRowsCount[scope.zones[i]._id] );
        			if( !visibleRowsCount ) { continue;}
	        		if( coords.y>=scope.zonesizes[i].worksY || coords.y<=scope.zonesizes[i].calendarY) {
	        			continue;
		        	}
        			var rowIndex = Math.floor((coords.y-scope.zonesizes[i].calendarY)/scope.options.rowheight);
        			var idx = 0;
        			for( var j=0, c;j<curZone.works.length;j++) {
	        			c = curZone.works[j];
	        			if( removeHiddenZones && !c.visible[hash] ) { continue; }
	        			if( idx==rowIndex ) { // work line
		        			if( coords.x < timerangeStartX ) { // work highlight
			        			var cnt = 1 +  (!removeHiddenZones ?  c.subworks.length : c.subworks.filter(function(d) { return (d.visible); }).length );
			        			
								scope.showcontextmenu = true;
								scope.contextmenustyle = {left: 15+timerangeStartX-ctxmenubtnsize.w-5, top:scope.zonesizes[i].calendarY+rowIndex*scope.options.rowheight+scope.options.rowheight*0.5-ctxmenubtnsize.h*0.5};
			        			ctx.save();
			        			ctx.fillStyle='rgba(255,0,0,0.2)';
			        			ctx.fillRect(scope.options.zoneswidth, scope.zonesizes[i].calendarY +rowIndex*scope.options.rowheight, scope.canvas.width, scope.options.rowheight*cnt);
			        			ctx.restore();
			        			
			        			var tmpCanvas = document.createElement('canvas');
			        			tmpCanvas.width = scope.canvas.width-scope.options.zoneswidth-2;
			        			tmpCanvas.height = scope.options.rowheight*cnt-2;
			        			var tmpCtx = tmpCanvas.getContext('2d');
			        			tmpCtx.putImageData( ctx.getImageData(scope.options.zoneswidth,scope.zonesizes[i].calendarY +rowIndex*scope.options.rowheight, scope.canvas.width-scope.options.zoneswidth, scope.options.rowheight*cnt), 0,0);
			        			var tmpImage = new Image();
			        			tmpImage.src = tmpCanvas.toDataURL();
			        			scope.selection = {type:'work', zone: curZone, work:c, hasmoved: false };
			        			scope.selection.image = tmpImage;
			        			tmpCtx.clearRect(0,0,tmpCanvas.width, tmpCanvas.height);
							} else { // timerange highlight
				        		var day = Math.floor((coords.x-(scope.options.zoneswidth+scope.options.workswidth))/scope.options.colwidth)+scope.options.startday;
						        if( c.subworks.length ) {
							        var startday = 99999;
							        var endday = -99999;
							        c.subworks.forEach(function(e) {
								        e.timeranges.forEach(function(f) {
									       startday = Math.min(f.startDay, startday); 
									       endday = Math.max(f.startDay+f.duration, endday); 
								        });
							        });
				        			if( day >= startday && day < endday) {
										var fromX = (-scope.options.startday)*scope.options.colwidth+(scope.options.zoneswidth+scope.options.workswidth);
										var startX = fromX+startday*scope.options.colwidth;
										var endX = fromX+endday*scope.options.colwidth;
										var startY = scope.zonesizes[i].calendarY+rowIndex*scope.options.rowheight;
		
				        				scope.selection = {type:'totaltimerange', zone: curZone, work:c, edge:(( (endX-coords.x)<5 )?true:false), startx:coords.x, starty:startY, curx: coords.x, curstartday:startday, inviewstartday: startday, curduration:(endday-startday), inviewduration:(endday-startday), hasmoved: false};
				        				scope.updateCanvas();
										scope.currentImageData = ctx.getImageData(0,0, scope.canvas.width, scope.canvas.height);
										ctx.save();
										ctx.beginPath();
										ctx.rect((scope.options.zoneswidth+scope.options.workswidth), scope.zonesizes[i].calendarY , scope.canvas.width-(scope.options.zoneswidth+scope.options.workswidth), scope.zonesizes[i].worksY - scope.zonesizes[i].calendarY);
										ctx.clip();
										switch(scope.options.color) {
											case 'work':
												ctx.fillStyle = (c.color ? 'rgba('+c.color+',1.0)' : 'rgba(241, 194, 50, 0.8)');
												break;
											default:
												ctx.fillStyle = (curZone.color ? 'rgba('+curZone.color+',1.0)' : 'rgba(241, 194, 50, 0.8)');
												break;
										}
										scope.drawTotalAndSingletimeranges(ctx, fromX, startY, startday, endday, c);
					        			ctx.restore();
		 				        		break;
				        			}
							        
						        } else {
				        			for( var z=0;z<c.timeranges.length;z++ ) {
					        			var curTimerange = c.timeranges[z];
					        			if( day >= curTimerange.startDay && day < (curTimerange.startDay+curTimerange.duration) ) {
											var fromX = (-scope.options.startday)*scope.options.colwidth+(scope.options.zoneswidth+scope.options.workswidth);
											var startX = fromX+curTimerange.startDay*scope.options.colwidth;
											var endX = startX+curTimerange.duration*scope.options.colwidth;
											var startY = scope.zonesizes[i].calendarY+rowIndex*scope.options.rowheight;
			
					        				scope.selection = {type:'timerange', zone: curZone, work:c, edge:(( (endX-coords.x)<5 )?true:false), startx:coords.x, starty:startY, curx: coords.x, curstartday:curTimerange.startDay, inviewstartday: curTimerange.startDay, curduration:curTimerange.duration, inviewduration:curTimerange.duration, hasmoved: false, timerange: curTimerange, lowerlimit: (z?(c.timeranges[z-1]).startDay+(c.timeranges[z-1]).duration:1), upperlimit:(z+1<c.timeranges.length?(c.timeranges[z+1]).startDay:999999), parenttimerange: c};
					        				scope.updateCanvas();
											scope.currentImageData = ctx.getImageData(0,0, scope.canvas.width, scope.canvas.height);
											ctx.save();
											ctx.beginPath();
											ctx.rect((scope.options.zoneswidth+scope.options.workswidth), scope.zonesizes[i].calendarY , scope.canvas.width-(scope.options.zoneswidth+scope.options.workswidth), scope.zonesizes[i].worksY - scope.zonesizes[i].calendarY);
											ctx.clip();
											switch(scope.options.color) {
												case 'work':
													ctx.fillStyle = (c.color ? 'rgba('+c.color+',1.0)' : 'rgba(241, 194, 50, 0.8)');
													break;
												default:
													ctx.fillStyle = (curZone.color ? 'rgba('+curZone.color+',1.0)' : 'rgba(241, 194, 50, 0.8)');
													break;
											}
											scope.drawTimerange(ctx, fromX, startY, curTimerange);
						        			ctx.restore();
			 				        		break;
					        			}
				        			}
						        }
							}
							break;
	        			} else { 
		        			for( var z=0, d;z<c.subworks.length;z++) {
			        			d = c.subworks[z];
								if( removeHiddenZones && !d.visible[hash] ) { continue; }
			        			idx++;
			        			if( idx==rowIndex ) { // subwork line
									if( coords.x < timerangeStartX ) { // subwork highlight
										scope.showcontextmenu = true;
										scope.contextmenustyle = {left: 15+timerangeStartX-ctxmenubtnsize.w-5, top:scope.zonesizes[i].calendarY+rowIndex*scope.options.rowheight+scope.options.rowheight*0.5-ctxmenubtnsize.h*0.5};
					        			ctx.save();
					        			ctx.fillStyle='rgba(255,0,0,0.2)';
					        			ctx.fillRect(scope.options.zoneswidth, scope.zonesizes[i].calendarY +rowIndex*scope.options.rowheight, scope.canvas.width, scope.options.rowheight);
					        			ctx.restore();
					        			
					        			var tmpCanvas = document.createElement('canvas');
					        			tmpCanvas.width = scope.canvas.width-scope.options.zoneswidth-2;
					        			tmpCanvas.height = scope.options.rowheight-2;
					        			var tmpCtx = tmpCanvas.getContext('2d');
					        			tmpCtx.putImageData( ctx.getImageData(scope.options.zoneswidth, scope.zonesizes[i].calendarY +rowIndex*scope.options.rowheight, scope.canvas.width-scope.options.zoneswidth, scope.options.rowheight), 0,0);
					        			var tmpImage = new Image();
					        			tmpImage.src = tmpCanvas.toDataURL();
					        			scope.selection = {type:'subwork', zone: curZone, work:c,subwork:d, hasmoved: false };
					        			scope.selection.image = tmpImage;
					        			tmpCtx.clearRect(0,0,tmpCanvas.width, tmpCanvas.height);
					        		} else { // timerange highlight
						        		
					        			var day = Math.floor((coords.x-(scope.options.zoneswidth+scope.options.workswidth))/scope.options.colwidth)+scope.options.startday;
					        			for( var z=0;z<d.timeranges.length;z++ ) {
						        			var curTimerange = d.timeranges[z];
						        			if( day >= curTimerange.startDay && day < (curTimerange.startDay+curTimerange.duration) ) {
												var fromX = (-scope.options.startday)*scope.options.colwidth+(scope.options.zoneswidth+scope.options.workswidth);
												var startX = fromX+curTimerange.startDay*scope.options.colwidth;
												var endX = startX+curTimerange.duration*scope.options.colwidth;
												var startY = scope.zonesizes[i].calendarY +rowIndex*scope.options.rowheight;
				
						        				scope.selection = {type:'timerange', zone: curZone, work:c, edge:(( (endX-coords.x)<5 )?true:false), startx:coords.x, starty:startY, curx: coords.x, curstartday:curTimerange.startDay, inviewstartday: curTimerange.startDay, curduration:curTimerange.duration, inviewduration:curTimerange.duration, hasmoved: false, timerange: curTimerange, lowerlimit: (z?(d.timeranges[z-1]).startDay+(d.timeranges[z-1]).duration:1), upperlimit:(z+1<d.timeranges.length?(d.timeranges[z+1]).startDay:999999), parenttimerange: d};
						        				scope.updateCanvas();
												scope.currentImageData = ctx.getImageData(0,0, scope.canvas.width, scope.canvas.height);
												ctx.save();
												ctx.beginPath();
												ctx.rect((scope.options.zoneswidth+scope.options.workswidth),scope.zonesizes[i].calendarY , scope.canvas.width-(scope.options.zoneswidth+scope.options.workswidth), scope.zonesizes[i].worksY-scope.zonesizes[i].calendarY);
												ctx.clip();
												switch(scope.options.color) {
													case 'work':
														ctx.fillStyle = (c.color ? 'rgba('+c.color+',1.0)' : 'rgba(241, 194, 50, 0.8)');
														break;
													default:
														ctx.fillStyle = (curZone.color ? 'rgba('+curZone.color+',1.0)' : 'rgba(241, 194, 50, 0.8)');
														break;
												}
												scope.drawTimerange(ctx, fromX, startY, curTimerange);
							        			ctx.restore();
				 				        		break;
						        			}
						        			
					        			}
					        		}
									break;
			        			}
		        			}
	        			}
						idx++;
        			}
	        		break;
        		}
        		if( !scope.selection.type ) {
	        		scope.selection = {type:'move', startx:coords.x, startday:scope.options.startday };
	        		scope.showcontextmenu = false;
        		}
	        };
	        scope.doMouseUp = function(jqevent) {
		        var event = jqevent.pageX ? jqevent : (jqevent.originalEvent.touches[0] || jqevent.originalEvent.changedTouches[0]);
	        	var offset = $(scope.canvas).offset();
	        	var coords = {x: event.pageX-offset.left, y: event.pageY-offset.top};
	        	var removeHiddenZones = scope.options.removehiddenzones;
				var headerheight = ((scope.options.showcalendar ? 2 : 0 ) +(scope.options.showdaycounts ? 1 : 0 ))*scope.options.rowheight;
				var hash = scope.options._id;
				scope.mouseDown = false;
	      		if( scope.selection.type ) { 
		        	var hasMoved = scope.selection.hasmoved;
		        	scope.selection.hasmoved = false;
		      		if( scope.selection.type=='day' ) {
			      		if( scope.options.caneditcalendar == false ) { 
				      		scope.resetSelection();
				      		return;
			      		}
        				var fromDay = scope.selection.startday;
        				var endDay = Math.floor((coords.x-(scope.options.workswidth+scope.options.zoneswidth))/scope.options.colwidth)+scope.options.startday;
        				var startDay = Math.min(fromDay, endDay);
        				var duration = Math.max(fromDay, endDay) - startDay+1;
        				scope.highlighteddays = { startday: startDay, duration: duration };
        				scope.resetSelection();
		      		} else if( scope.selection.type=='zone') { 
			      		var canDrag =  (scope.options.candrag == true) && !removeHiddenZones && (scope.zones.filter(function(e) {return e.visible[hash]}).length==scope.zones.length);
						for( var i=0;i<scope.zonesizes.length;i++ ) {
							if( coords.y >= scope.zonesizes[i].startY && coords.y <= scope.zonesizes[i].worksY ) {
			        			if( hasMoved && canDrag ) { // drag
									if( scope.zones.indexOf(scope.selection.zone)==i) { break;}
					        		var tmpZone = scope.selection.zone;
									scope.zones[scope.zones.indexOf(scope.selection.zone)] = scope.zones[i];
									scope.zones[i] = tmpZone;
			        			} 								
								break;
							}
						}
		        	} else if( scope.selection.type=='work' ) {
		        		for( var i=0;i<scope.zones.length; i++ ) {
			        		var curZone = scope.zones[i];
	        				if( !curZone.visible[hash] ) { continue;}
		        			var visibleRowsCount = (!removeHiddenZones ?  scope.totalRowsCount[curZone._id] : scope.visibleRowsCount[curZone._id] );
			        		if( coords.y<scope.zonesizes[i].worksY ) { 
			        			if( hasMoved && scope.selection.zone!=curZone ) { break; }
			        			var canDrag = (scope.options.candrag == true) && (curZone.works.length == curZone.works.filter(function(e) { return e.visible;}).length);
			        			if( !canDrag ) {
									elem.css('cursor','auto');
									scope.updateCanvas();
									return;
			        			}
			        			var nextWorkY = scope.zonesizes[i].calendarY;
			        			var rowIndex = -1;
			        			for( var j=0;j<curZone.works.length;j++ ) {
				        			nextWorkY += (1+(curZone.works[j].subworks?curZone.works[j].subworks.length:0))*scope.options.rowheight;
				        			if( coords.y<nextWorkY) { 
					        			rowIndex = j; 
					        			break;
					        		}
			        			}

			        			if( rowIndex<0 ) { break; }
								var selectedZone = scope.selection.zone;
			        			if( hasMoved ) { // drag
									if( selectedZone.works.indexOf(scope.selection.work)==rowIndex) { break;}
					        		var tmpWork = scope.selection.work;
									selectedZone.works[ selectedZone.works.indexOf( scope.selection.work ) ] = selectedZone.works[ rowIndex ];
									selectedZone.works[rowIndex] = tmpWork;
			        			} 
				        		break;
			        		}
		        		};
		        	} else if( scope.selection.type=='subwork' ) {
		        		for( var i=0;i<scope.zones.length; i++ ) {
			        		var curZone = scope.zones[i];
			        		var curY = scope.zonesizes[i].calendarY;
							var nextY = curY;
	        				if( !curZone.visible[hash] ) { continue;}
		        			var visibleRowsCount = (!removeHiddenZones ?  scope.totalRowsCount[curZone._id] : scope.visibleRowsCount[curZone._id] );
			        		if( coords.y<scope.zonesizes[i].worksY ) { 
			        			if( hasMoved && scope.selection.zone!=curZone ) { break; }
			        			var nextWorkY = curY;
			        			var curWork = null;
			        			for( var j=0;j<curZone.works.length;j++ ) {
				        			nextWorkY += (1+(curZone.works[j].subworks?curZone.works[j].subworks.length:0))*scope.options.rowheight;
				        			if( coords.y<nextWorkY) { 
					        			curWork = curZone.works[j]; 
					        			break;
					        		}
				        			curY = nextWorkY;
			        			}
								if( scope.selection.work!=curWork ) { break; }
			        			var canDrag = (scope.options.candrag == true) && (curWork.subworks.length == curWork.subworks.filter(function(e) { return e.visible;}).length);
			        			if( !canDrag ) {
									elem.css('cursor','auto');
									scope.updateCanvas();
									return;
			        			}
			        			var rowIndex = Math.floor((coords.y-curY)/scope.options.rowheight)-1;
								if( rowIndex < 0 ) { break; } // Work title line
								var selectedWork = scope.selection.work;
			        			if( hasMoved ) { // drag
									if( selectedWork.subworks.indexOf(scope.selection.subwork)==rowIndex) { break;}
					        		var tmpSubwork = scope.selection.subwork;
									selectedWork.subworks[ selectedWork.subworks.indexOf( scope.selection.subwork ) ] = selectedWork.subworks[ rowIndex ];
									selectedWork.subworks[rowIndex] = tmpSubwork;
			        			}
				        		break;
			        		}
			        		curY = nextY;
		        		};
		        	} else if( scope.selection.type=='timerange' ) {
	        			var selectedTimerange = scope.selection.timerange;
	        			selectedTimerange.startDay = scope.selection.curstartday;
	        			selectedTimerange.duration = scope.selection.curduration;
	        			if( hasMoved ) { 
		        			scope.updateZoneInterferences(scope.selection.zone);
		        		}
		        		scope.resetSelection(); 
		        	} else if( scope.selection.type=='totaltimerange' ) {
						var srcStartday = 99999, deltaday = 0;
	        			var selectedWork = scope.selection.work;
						selectedWork.subworks.forEach(function(e) {
							e.timeranges.forEach(function(tr) {
								srcStartday = Math.min(srcStartday, tr.startDay);
							});
						});
						deltaday = scope.selection.curstartday-srcStartday;
						selectedWork.subworks.forEach(function(e) {
							e.timeranges.forEach(function(tr) {
								tr.startDay +=deltaday;
							});
						});
		        		scope.updateZoneInterferences(scope.selection.zone);
	        			scope.resetSelection();
		        	} else if( scope.selection.type=='zoneinterference' ) {
			        	var interference = scope.selection.interference;
			        	var zone = scope.selection.zone;
						scope.manageZoneInterferences(zone, interference);
	        			scope.resetSelection();
		        	} else if( scope.selection.type=='contractorsinterference' ) {
			        	var interference = scope.selection.interference;
						scope.manageContractorsInterferences(interference);
	        			scope.resetSelection();
		        	} else {
		  				scope.resetSelection();
		        	}
	      		} else {
		  			scope.resetSelection();
	      		}
			  	elem.css('cursor','auto');
		      	scope.updateCanvas();
	        };
			scope.doMouseOut = function( event ) { 
	      		scope.updateCanvas();
				if( scope.popoverisshown ) {
					popover.popover('hide');
					scope.popoverisshown = false;
				}
			};
			scope.doMouseMove = function(jqevent) {
				var event = jqevent.pageX ? jqevent : (jqevent.originalEvent.touches[0] || jqevent.originalEvent.changedTouches[0]);
				var offset = $(scope.canvas).offset();
				var coords = {x: event.pageX-offset.left, y: event.pageY-offset.top};
				var headerheight = ((scope.options.showcalendar ? 2 : 0 ) +(scope.options.showdaycounts ? 1 : 0 ))*scope.options.rowheight;
				var ctx = scope.canvas.getContext("2d");
				var hash = scope.options._id;
    				popover.popover('hide');
				elem.css('cursor','auto');
    				scope.popoverisshown = false;

				if( scope.mouseDown && scope.selection.type == 'workswidth' ) {
					scope.options.workswidth = Math.min(400,Math.max(50,coords.x-scope.options.zoneswidth));
					scope.updateCanvas();
					return;
				}

				if( coords.x <=(scope.options.zoneswidth+scope.options.workswidth+4) && coords.x >=(scope.options.zoneswidth+scope.options.workswidth-4) && coords.y<headerheight) { // works width
					if( !scope.mouseDown ) {
						elem.css('cursor','col-resize');
						return;
					}
				}
				var zoneHovered = -1;
				var zoneSize;
				
				for( var i=0;i<scope.zonesizes.length;i++ ) {
					if( coords.y >= scope.zonesizes[i].headerY && coords.y <= scope.zonesizes[i].calendarY ) {
						zoneHovered = i;
						break;
					}
				}
	      		if( zoneHovered >= 0 ) {  // header
		      		zoneSize = scope.zonesizes[zoneHovered];	    			
					ctx.putImageData(scope.currentImageData, 0,0);
					if( coords.x>(scope.options.workswidth+scope.options.zoneswidth)) {
						if( scope.selection.type=='day' ) {
		        				var fromX = scope.options.workswidth+scope.options.zoneswidth + (scope.selection.startday-scope.options.startday)*scope.options.colwidth;
		        				var endX = Math.floor((coords.x-(scope.options.workswidth+scope.options.zoneswidth))/scope.options.colwidth)*scope.options.colwidth+(scope.options.workswidth+scope.options.zoneswidth);
		        				var startX = Math.min(fromX, endX);
		        				var deltaX = Math.max(fromX, endX) + scope.options.colwidth - startX;
			        			ctx.save();
			        			ctx.beginPath();
			        			ctx.fillStyle='rgba(255,0,0,0.2)';
			        			ctx.rect(startX, zoneSize.headerY, deltaX, zoneSize.worksY-zoneSize.calendarY);
			        			ctx.fill();
			        			ctx.closePath();
			        			ctx.restore();
							return;
						}
		        			var startY = zoneSize.headerY;
		        			if( coords.y>=(headerheight-scope.options.rowheight) && coords.y<=(headerheight-scope.options.rowheight*0.4) && scope.options.showinterferences ) { // interferences zone
							var startX = (-scope.options.startday)*scope.options.colwidth+(scope.options.zoneswidth+scope.options.workswidth);
								/* Disabled 
			        			for( var q=0;q<scope.interferences.length; q++ ) {
				        			var curInt = scope.interferences[q];
				        			if( coords.x>=(startX+curInt.day*scope.options.colwidth) && coords.x<=(startX+(curInt.day+curInt.duration)*scope.options.colwidth)) {
										ctx.strokeStyle = curInt.solved ? 'rgba(102,204,0,0.8)' : 'rgba(204,204,0,0.8)';
										ctx.fillStyle = curInt.solved ? 'rgba(153,255,51,0.8)' : 'rgba(255,255,102,0.8)';
										ctx.fillRect(startX+curInt.day*scope.options.colwidth, startY, curInt.duration*scope.options.colwidth,scope.options.rowheight*0.4); 
										ctx.strokeRect(startX+curInt.day*scope.options.colwidth+1, startY+1, curInt.duration*scope.options.colwidth-2,scope.options.rowheight*0.4-2); 
					        			
					        			popover.css({top:startY, left:(startX+(curInt.day+curInt.duration*0.5)*scope.options.colwidth)});
				        				var startdate = new Date(scope.startdate.getTime()+(curInt.day-1)*86400000);
				        				startdate.setHours(12);
				        				var html = "<b>"+$filter('date')(startdate, 'd MMMM');
				        				var enddate = new Date(scope.startdate.getTime()+(curInt.day-1+curInt.duration-1)*86400000);
				        				html += (curInt.duration>1?("-"+$filter('date')(enddate, 'd MMMM')):"");
				        				
				        				html += "</b><br>Interferenza "+(curInt.solved?"":"non ")+"risolta";
				        				if( curInt.notes.length ) {
				        					html += "<div class='text-muted'>"+curInt.notes+"</div>";
				        				}
				        				if( curInt.solved == false ) {
					        				html += "<br><i>Clicca per modificarla.</i>";
				        				}
				        				popover.data("bs.popover").options.content=html;
					        			popover.popover('show');
					        			scope.popoverisshown = true;
										
										return;
				        			}
			        			}
			        			*/
		        			} 
		        			if( scope.options.caneditcalendar == false ) { return; }
		        			ctx.save();
		        			ctx.beginPath();
		        			ctx.fillStyle='rgba(120,120,120,0.3)';
		        			var startX = Math.floor((coords.x-(scope.options.workswidth+scope.options.zoneswidth))/scope.options.colwidth)*scope.options.colwidth+(scope.options.workswidth+scope.options.zoneswidth);
		        			scope.zonesizes.forEach(function(z) {
		        				ctx.rect(startX, z.headerY, scope.options.colwidth, z.worksY-z.headerY);
							ctx.fill();
		        			});
		        			ctx.closePath();
		        			ctx.restore();
						return; 
					}
				}
	        		var removeHiddenZones = scope.options.removehiddenzones;
		        var zonebutton = $(elem.find('#zonebutton'));
		        var workbutton = $(elem.find('#workbutton'));
		        zonebutton.css({top: -9999});
		        workbutton.css({top: -9999});
		        
	      		if( scope.selection.type && scope.mouseDown ) { 
	      			scope.selection.hasmoved = true;
	        			elem.css('cursor','grabbing');
					elem.css('cursor','-webkit-grabbing');
		        		if( scope.selection.type=='zone') { 
						var canDrag = !removeHiddenZones && (scope.zones.filter(function(e) {return e.visible[hash]}).length==scope.zones.length) && scope.options.candrag == true;
						ctx.putImageData(scope.currentImageData, 0,0);
						if( !canDrag ) { 
				      		elem.css('cursor','no-drop');
					  		return;
					  	}
					  	zoneHovered = -1;
						for( var i=0;i<scope.zonesizes.length;i++ ) {
							if( coords.y >= scope.zonesizes[i].startY && coords.y <= scope.zonesizes[i].worksY ) {
								zoneHovered = i;
								break;
							}
						}
						if( zoneHovered >= 0) {
			        			ctx.save();
			        			ctx.globalAlpha = 0.4;
			        			ctx.drawImage(scope.selection.image, 0, coords.y);
			        			ctx.beginPath();
			        			ctx.globalAlpha = 1.0;
			        			ctx.strokeStyle='rgb(255,0,0)';
			        			ctx.lineWidth=3;
			        			ctx.moveTo(0, scope.zonesizes[zoneHovered].startY);
			        			ctx.lineTo(scope.canvas.width, scope.zonesizes[zoneHovered].startY);
			        			ctx.stroke();
			        			ctx.closePath();
			        			ctx.restore();
						}
						return;
					} 
		        		if( scope.selection.type=='work' ) {
						ctx.putImageData(scope.currentImageData, 0,0);
			        		for( var i=0;i<scope.zones.length; i++ ) {
				        		var curY;
				        		var curZone = scope.zones[i];
		        				if( !curZone.visible[hash] ) { continue;}
			        			var visibleRowsCount = (!removeHiddenZones ? scope.totalRowsCount[curZone._id] : scope.visibleRowsCount[curZone._id] );
				        		if( coords.y<scope.zonesizes[i].worksY ) {
				        			if( scope.selection.zone!=curZone ) { break; }
				        			var canDrag =  (scope.options.candrag == true) && (curZone.works.length == curZone.works.filter(function(e) { return e.visible;}).length);
				        			if( !canDrag ) {
										elem.css('cursor','no-drop');
										return;
				        			}
				        			var nextWorkY = scope.zonesizes[i].calendarY;
				        			for( var j=0;j<curZone.works.length;j++ ) {
					        			nextWorkY += (1+(curZone.works[j].subworks?curZone.works[j].subworks.length:0))*scope.options.rowheight;
					        			if( coords.y<nextWorkY) { break;}
					        			curY = nextWorkY;
				        			}
				        			ctx.save();
				        			ctx.globalAlpha = 0.4;
				        			ctx.drawImage(scope.selection.image, scope.options.zoneswidth, curY);
				        			ctx.beginPath();
				        			ctx.globalAlpha = 1.0;
				        			ctx.strokeStyle='rgba(255,0,0,0.8)';
				        			ctx.lineWidth=3;
				        			ctx.moveTo(scope.options.zoneswidth, curY);
				        			ctx.lineTo(scope.canvas.width, curY);
				        			ctx.stroke();
				        			ctx.closePath();
				        			ctx.restore();
					        		break;
				        		}
			        		};
						return;
					}
		        		if( scope.selection.type=='subwork' ) {
						ctx.putImageData(scope.currentImageData, 0,0);
			        		for( var i=0;i<scope.zones.length; i++ ) {
				        		var curY;
				        		var curZone = scope.zones[i];
		        				if( !curZone.visible[hash] ) { continue;}
			        			var visibleRowsCount = (!removeHiddenZones ? scope.totalRowsCount[curZone._id] : scope.visibleRowsCount[curZone._id] );
				        		if( coords.y>scope.zonesizes[i].calendarY && coords.y<scope.zonesizes[i].worksY ) {
				        			if( scope.selection.zone!=curZone ) { break; }
				        			var nextWorkY = scope.zonesizes[i].calendarY;
				        			curY = nextWorkY;
				        			var curWork = null;
				        			for( var j=0;j<curZone.works.length;j++ ) {
					        			nextWorkY += (1+(curZone.works[j].subworks?curZone.works[j].subworks.length:0))*scope.options.rowheight;
					        			if( coords.y<nextWorkY) { 
						        			curWork = curZone.works[j];
						        			break;
						        		}
					        			curY = nextWorkY;
				        			}
				        			if( scope.selection.work!=curWork ) { break; }
				        			var canDrag =  (scope.options.candrag == true) && (curWork.subworks.length == curWork.subworks.filter(function(e) { return e.visible;}).length);
				        			if( !canDrag ) {
										elem.css('cursor','no-drop');
										return;
				        			}
	
				        			var rowIndex = Math.floor((coords.y-curY)/scope.options.rowheight);
				        			if( rowIndex<1 ) { return; }
				        			ctx.save();
				        			ctx.globalAlpha = 0.4;
				        			ctx.drawImage(scope.selection.image, scope.options.zoneswidth, curY+rowIndex*scope.options.rowheight);
				        			ctx.beginPath();
				        			ctx.globalAlpha = 1.0;
				        			ctx.strokeStyle='rgba(255,0,0,0.8)';
				        			ctx.lineWidth=3;
				        			ctx.moveTo(scope.options.zoneswidth, curY+rowIndex*scope.options.rowheight);
				        			ctx.lineTo(scope.canvas.width, curY+rowIndex*scope.options.rowheight);
				        			ctx.stroke();
				        			ctx.closePath();
				        			ctx.restore();
					        		break;
				        		}
				        		//curY = nextY;
			        		};
						return;
					}
			        	if( scope.selection.type=='timerange' ) {
		        			var selectedWork = scope.selection.work;
				        	
		        			if( ((scope.options.zoneswidth+scope.options.workswidth)+(scope.selection.curstartday - scope.options.startday + 1 + scope.selection.curduration )* scope.options.colwidth )>(scope.canvas.width-50) ) { // near upper limit
			        			if( coords.x>scope.selection.curx ) { // moving beyond
			        				if( scope.selection.boundLimit == 1 ) { // already set, do nothing
				        				return;
			        				}
			        				scope.selection.boundLimit = 1;
			        			} else if( scope.selection.boundLimit == 1 ) {
			        				scope.selection.inviewstartday = scope.selection.curstartday;
			        				scope.selection.inviewduration = scope.selection.curduration;
			        				scope.selection.startx = scope.selection.curx;
				        			scope.selection.boundLimit = undefined;
			        			}
		        			} 
		        			if( (scope.selection.curstartday- scope.options.startday ) * scope.options.colwidth<=0 && !scope.selection.edge) { // nearlower limit
		        			 	if( coords.x<scope.selection.curx ) { // moving below
			        				if( scope.selection.boundLimit == -1 ) { // already set, do nothing
				        				return;
			        				}
			        				scope.selection.boundLimit = -1;
		        				} else if( scope.selection.boundLimit == -1 ) {
			        				scope.selection.inviewstartday = scope.selection.curstartday;
			        				scope.selection.inviewduration = scope.selection.curduration;
			        				scope.selection.startx = scope.selection.curx;
				        			scope.selection.boundLimit = undefined;
		        				}
		        			}
		        			if( scope.selection.boundLimit ) {
		        				var intervalFunction = $interval(function() {
			        				var isActive = scope.selection.type && scope.selection.boundLimit;
			        				if( !isActive ) {
										scope.options.avoidRefresh=false;
				        				$interval.cancel(intervalFunction);
										return;
			        				}
			        				if( scope.options.startday == 1 && scope.selection.boundLimit < 0 ) {
			        					return;
			        				}
			        				var day = 0;
			        				var duration = 0;
			        				if( !scope.selection.edge ) {
					        			scope.options.startday=Math.max(1, scope.options.startday + scope.selection.boundLimit);
					        			scope.selection.curstartday=Math.max(1, scope.selection.curstartday + scope.selection.boundLimit);
										day = scope.selection.curstartday;
										duration = scope.selection.curduration;
										if( day < scope.selection.lowerlimit) {
											if( confirm("Vuoi unire le due fasi temporali?")) {
												scope.joinTimeranges(scope.selection.timerange, scope.selection.parenttimerange, null, duration, -1);
												scope.resetSelection();
												return;
											} else {
												scope.doMouseUp(event);
												return;
											}
										}
										if( day+duration > scope.selection.upperlimit) {
											if( confirm("Vuoi unire le due fasi temporali?")) {
												scope.joinTimeranges(scope.selection.timerange, scope.selection.parenttimerange,scope.selection.upperlimit-duration, duration, 1);
												scope.resetSelection();
												return;
											} else {
												scope.doMouseUp(event);
												return;
											}
										}
			        				} else {
					        			scope.options.startday=Math.max(1, scope.options.startday + scope.selection.boundLimit);
					        			scope.selection.curduration=Math.max(1, scope.selection.curduration + scope.selection.boundLimit);
										day = scope.selection.curstartday;
										duration = scope.selection.curduration;
										if( day+duration > scope.selection.upperlimit) {
											if( confirm("Vuoi unire le due fasi temporali?")) {
												scope.joinTimeranges(scope.selection.timerange, scope.selection.parenttimerange,day, scope.selection.upperlimit-day, 1);
												scope.resetSelection();
												return;
											} else {
												scope.doMouseUp(event);
												return;
											}
										}
			        				}
			        				scope.updateCanvas();
									scope.currentImageData = ctx.getImageData(0,0, scope.canvas.width, scope.canvas.height);
				        			var selectedWork = scope.selection.work;
									var startX = (day-scope.options.startday)*scope.options.colwidth+(scope.options.zoneswidth+scope.options.workswidth);
									var endX = startX+duration*scope.options.colwidth;
									var startY = scope.selection.starty;
									ctx.save();
									ctx.fillStyle = 'rgba(80,80,80,1.0)';
									ctx.strokeStyle='rgba(255,0,0,0.8)';
									ctx.font = 'normal 12px arial';
									zoneHovered = -1;
									for( var i=0;i<scope.zonesizes.length;i++ ) {
										if( coords.y>scope.zonesizes[i].calendarY && coords.y<scope.zonesizes[i].worksY) {
											zoneHovered=i;
											break;
										}
									}
									if( scope.selection.edge ) {
										var textwidth = (ctx.measureText(duration+'')).width;
										if( scope.options.colwidth<40 ) {
											ctx.fillText(duration+'', endX-textwidth-2, scope.zonesizes[zoneHovered].calendarY-scope.options.rowheight*0.5-6);										
											ctx.beginPath();
											ctx.moveTo(endX, scope.zonesizes[zoneHovered].calendarY-scope.options.rowheight);
											ctx.lineTo(endX, scope.zonesizes[zoneHovered].worksY);
											ctx.stroke();
										}
									} else {
										if( scope.options.colwidth<40 ) {
											ctx.fillText(day+'', startX+2, scope.zonesizes[zoneHovered].calendarY-scope.options.rowheight*0.5-6);
											ctx.beginPath();
											ctx.moveTo(startX, scope.zonesizes[zoneHovered].calendarY-scope.options.rowheight);
											ctx.lineTo(startX, scope.zonesizes[zoneHovered].worksY);
											ctx.stroke();
										}
									}
									ctx.beginPath();
									ctx.rect((scope.options.zoneswidth+scope.options.workswidth), scope.zonesizes[zoneHovered].calendarY , scope.canvas.width-(scope.options.zoneswidth+scope.options.workswidth), scope.zonesizes[zoneHovered].worksY-scope.zonesizes[zoneHovered].calendarY );
									ctx.clip();
									switch(scope.options.color) {
										case 'work':
											ctx.fillStyle = (selectedWork.color ? 'rgba('+selectedWork.color+',1.0)' : 'rgba(241, 194, 50, 0.8)');
											break;
										default:
											ctx.fillStyle = (scope.selection.zone.color ? 'rgba('+scope.selection.zone.color+',1.0)' : 'rgba(241, 194, 50, 0.8)');
											break;
									}
									ctx.lineWidth = 3;
									scope.drawTask(ctx, startX, startY, duration);
				        			ctx.restore();
			        			},100);
			        			return;
		        			}
		        			var deltaday = Math.floor((coords.x-scope.selection.startx)/scope.options.colwidth);
		        			var duration = 0;
		        			var day=0;
		        			if( !scope.selection.edge ) {
								day = Math.max(1, scope.selection.inviewstartday+deltaday);
								duration = scope.selection.inviewduration;
								if( day < scope.selection.lowerlimit) {
									if( confirm("Vuoi unire le due fasi temporali?")) {
										scope.joinTimeranges(scope.selection.timerange, scope.selection.parenttimerange, null, duration, -1);
										scope.resetSelection();
										return;
									} else {
										scope.doMouseUp(event);
										return;
									}
								}
								if( day+duration > scope.selection.upperlimit) {
									if( confirm("Vuoi unire le due fasi temporali?")) {
										scope.joinTimeranges(scope.selection.timerange, scope.selection.parenttimerange,scope.selection.upperlimit-duration, duration, 1);
										scope.resetSelection();
										return;
									} else {
										scope.doMouseUp(event);
										return;
									}
								}
		        			} else {
			        			day = scope.selection.inviewstartday;
								duration = Math.max(1, scope.selection.inviewduration+deltaday);
								if( day+duration > scope.selection.upperlimit) {
									if( confirm("Vuoi unire le due fasi temporali?")) {
										scope.joinTimeranges(scope.selection.timerange, scope.selection.parenttimerange,day, scope.selection.upperlimit-day, 1);
										scope.resetSelection();
										return;
									} else {
										scope.doMouseUp(event);
										return;
									}
								}
		        			}
		        			scope.options.avoidRefresh=true;
							ctx.putImageData(scope.currentImageData, 0,0);
		        			scope.selection.curduration=duration;
		        			scope.selection.curstartday=day;
		        			scope.selection.curx = coords.x;
		        			if( day<scope.options.startday ) {
			        			scope.options.startday = day;
		        				scope.updateCanvas();
								scope.currentImageData = ctx.getImageData(0,0, scope.canvas.width, scope.canvas.height);
		        			}
							var startX = (day-scope.options.startday)*scope.options.colwidth+(scope.options.zoneswidth+scope.options.workswidth);
							var endX = startX+duration*scope.options.colwidth;
							var startY = scope.selection.starty;
							ctx.save();
							ctx.fillStyle = 'rgba(80,80,80,1.0)';
							ctx.strokeStyle='rgba(255,0,0,0.8)';
							ctx.font = 'normal 12px arial';
							zoneHovered = -1;
							for( var i=0;i<scope.zonesizes.length;i++ ) {
								if( coords.y>scope.zonesizes[i].calendarY && coords.y<scope.zonesizes[i].worksY) {
									zoneHovered=i;
									break;
								}
							}
							if( scope.selection.edge ) {
								var textwidth = (ctx.measureText(duration+'')).width;
								if( scope.options.colwidth<40 ) {
									ctx.fillText(duration+'', endX-textwidth-2, scope.zonesizes[zoneHovered].calendarY-scope.options.rowheight*0.5-6);
									ctx.beginPath();
									ctx.moveTo(endX, scope.zonesizes[zoneHovered].calendarY -scope.options.rowheight);
									ctx.lineTo(endX, scope.zonesizes[zoneHovered].worksY);
									ctx.stroke();
								}
							} else {
								if( scope.options.colwidth<40 ) {
									ctx.fillText(day+'', startX+2, scope.zonesizes[zoneHovered].calendarY-scope.options.rowheight*0.5-6);
									ctx.beginPath();
									ctx.moveTo(startX, scope.zonesizes[zoneHovered].calendarY -scope.options.rowheight);
									ctx.lineTo(startX, scope.zonesizes[zoneHovered].worksY);
									ctx.stroke();
								}
							}
							ctx.beginPath();
							ctx.rect((scope.options.zoneswidth+scope.options.workswidth),scope.zonesizes[zoneHovered].calendarY , scope.canvas.width-(scope.options.zoneswidth+scope.options.workswidth), scope.zonesizes[zoneHovered].worksY-scope.zonesizes[zoneHovered].calendarY);
							ctx.clip();
							switch(scope.options.color) {
								case 'work':
									ctx.fillStyle = (selectedWork.color ? 'rgba('+selectedWork.color+',1.0)' : 'rgba(241, 194, 50, 0.8)');
									break;
								default:
									ctx.fillStyle = (scope.selection.zone.color ? 'rgba('+scope.selection.zone.color+',1.0)' : 'rgba(241, 194, 50, 0.8)');
									break;
							}
							ctx.lineWidth = 3;
							scope.drawTask(ctx, startX, startY, duration);
							var indexOfWork = scope.selection.zone.works.indexOf(selectedWork);
							var curIndex = 0;
							scope.selection.zone.works.forEach(function(c) {
								if( c != selectedWork ) {
									if( c.startday < (day+duration) && (c.startday+c.duration)>day ) {//conflict
										ctx.save();
										ctx.lineWidth=4;
										ctx.fillStyle='rgba(255,0,0,0.4)';
										var fromDay = Math.max(day,c.startday)-scope.options.startday;
										var range = Math.min(day+duration, c.startday+c.duration)-fromDay-1;
										var zoneY = startY - indexOfWork*scope.options.rowheight;
										ctx.fillRect(fromDay*scope.options.colwidth+(scope.options.zoneswidth+scope.options.workswidth), zoneY+Math.min(indexOfWork, curIndex)*scope.options.rowheight, range*scope.options.colwidth, (Math.abs(curIndex-indexOfWork)+1)*scope.options.rowheight);
										ctx.restore();
										
									}
								}
								curIndex++;
							});
		        			ctx.restore();
							return;
			        	} 
			        	if( scope.selection.type=='totaltimerange' ) {
		        			var selectedWork = scope.selection.work;
				        	
		        			if( ((scope.options.zoneswidth+scope.options.workswidth)+(scope.selection.curstartday - scope.options.startday + 1 + scope.selection.curduration )* scope.options.colwidth )>(scope.canvas.width-50) ) { // near upper limit
			        			if( coords.x>scope.selection.curx ) { // moving beyond
			        				if( scope.selection.boundLimit == 1 ) { // already set, do nothing
				        				return;
			        				}
			        				scope.selection.boundLimit = 1;
			        			} else if( scope.selection.boundLimit == 1 ) {
			        				scope.selection.inviewstartday = scope.selection.curstartday;
			        				scope.selection.inviewduration = scope.selection.curduration;
			        				scope.selection.startx = scope.selection.curx;
				        			scope.selection.boundLimit = undefined;
			        			}
		        			} 
		        			if( (scope.selection.curstartday- scope.options.startday ) * scope.options.colwidth<=0 && !scope.selection.edge) { // nearlower limit
		        			 	if( coords.x<scope.selection.curx ) { // moving below
			        				if( scope.selection.boundLimit == -1 ) { // already set, do nothing
				        				return;
			        				}
			        				scope.selection.boundLimit = -1;
		        				} else if( scope.selection.boundLimit == -1 ) {
			        				scope.selection.inviewstartday = scope.selection.curstartday;
			        				scope.selection.inviewduration = scope.selection.curduration;
			        				scope.selection.startx = scope.selection.curx;
				        			scope.selection.boundLimit = undefined;
		        				}
		        			}
		        			if( scope.selection.boundLimit ) {
		        				var intervalFunction = $interval(function() {
			        				var isActive = scope.selection.type && scope.selection.boundLimit;
			        				if( !isActive ) {
										scope.options.avoidRefresh=false;
				        				$interval.cancel(intervalFunction);
										return;
			        				}
			        				if( scope.options.startday == 1 && scope.selection.boundLimit < 0 ) {
			        					return;
			        				}
			        				var day = 0;
			        				var duration = 0;
	
					        		scope.options.startday=Math.max(1, scope.options.startday + scope.selection.boundLimit);
				        			scope.selection.curstartday=Math.max(1, scope.selection.curstartday + scope.selection.boundLimit);
									day = scope.selection.curstartday;
									duration = scope.selection.curduration;
	
			        				scope.updateCanvas();
									scope.currentImageData = ctx.getImageData(0,0, scope.canvas.width, scope.canvas.height);
				        			var selectedWork = scope.selection.work;
									var fromX = (-scope.options.startday)*scope.options.colwidth+(scope.options.zoneswidth+scope.options.workswidth);
									var startX = fromX+day*scope.options.colwidth;
									var endX = startX+duration*scope.options.colwidth;
									var startY = scope.selection.starty;
									ctx.save();
									ctx.fillStyle = 'rgba(80,80,80,1.0)';
									ctx.strokeStyle='rgba(255,0,0,0.8)';
									ctx.font = 'normal 12px arial';
									zoneHovered = -1;
									for( var i=0;i<scope.zonesizes.length;i++ ) {
										if( coords.y>scope.zonesizes[i].calendarY && coords.y<scope.zonesizes[i].worksY) {
											zoneHovered=i;
											break;
										}
									}
									if( scope.options.colwidth<40 ) {
										ctx.fillText(day+'', startX+2, scope.zonesizes[zoneHovered].calendarY-scope.options.rowheight*0.5-6);
										ctx.beginPath();
										ctx.moveTo(startX, scope.zonesizes[zoneHovered].calendarY-scope.options.rowheight);
										ctx.lineTo(startX, scope.zonesizes[zoneHovered].worksY);
										ctx.stroke();
									}
									ctx.beginPath();
									ctx.rect((scope.options.zoneswidth+scope.options.workswidth),scope.zonesizes[zoneHovered].calendarY, scope.canvas.width-(scope.options.zoneswidth+scope.options.workswidth), scope.zonesizes[zoneHovered].worksY-scope.zonesizes[zoneHovered].calendarY);
									ctx.clip();
									switch(scope.options.color) {
										case 'work':
											ctx.fillStyle = (selectedWork.color ? 'rgba('+selectedWork.color+',1.0)' : 'rgba(241, 194, 50, 0.8)');
											break;
										default:
											ctx.fillStyle = (scope.selection.zone.color ? 'rgba('+scope.selection.zone.color+',1.0)' : 'rgba(241, 194, 50, 0.8)');
											break;
									}
									ctx.lineWidth = 3;
									scope.drawTotalAndSingletimeranges(ctx, fromX, startY, day, (day+duration), scope.selection.work);
				        			ctx.restore();
			        			},100);
			        			return;
		        			}
		        			scope.options.avoidRefresh=true;
							ctx.putImageData(scope.currentImageData, 0,0);
		        			var deltaday = Math.floor((coords.x-scope.selection.startx)/scope.options.colwidth);
		        			var duration = 0;
		        			var day=0;
							day = Math.max(1, scope.selection.inviewstartday+deltaday);
							duration = scope.selection.inviewduration;
		        			scope.selection.curduration=duration;
		        			scope.selection.curstartday=day;
		        			scope.selection.curx = coords.x;
		        			if( day<scope.options.startday ) {
			        			scope.options.startday = day;
		        				scope.updateCanvas();
								scope.currentImageData = ctx.getImageData(0,0, scope.canvas.width, scope.canvas.height);
		        			}
							var fromX = (-scope.options.startday)*scope.options.colwidth+(scope.options.zoneswidth+scope.options.workswidth);
							var startX = fromX+day*scope.options.colwidth;
							var endX = startX+duration*scope.options.colwidth;
							var startY = scope.selection.starty;
	
							ctx.save();
							ctx.fillStyle = 'rgba(80,80,80,1.0)';
							ctx.strokeStyle='rgba(255,0,0,0.8)';
							ctx.font = 'normal 12px arial';
							zoneHovered = -1;
							for( var i=0;i<scope.zonesizes.length;i++ ) {
								if( coords.y>scope.zonesizes[i].calendarY && coords.y<scope.zonesizes[i].worksY) {
									zoneHovered=i;
									break;
								}
							}
							if( scope.options.colwidth<40 ) {
								ctx.fillText(day+'', startX+2, scope.zonesizes[zoneHovered].calendarY-scope.options.rowheight*0.5-6);
								ctx.beginPath();
								ctx.moveTo(startX, scope.zonesizes[zoneHovered].calendarY-scope.options.rowheight);
								ctx.lineTo(startX, scope.zonesizes[zoneHovered].worksY);
								ctx.stroke();
							}
							ctx.beginPath();
							ctx.rect((scope.options.zoneswidth+scope.options.workswidth),scope.zonesizes[zoneHovered].calendarY, scope.canvas.width-(scope.options.zoneswidth+scope.options.workswidth), scope.zonesizes[zoneHovered].worksY-scope.zonesizes[zoneHovered].calendarY);
							ctx.clip();
							switch(scope.options.color) {
								case 'work':
									ctx.fillStyle = (selectedWork.color ? 'rgba('+selectedWork.color+',1.0)' : 'rgba(241, 194, 50, 0.8)');
									break;
								default:
									ctx.fillStyle = (scope.selection.zone.color ? 'rgba('+scope.selection.zone.color+',1.0)' : 'rgba(241, 194, 50, 0.8)');
									break;
							}
							ctx.lineWidth = 3;
							scope.drawTotalAndSingletimeranges(ctx, fromX, startY, day, (day+duration), scope.selection.work);
							var indexOfWork = scope.selection.zone.works.indexOf(selectedWork);
							
							/*
							var curIndex = 0;
							scope.selection.zone.works.forEach(function(c) {
								if( c != selectedWork ) {
									if( c.startday < (day+duration) && (c.startday+c.duration)>day ) {//conflict
										ctx.save();
										ctx.lineWidth=4;
										ctx.fillStyle='rgba(255,0,0,0.4)';
										var fromDay = Math.max(day,c.startday)-scope.options.startday;
										var range = Math.min(day+duration, c.startday+c.duration)-fromDay-1;
										var zoneY = startY - indexOfWork*scope.options.rowheight;
										ctx.fillRect(fromDay*scope.options.colwidth+(scope.options.zoneswidth+scope.options.workswidth), zoneY+Math.min(indexOfWork, curIndex)*scope.options.rowheight, range*scope.options.colwidth, (Math.abs(curIndex-indexOfWork)+1)*scope.options.rowheight);
										ctx.restore();
										
									}
								}
								curIndex++;
							});
							*/
		        			ctx.restore();
							return;
			        	} 
			        	if( scope.selection.type=='move' ) {
		        			scope.options.avoidRefresh=true;
		        			
		        			var deltaday = Math.floor((coords.x-scope.selection.startx)/scope.options.colwidth);
							scope.options.startday = Math.max(1, scope.selection.startday+deltaday);
	        				scope.updateCanvas();
							scope.currentImageData = ctx.getImageData(0,0, scope.canvas.width, scope.canvas.height);
				        	return;
			        	}
	      		}
				ctx.putImageData(scope.currentImageData, 0,0);
				
				zoneHovered=-1;
				for( var i=0;i<scope.zonesizes.length;i++ ) {
					if( coords.y >= scope.zonesizes[i].startY && coords.y <= scope.zonesizes[i].startY+scope.options.zonetitleheight ) {
						zoneHovered = i;
						break;
					}
				}
				// Highlight zone
				if( zoneHovered>=0 ) { 
					ctx.save();
					ctx.beginPath();
					ctx.fillStyle='rgba(120,120,120,0.2)';
					ctx.rect(0, scope.zonesizes[zoneHovered].startY, scope.canvas.width, scope.zonesizes[zoneHovered].worksY-scope.zonesizes[zoneHovered].startY);
					ctx.fill();
					ctx.closePath();
					ctx.restore();
					return;
				} 
				if( coords.x>=scope.canvas.width ) {
		        		elem.css('cursor','pointer');
					return;
		        }
		      
				var timerangeStartX = scope.options.zoneswidth+scope.options.workswidth;        		
	        		// Highlight (or at least try) interferences
	        		if( coords.x>timerangeStartX ) {
		        		var found=false;
					var startX = (-scope.options.startday)*scope.options.colwidth+(scope.options.zoneswidth+scope.options.workswidth);
					var zoneHeight;
					for( var i=0;i<scope.zones.length; i++ ) {
	        				var curZone = scope.zones[i];
						if( !curZone.interferences.length ) { continue;}
						zoneHeight = (!removeHiddenZones ? scope.totalRowsCount[scope.zones[i]._id] : scope.visibleRowsCount[scope.zones[i]._id] )*scope.options.rowheight;
						if( coords.y<scope.zonesizes[i].calendarY || coords.y>scope.zonesizes[i].worksY ) { continue; }
						for( var j=0;j<curZone.interferences.length;j++ ) {
							var interference = curZone.interferences[j];
							if( (coords.x>=(startX+interference.day*scope.options.colwidth) && coords.x<=(startX+(interference.day)*scope.options.colwidth)+2) || (coords.x>=(startX+(interference.day+interference.duration)*scope.options.colwidth)-2 && coords.x<=(startX+(interference.day+interference.duration)*scope.options.colwidth)) ) {
								if( scope.options.showinterferences == 2 || (scope.options.showinterferences == 1 && interference.solved==false) ) {
									ctx.strokeStyle = interference.solved ? 'rgba(102,204,0,0.9)' : 'rgba(204,204,0,0.9)';
									ctx.fillStyle = interference.solved ? 'rgba(153,255,51,0.9)' : 'rgba(255,255,102,0.9)';
									ctx.fillRect(startX+interference.day*scope.options.colwidth, scope.zonesizes[i].calendarY, interference.duration*scope.options.colwidth,zoneHeight); 
									ctx.strokeRect(startX+interference.day*scope.options.colwidth+1, scope.zonesizes[i].calendarY+1, interference.duration*scope.options.colwidth-2,zoneHeight-2); 
									
				        				popover.css({top:scope.zonesizes[i].calendarY, left:(startX+(interference.day+interference.duration)*scope.options.colwidth)});
									var startdate = new Date(scope.startdate.getTime()+(interference.day-1)*86400000);
			        					startdate.setHours(12);
									var html = "<b>"+$filter('date')(startdate, 'd MMMM');
									var enddate = new Date(scope.startdate.getTime()+(interference.day-1+interference.duration-1)*86400000);
			        					html += (interference.duration>1?("-"+$filter('date')(enddate, 'd MMMM')):"");
			        				
									html += "</b><br>Interferenza "+(interference.solved?"":"non ")+"risolta";
				        				if( interference.notes.length ) {
				        					html += "<div class='text-muted'>"+interference.notes+"</div>";
				        				}
				        				if( interference.solved == false ) {
					        				html += "<br><i>Clicca per modificarla.</i>";
				        				}
				        				popover.data("bs.popover").options.content=html;
					        			popover.popover('show');
					        			scope.popoverisshown = true;
									found=true;
									elem.css('cursor','grabbing');
									break;
								}
							}
						}
					}
		        		if( found ) { return; }
	        		}
        		
        			// Highlight works, subworks and timeranges
	        		for( var i=0;i<scope.zones.length; i++ ) {
	        			if( !scope.zones[i].visible[hash] ) { continue;}
	        			var curZone = scope.zones[i];
	        			var visibleRowsCount = (!removeHiddenZones ?  scope.totalRowsCount[scope.zones[i]._id] : scope.visibleRowsCount[scope.zones[i]._id] );
	        			if( !visibleRowsCount ) { continue;}
		        		if( coords.y>=scope.zonesizes[i].worksY || coords.y<=scope.zonesizes[i].calendarY) {
		        			continue;
			        	}
	        			var rowIndex = Math.floor((coords.y-scope.zonesizes[i].calendarY)/scope.options.rowheight);
	        			var idx = 0;
	        			for( var j=0, c;j<curZone.works.length;j++) {
		        			c = curZone.works[j];
		        			if( removeHiddenZones && !c.visible[hash] ) { continue; }
		        			if( idx==rowIndex ) { // work line
			        			if( coords.x < timerangeStartX ) { // work highlight
				        			var cnt = 1 +  (!removeHiddenZones ?  c.subworks.length : c.subworks.filter(function(d) { return (d.visible); }).length );
									//elem.css('cursor','row-resize');
				        			ctx.save();
				        			ctx.beginPath();
				        			ctx.fillStyle='rgba(120,120,120,0.3)';
				        			ctx.rect(scope.options.zoneswidth, scope.zonesizes[i].calendarY + rowIndex*scope.options.rowheight, scope.canvas.width, scope.options.rowheight*cnt);
				        			ctx.fill();
				        			ctx.closePath();
				        			ctx.restore();
							} else { // timerange highlight
					        		var day = Math.floor((coords.x-(scope.options.zoneswidth+scope.options.workswidth))/scope.options.colwidth)+scope.options.startday;
								if( c.subworks.length ) {
							        var startday = 99999;
							        var endday = -99999;
							        var effectiveDuration = 0;
							        var totalMenAtwork = 0;
							        c.subworks.forEach(function(e) {
								        e.timeranges.forEach(function(f) {
									       startday = Math.min(f.startDay, startday); 
									       endday = Math.max(f.startDay+f.duration, endday); 
									       effectiveDuration += f.duration;
									       totalMenAtwork += (f.duration*e.menatwork);
								        });
							        });
							        if( day<startday || day>endday) {
								        return;
							        }
									var fromX = (-scope.options.startday)*scope.options.colwidth+(scope.options.zoneswidth+scope.options.workswidth);
									var startX = fromX+startday*scope.options.colwidth;
									var endX = fromX+endday*scope.options.colwidth;
									var startY = scope.zonesizes[i].calendarY+rowIndex*scope.options.rowheight;
				        			popover.css({top:startY, left:Math.max((scope.options.zoneswidth+scope.options.workswidth), (startX+endX)*0.5)});
				        			var dur = endday-startday;
							        effectiveDuration = Math.min(effectiveDuration, dur);
				        			var html = "<b>"+c.name+"</b><br>"+dur+(dur==1?" giorno": " giorni");
				        			if( dur != effectiveDuration ) { html += " di cui "+effectiveDuration+" effettivi";}
				        			html += "<br><i>"+totalMenAtwork+" uomini giorno</i>";
			        				if( scope.options.showcalendar ) {
				        				var startdate = new Date(scope.startdate.getTime()+(startday-1)*86400000);
				        				startdate.setHours(12);
				        				var enddate = new Date(scope.startdate.getTime()+(endday-1)*86400000);	
				        				html += "<br><i>"+$filter('date')(startdate, 'd MMM');
				        				if( dur > 1 ) {
					        				html += ("-"+$filter('date')(enddate, 'd MMM'));
				        				}
				        				html += "</i>";
			        				}
			        				popover.data("bs.popover").options.content=html;
				        			popover.popover('show');
				        			scope.popoverisshown = true;
								} else {
					        			for( var z=0;z<c.timeranges.length;z++ ) {
						        			var curTimerange = c.timeranges[z];
						        			if( day >= curTimerange.startDay && day < (curTimerange.startDay+curTimerange.duration) ) {
												var fromX = (-scope.options.startday)*scope.options.colwidth+(scope.options.zoneswidth+scope.options.workswidth);
												var startX = fromX+curTimerange.startDay*scope.options.colwidth;
												var endX = startX+curTimerange.duration*scope.options.colwidth;
												var startY = scope.zonesizes[i].calendarY+rowIndex*scope.options.rowheight;
				
												ctx.save();
												ctx.beginPath();
												ctx.rect((scope.options.zoneswidth+scope.options.workswidth), scope.zonesizes[i].calendarY , scope.canvas.width-(scope.options.zoneswidth+scope.options.workswidth), scope.zonesizes[i].worksY - scope.zonesizes[i].calendarY);
												ctx.clip();
												switch(scope.options.color) {
													case 'work':
														ctx.fillStyle = (c.color ? 'rgba('+c.color+',1.0)' : 'rgba(241, 194, 50, 0.8)');
														break;
													default:
														ctx.fillStyle = (scope.zones[i].color ? 'rgba('+scope.zones[i].color+',1.0)' : 'rgba(241, 194, 50, 0.8)');
														break;
												}
												ctx.lineWidth = 3;
												scope.drawTimerange(ctx, fromX, startY, curTimerange);
												ctx.closePath();
							        			ctx.restore();
							        			if( (endX-coords.x)<5 ) {
													elem.css('cursor','e-resize');
							        			} else {
													elem.css('cursor','ew-resize');
							        			}
							        			popover.css({top:startY, left:Math.max((scope.options.zoneswidth+scope.options.workswidth), (startX+endX)*0.5)});
							        			var html = "";
						        				if( c.contractor && c.contractor.name ) {
								        			html += "<b>"+c.contractor.name+"</b><br>";
						        				}
						        				html += (curTimerange.duration+(curTimerange.duration==1?" giorno": " giorni"));
						        				if( scope.options.showcalendar ) {
						        					var startdate = new Date(scope.startdate.getTime()+(curTimerange.startDay-1)*86400000);
													startdate.setHours(12);
						        					var enddate = new Date(startdate.getTime()+(curTimerange.duration-1)*86400000);
							        				html += "<br><i>"+$filter('date')(startdate, 'd MMM');
							        				if( curTimerange.duration > 1 ) {
								        				html += ("-"+$filter('date')(enddate, 'd MMM'));
							        				}
													html += "</i>";
						        				}
						        				html += "<br>"+(curTimerange.duration*c.menatwork+" uomini giorno");
						        				popover.data("bs.popover").options.content=html;
							        			popover.popover('show');
							        			scope.popoverisshown = true;
				 				        		break;
						        			}
					        			}
								}
							}
							return;
		        			} else { 
			        			for( var z=0, d;z<c.subworks.length;z++) {
				        			d = c.subworks[z];
									if( removeHiddenZones && !d.visible[hash] ) { continue; }
				        			idx++;
				        			if( idx==rowIndex ) { // subwork line
										if( coords.x < timerangeStartX ) { // subwork highlight
											//elem.css('cursor','row-resize');
						        			ctx.save();
						        			ctx.beginPath();
						        			ctx.fillStyle='rgba(120,120,120,0.3)';
						        			ctx.rect(scope.options.zoneswidth, scope.zonesizes[i].calendarY + rowIndex*scope.options.rowheight, scope.canvas.width, scope.options.rowheight);
						        			ctx.fill();
						        			ctx.closePath();
						        			ctx.restore();
						        		} else { // timerange highlight
						        			var day = Math.floor((coords.x-(scope.options.zoneswidth+scope.options.workswidth))/scope.options.colwidth)+scope.options.startday;
						        			for( var z=0;z<d.timeranges.length;z++ ) {
							        			var curTimerange = d.timeranges[z];
							        			if( day >= curTimerange.startDay && day < (curTimerange.startDay+curTimerange.duration) ) {
													var fromX = (-scope.options.startday)*scope.options.colwidth+(scope.options.zoneswidth+scope.options.workswidth);
													var startX = fromX+curTimerange.startDay*scope.options.colwidth;
													var endX = startX+curTimerange.duration*scope.options.colwidth;
													var startY = scope.zonesizes[i].calendarY+rowIndex*scope.options.rowheight;
					
													ctx.save();
													ctx.beginPath();
													ctx.rect((scope.options.zoneswidth+scope.options.workswidth),scope.zonesizes[i].calendarY , scope.canvas.width-(scope.options.zoneswidth+scope.options.workswidth), scope.zonesizes[i].worksY-scope.zonesizes[i].calendarY);
													ctx.clip();
													switch(scope.options.color) {
														case 'work':
															ctx.fillStyle = (c.color ? 'rgba('+c.color+',1.0)' : 'rgba(241, 194, 50, 0.8)');
															break;
														default:
															ctx.fillStyle = (scope.zones[i].color ? 'rgba('+scope.zones[i].color+',1.0)' : 'rgba(241, 194, 50, 0.8)');
															break;
													}
													ctx.lineWidth = 3;
													scope.drawTimerange(ctx, fromX, startY, curTimerange);
													ctx.closePath();
								        			ctx.restore();
								        			if( (endX-coords.x)<5 ) {
														elem.css('cursor','e-resize');
								        			} else {
														elem.css('cursor','ew-resize');
								        			}
								        			popover.css({top:startY, left:Math.max((scope.options.zoneswidth+scope.options.workswidth), (startX+endX)*0.5)});
								        			var html = "";
							        				if( d.contractor && d.contractor.name ) {
									        			html += "<b>"+d.contractor.name+"</b><br>";
							        				}
							        				html += (curTimerange.duration+(curTimerange.duration==1?" giorno": " giorni"));
							        				if( scope.options.showcalendar ) {
							        					var startdate = new Date(scope.startdate.getTime()+(curTimerange.startDay-1)*86400000);
														startdate.setHours(12);
							        					var enddate = new Date(startdate.getTime()+(curTimerange.duration-1)*86400000);
								        				html += "<br><i>"+$filter('date')(startdate, 'd MMM');
								        				if( curTimerange.duration > 1 ) {
									        				html += ("-"+$filter('date')(enddate, 'd MMM'));
								        				}
														html += "</i>";
							        				}
							        				html += "<br>"+(curTimerange.duration*d.menatwork+" uomini giorno");
							        				popover.data("bs.popover").options.content=html;
								        			popover.popover('show');
								        			scope.popoverisshown = true;
					 				        		break;
							        			}
						        			}
						        		}
										/*j = curZone.works.length; // exit from works cycle
										i = scope.zones.length; // exit from zones cycle*/
										return;
				        			}
			        			}
		        			}
							idx++;
	        			}
		        		break;
	        		}
	        };
			/* end mouse events */
			
			scope.moveLeft = function() {
    			scope.options.avoidRefresh=true;
				var ctx = scope.canvas.getContext("2d");
				scope.options.startday = Math.max(1, scope.options.startday-50);
				scope.updateCanvas();
				scope.currentImageData = ctx.getImageData(0,0, scope.canvas.width, scope.canvas.height);
			}
			scope.moveRight = function() {
    			scope.options.avoidRefresh=true;
				var ctx = scope.canvas.getContext("2d");
				scope.options.startday = Math.max(1, scope.options.startday+50);
				scope.updateCanvas();
				scope.currentImageData = ctx.getImageData(0,0, scope.canvas.width, scope.canvas.height);
			}
			/* actions */
			scope.deleteZone = function() {
				if( scope.options.caneditzones == false ) { return; }
				if( scope.zones.length == 1 ) { return; }
				if( scope.selection.type!='zone' || !scope.selection.zone) { return; }
				if( confirm('Sei sicuro di voler cancellare questa zona? Non potrai tornare indietro una volta eliminata')) {
					var zoneIndex = scope.zones.indexOf(scope.selection.zone);
					if( zoneIndex>=0 ) {
						scope.zones.splice(zoneIndex,1);
					}
					scope.resetSelection();
					scope.updateCanvas();
				}				
			};
			
			scope.editZone = function(isNew) {
				if( scope.options.caneditzones == false ) { return; }
				var zone = (isNew ? null : scope.selection.zone);
				var modalInstance = $uibModal.open({
					templateUrl: 'zoneModal.html',
					controller: ['$scope', '$uibModalInstance', 'zone', function (scope, $uibModalInstance, zone ) {
						scope.isNew = (zone ? false : true);

						scope.data = {};
						scope.data.name = zone ? zone.name : 'Nuova zona';
						scope.data.description = zone ? zone.description : '';
						scope.data.color = zone ? zone.color : '100,179,223';
						scope.data.image = zone ? zone.image : null;
						scope.ok = function () {
							$uibModalInstance.close(scope.data);
						};
						
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
					}],
					resolve: {zone: function() { return zone;} }
				});
				modalInstance.result.then(
					function successCallback(data) {
						if( zone ) {
							zone.name = data.name;
							zone.description = data.description;
							zone.color = data.color;
							zone.image = data.image;
						} else {
							var newZone = new models.Zone(data.name, data.description, data.image, data.color, [new models.Work('Nuova macrofase', 1, [new models.Timerange(1,10)], {name:'Appalto da definire'}, '100,179,223', null, scope.visibility())], [],scope.visibility());
							scope.zones.push(newZone);
							scope.totalRowsCount[newZone._id]=true;
							scope.visibleRowsCount[newZone._id]=true;
						}
						scope.updateZonesWidth();
						scope.resetSelection();
						scope.updateCanvas();
					}, function errorCallback() {}
				);
			};
			
			scope.updateZonesWidth = function() {
				var ctx = scope.canvas.getContext("2d");
				scope.options.zoneswidth = 8;
				/*
				ctx.save();
				ctx.font='normal 18px arial';
				scope.zones.forEach(function(z) {
					scope.options.zoneswidth = Math.max(scope.options.zoneswidth, ctx.measureText(z.name).width+25);
				});
				ctx.restore();
				*/
			}
			
			scope.updateWorksWidth = function() {
				var ctx = scope.canvas.getContext("2d");
				scope.options.workswidth = 0;
				ctx.save();
				ctx.font='normal 15px arial';
				scope.zones.forEach(function(z) {
					if( z.works) {
						z.works.forEach(function(w) {
							scope.options.workswidth = Math.max(scope.options.workswidth, ctx.measureText(w.name).width+10);
						})
						if( w.subworks ) {
							w.subworks.forEach(function(s) {
								scope.options.workswidth = Math.max(scope.options.workswidth, ctx.measureText(s.name).width+10);
							})
						}
					}
				});
				scope.options.workswidth +=10;
				ctx.restore();
			}
			scope.copyZone = function() {
				if( scope.options.caneditzones == false ) { return; }
				if( scope.selection.type!='zone' || !scope.selection.zone) { return; }
				var newZone = angular.copy(scope.selection.zone);
				newZone._id = models.clientId();
				
				scope.zones.push(newZone);
				scope.totalRowsCount[newZone._id]=true;
				scope.visibleRowsCount[newZone._id]=true;
				scope.updateZoneRowsCount(newZone);
				scope.resetSelection();
				scope.updateZoneInterferences(newZone);
				scope.updateCanvas();
			};
			scope.rearrangeTimeranges = function() {
				if( scope.options.caneditzones == false ) { return; }
				var modalInstance = $uibModal.open({
					templateUrl: 'rearrangeModal.html',
					controller: ['$scope', '$uibModalInstance', function (scope, $uibModalInstance) {
						scope.options = {};
						scope.options.skipHolidays = true;				
						scope.ok = function () {
							$uibModalInstance.close(scope.options.skipHolidays);
						};
						
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
					}],
					resolve: {}
				});
			
				modalInstance.result.then(
					function successCallback(skipHolidays) {
						switch(scope.selection.type) {
							case 'zone':
								scope.rearrangeZoneTimeranges(scope.selection.zone, skipHolidays );
								scope.updateZoneInterferences(scope.selection.zone);
								break;
							case 'work':
								scope.rearrangeWorkTimeranges(scope.selection.work, skipHolidays );
								scope.updateZoneInterferences(scope.selection.zone);
								break;
							case 'subwork':
								scope.rearrangeSubworkTimeranges(scope.selection.subwork, skipHolidays );
								scope.updateZoneInterferences(scope.selection.zone);
								break;
							default:
								scope.rearrangeAll(skipHolidays);
								scope.updateInterferences();
								break;
						}
						
						scope.updateCanvas();
					}, function errorCallback() {}
				);
			}
			
			scope.deleteWork = function() {
				if( scope.selection.type!='work' || !scope.selection.work || !scope.selection.zone) { return; }
				if( confirm('Sei sicuro di voler cancellare questa macrofase? Non potrai tornare indietro una volta eliminata')) {
					var zone = scope.selection.zone;
					var work = scope.selection.work;
					var workIndex = zone.works.indexOf(work);
					if( workIndex>=0 ) {
						/*if( zone.works.length == 1 ) {
							alert("Non puoi cancellare questa macrofase. Ogni zona deve averne almeno una.")
						} else */{
							zone.works.splice(workIndex,1);
						}
					}
					scope.updateZoneRowsCount(zone);
					scope.updateZoneInterferences(zone);
					scope.resetSelection();
					scope.updateCanvas();
				}				
			};
			scope.copyWork = function() {
				if( scope.selection.type!='work' || !scope.selection.work || !scope.selection.zone) { return; }
				var zone = scope.selection.zone;
				var work = scope.selection.work;
				var workIndex = zone.works.indexOf(work);
				if( workIndex>=0 ) {
					var newWork = angular.copy(work);
					newWork._id = models.clientId();
					zone.works.push(newWork);
					scope.updateZoneRowsCount(zone);
					scope.updateZoneInterferences(zone);
					scope.resetSelection();
					scope.updateCanvas();
				}
			};
			scope.addNewWork = function() {
				scope.editWork(true);
			};
			scope.editWork = function(addNew) {
				var work = (addNew ? null : scope.selection.work);
				var contractors = angular.copy(scope.contractors());
				var selectedZone = scope.selection.zone ? scope.selection.zone : scope.zones[0];
				if( !contractors ) { contractors = [];}
				if( work ) {
					if( work.contractor && work.contractor.name ) {
						/*if( !(contractors.filter(function(c) {
							return c.fullname == work.contractor.name;
						}).length) ) {
							contractors.push({fullname:work.contractor.name});
						}*/
					}
				}
				var startday = 1;
				if( selectedZone.works.length>0 ) { 
					var prevWork = selectedZone.works[selectedZone.works.length-1];
					var lastTimerange;
					if( prevWork.subworks.length ) {
						var lastSubwork = prevWork.subworks[prevWork.subworks.length-1];
						lastTimerange = lastSubwork.timeranges[lastSubwork.timeranges.length-1];
					} else {
						lastTimerange = prevWork.timeranges[prevWork.timeranges.length-1];
					}
					startday = lastTimerange.startDay+lastTimerange.duration;
				}
				
				var modalInstance = $uibModal.open({
					templateUrl: 'workModal.html',
					controller: ['$scope', '$uibModalInstance', 'work','contractors', 'zones', 'selectedZone','startday', 'startdate', function (scope, $uibModalInstance, work, contractors, zones, selectedZone, startday, startdate) {
						scope.startdate = startdate;
						scope.data = {};
						scope.data.zones = zones;
						scope.contractors = contractors;
						scope.wks = worksheetService;
						scope.isNew = (work ? false : true);
						scope.hasNoSubworksAnd1Timerange = (!(work && work.subworks && work.subworks.length) && work && work.timeranges && work.timeranges.length==1) || !work;
						scope.type = "work";
						if( scope.hasNoSubworksAnd1Timerange && startdate ) {
							if( work && work.timeranges && work.timeranges.length && work.timeranges[0].startDay ) {
								var newdate = new Date(startdate.valueOf()+864E5*(work.timeranges[0].startDay-1));
								scope.data.startdate = newdate;
							} else {
								var newdate = new Date(startdate.valueOf()+864E5*(startday-1));
								scope.data.startdate = newdate;
							}
							scope.$watch('data.startdate', function(newval) {
								if( newval === undefined ) { return; }
								var tm1 = scope.data.startdate.getTime();
								var tm2 = startdate.getTime();
								var diff_days = Math.round((tm1-tm2)/(864E5));
								if( diff_days < 0 ) {
									scope.data.startdate = new Date(startdate);
									scope.data.startday = 1;
								} else {
									scope.data.startday = diff_days+1;
								}
							});
							scope.$watch('data.startday', function(newval) {
								if( newval === undefined ) { return; }
								scope.data.startdate = new Date(startdate.valueOf()+864E5*(scope.data.startday-1));
							});
						}
						scope.data.zone = selectedZone;
						scope.data.workcategory = (work && work.worksheet && work.worksheet.catid ? {_id:work.worksheet.catid} : null);
						scope.data.work = (work && work.worksheet && work.worksheet.workid ? {_id:work.worksheet.workid} : null);
						scope.data.worksheet = (work && work.worksheet && work.worksheet.id ? {_id:work.worksheet.id} : null);
						scope.data.worksheetdata = (work && work.worksheet && work.worksheet.data ? work.worksheet.data : null);
						scope.data.name = work ? work.name : 'Nuova macrofase';
						scope.data.color = work ? work.color : '100,179,223';
						scope.data.menatwork = work ? work.menatwork : 1;
						scope.data.duration = (work && work.timeranges && work.timeranges.length==1) ? work.timeranges[0].duration : 1;
						scope.data.startday = (work && work.timeranges && work.timeranges.length==1) ? work.timeranges[0].startDay : startday;
						scope.data.contractor = (work && work.contractor && work.contractor.name && work.contractor.name.length) ?  work.contractor.name : 'Appalto da definire';
						scope.ok = function () {
							$uibModalInstance.close(scope.data);
						};
						
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.data.isSettingUp = true;
						worksheetService.getWorkscategories(scope.data);
					}],
					resolve: {work: function() { return work;}, contractors: function() { return contractors;}, zones: function() { return scope.zones;}, selectedZone: function() { return selectedZone; }, startday: function() { return startday; }, startdate: function() { return (scope.specifystartdate && scope.values.startdate)?scope.values.startdate:null;} }
				});
				modalInstance.result.then(
					function successCallback(data) {
						var originalSelectedZone = selectedZone;
						for( var i=0;i<scope.zones.length;i++ ) {
							if( data.zone._id === scope.zones[i]._id ) {
								selectedZone = scope.zones[i];
								break;
							}
						}
						if( !selectedZone.works ) { selectedZone.works=[];}
						if( work ) {
							work.name = data.name;
							work.color = data.color;
							work.menatwork = data.menatwork;
							if( work.timeranges && work.timeranges.length==1 ) {
								work.timeranges[0].duration = data.duration;
								work.timeranges[0].startDay = data.startday;
							}
							work.contractor = { name: data.contractor };
							// Check if data was already present. In this case don't change it!
							var worksheetdata = data.worksheetdata;
							if( data.workcategory && data.workcategory._id && data.work && data.work._id && data.worksheet && data.worksheet._id ) {
								worksheetdata = ((work.worksheet.catid === data.workcategory._id) && (work.worksheet.workid === data.work._id) && (work.worksheet.id === data.worksheet._id))?data.worksheetdata:((data.worksheet && data.worksheet.data)?data.worksheet.data:null);
							}
							if( worksheetdata !== null ) {
								if( worksheetdata.workname === undefined ) {
									worksheetdata.workname = work.name;
								}
								delete(worksheetdata._id);
								delete(worksheetdata.work);
							}
							work.worksheet = (data.workcategory && data.workcategory._id && data.work && data.work._id && data.worksheet && data.worksheet._id) ? { catid: data.workcategory._id, workid:data.work._id, id: data.worksheet._id, data: worksheetdata} : {catid:null, workid:null, id:null, data:worksheetdata};
							if( selectedZone !== originalSelectedZone ) {
								selectedZone.works.push(work);
								originalSelectedZone.works.splice(originalSelectedZone.works.indexOf(work),1);
								scope.updateZoneRowsCount(originalSelectedZone);
							} 
						} else {
							/*
							var startday = 1;
							if( selectedZone.works.length>0 ) { 
								var prevWork = selectedZone.works[selectedZone.works.length-1];
								var lastTimerange;
								if( prevWork.subworks.length ) {
									var lastSubwork = prevWork.subworks[prevWork.subworks.length-1];
									lastTimerange = lastSubwork.timeranges[lastSubwork.timeranges.length-1];
								} else {
									lastTimerange = prevWork.timeranges[prevWork.timeranges.length-1];
								}
								startday = lastTimerange.startDay+lastTimerange.duration;
							}
							*/
							var worksheetdata = (data.worksheet && data.worksheet.data) ? data.worksheet.data : null;
							if( worksheetdata !== null ) {
								worksheetdata.workname = data.name;
								delete(worksheetdata._id);
								delete(worksheetdata.work);
							}
	
							var worksheet = (data.workcategory && data.workcategory._id && data.work && data.work._id && data.worksheet && data.worksheet._id) ? { catid: data.workcategory._id, workid:data.work._id, id: data.worksheet._id, data: worksheetdata} : {catid:null, workid:null, id:null, data:null};
							var newWork = new models.Work(data.name, 1, [new models.Timerange(data.startday,data.duration)], {name:data.contractor}, data.color, worksheet, scope.visibility());
							selectedZone.works.push(newWork);
						}
						scope.updateZoneRowsCount(selectedZone);
						scope.updateZoneInterferences(selectedZone);
						scope.resetSelection();
						scope.updateCanvas();
					}, function errorCallback() {}
				);
			};
			scope.editSubwork = function() {
				if( !scope.selection.work ) { return; }
				var subwork = scope.selection.subwork;
				var contractors = angular.copy(scope.contractors());
				if( !contractors ) { contractors = [];}
				if( subwork ) {
					if( subwork.contractor && subwork.contractor.name ) {
						/*if( !(contractors.filter(function(c) {
							return c.fullname == subwork.contractor.name;
						}).length) ) {
							contractors.push({fullname:subwork.contractor.name});
						}*/
					}
				}
				var work = scope.selection.work;
				var startday = (work.subworks.length ? work.subworks[0].timeranges[0].startDay : work.timeranges[0].startDay);
				if( work.subworks.length>0 ) { 
					var prevSubwork = work.subworks[work.subworks.length-1];
					startday = prevSubwork.timeranges[0].startDay+prevSubwork.timeranges[0].duration;
				}
				
				var modalInstance = $uibModal.open({
					templateUrl: 'workModal.html',
					controller: ['$scope', '$uibModalInstance', 'subwork','contractors','startday', 'startdate', function (scope, $uibModalInstance, subwork, contractors,startday,startdate ) {
						scope.contractors = contractors;
						scope.wks = worksheetService;
						scope.type = "subwork";
						scope.isNew = (subwork ? false : true);
						scope.hasNoSubworksAnd1Timerange = (scope.isNew || (subwork && subwork.timeranges && subwork.timeranges.length==1));
						scope.data = {};
						if( scope.hasNoSubworksAnd1Timerange && startdate ) {
							if( subwork && subwork.timeranges && subwork.timeranges.length && subwork.timeranges[0].startDay ) {
								var newdate = new Date(startdate.valueOf()+864E5*(subwork.timeranges[0].startDay-1));
								scope.data.startdate = newdate;
							} else {
								var newdate = new Date(startdate.valueOf()+864E5*(startday-1));
								scope.data.startdate = newdate;
							}
							scope.$watch('data.startdate', function(newval) {
								if( newval === undefined ) { return; }
								var tm1 = scope.data.startdate.getTime();
								var tm2 = startdate.getTime();
								var diff_days = Math.round((tm1-tm2)/(864E5));
								if( diff_days < 0 ) {
									scope.data.startdate = new Date(startdate);
									scope.data.startday = 1;
								} else {
									scope.data.startday = diff_days+1;
								}
							});
							scope.$watch('data.startday', function(newval) {
								if( newval === undefined ) { return; }
								scope.data.startdate = new Date(startdate.valueOf()+864E5*(scope.data.startday-1));
							});
						}
						
						scope.data.name = subwork ? subwork.name : 'Nuova fase';
						scope.data.menatwork = subwork ? subwork.menatwork : 1;
						scope.data.workcategory = (subwork && subwork.worksheet && subwork.worksheet.catid ? {_id: subwork.worksheet.catid } : null);
						scope.data.work = (subwork && subwork.worksheet && subwork.worksheet.workid ? {_id: subwork.worksheet.workid } : null);
						scope.data.worksheet = (subwork && subwork.worksheet && subwork.worksheet.id ? {_id: subwork.worksheet.id } : null);
						scope.data.worksheetdata = (subwork && subwork.worksheet && subwork.worksheet.data ? subwork.worksheet.data : null);
						scope.data.duration = (subwork && subwork.timeranges && subwork.timeranges.length==1) ? subwork.timeranges[0].duration : 1;
						scope.data.startday = (subwork && subwork.timeranges && subwork.timeranges.length==1) ? subwork.timeranges[0].startDay : 1;
						scope.data.contractor = (subwork && subwork.contractor && subwork.contractor.name) ?  subwork.contractor.name : 'Appalto da definire';
						scope.ok = function () {
							$uibModalInstance.close(scope.data);
						};
						
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.data.isSettingUp = true;
						worksheetService.getWorkscategories(scope.data);
					}],
					resolve: {subwork: function() { return subwork;}, contractors: function() { return contractors;}, startday: function() { return startday; }, startdate: function() { return (scope.specifystartdate && scope.values.startdate)?scope.values.startdate:null;} }
				});
				modalInstance.result.then(
					function successCallback(data) {
						var zone = scope.selection.zone;
						var work = scope.selection.work;
						if( !work.subworks ) { work.subworks=[];}
						if( subwork ) {
							subwork.name = data.name;
							subwork.menatwork = data.menatwork;
							subwork.contractor = { name: data.contractor };
							if( subwork.timeranges && subwork.timeranges.length == 1 ) {
								subwork.timeranges[0].duration = data.duration;
								subwork.timeranges[0].startDay = data.startday;
							}
							// Check if data was already present. In this case don't change it!
							var worksheetdata = data.worksheetdata;
							if( data.workcategory && data.workcategory._id && data.work && data.work._id && data.worksheet && data.worksheet._id ) {
								worksheetdata = ((subwork.worksheet.catid === data.workcategory._id) && (subwork.worksheet.workid === data.work._id) && (subwork.worksheet.id === data.worksheet._id))?data.worksheetdata:((data.worksheet && data.worksheet.data)?data.worksheet.data:null);
							}
							if( worksheetdata !== null ) {
								if( worksheetdata.workname === undefined ) {
									worksheetdata.workname = subwork.name;
								}
								delete(worksheetdata._id);
								delete(worksheetdata.work);
							}
							subwork.worksheet = (data.workcategory && data.workcategory._id && data.work && data.work._id && data.worksheet && data.worksheet._id) ? { catid: data.workcategory._id, workid:data.work._id, id: data.worksheet._id, data: data.worksheet.data} : {catid:null, workid:null, id:null, data: data.worksheetdata };
						} else {
							/*
							var startday = (work.subworks.length ? work.subworks[0].timeranges[0].startDay : work.timeranges[0].startDay);
							if( work.subworks.length>0 ) { 
								var prevSubwork = work.subworks[work.subworks.length-1];
								startday = prevSubwork.timeranges[0].startDay+prevSubwork.timeranges[0].duration;
							}
							*/
							var worksheetdata = (data.worksheet && data.worksheet.data) ? data.worksheet.data : null;
							if( worksheetdata !== null ) {
								worksheetdata.workname = data.name;
								delete(worksheetdata._id);
								delete(worksheetdata.work);
							}
							
							var worksheet = (data.workcategory && data.workcategory._id && data.work && data.work._id && data.worksheet && data.worksheet._id) ? { catid: data.workcategory._id, workid:data.work._id, id: data.worksheet._id, data: worksheetdata} : {catid:null, workid:null, id:null, data: null };
							var newSubwork = new models.Subwork(data.name, 1, [new models.Timerange(data.startday, data.duration)], {name:data.contractor}, worksheet, scope.visibility());
							work.subworks.push(newSubwork);
						}
						scope.updateZoneRowsCount(zone);
						scope.updateZoneInterferences(zone);
						scope.resetSelection();
						scope.updateCanvas();
					}, function errorCallback() {}
				);
			};
			scope.deleteSubwork = function() {
				if( scope.selection.type!='subwork' || !scope.selection.subwork || !scope.selection.work || !scope.selection.zone) { return; }
				if( confirm('Sei sicuro di voler cancellare questa fase? Non potrai tornare indietro una volta eliminata')) {
					var zone = scope.selection.zone;
					var work = scope.selection.work;
					var subwork = scope.selection.subwork;
					var subworkIndex = work.subworks.indexOf(subwork);
					if( subworkIndex>=0 ) {
						work.subworks.splice(subworkIndex,1);
					}
					scope.updateZoneRowsCount(zone);
					scope.updateZoneInterferences(zone);
					scope.resetSelection();
					scope.updateCanvas();
				}				
			};
			scope.rearrangeAll = function(skipHolidays) {
				if( scope.options.caneditzones == false ) { return; }
				var startday = 1;
				scope.zones.forEach(function(z) {
					startday = scope.rearrangeZoneTimeranges(z, skipHolidays, startday);
				});
			};
			scope.rearrangeZoneTimeranges = function(zone, skipHolidays, fromday ) {
				if( scope.options.caneditzones == false ) { return; }
				if( scope.options.canaddtimeranges == false ) { return; }
				if( zone.works === undefined || !zone.works.length ) { return 0; }
				if( zone.works[0].timeranges === undefined && (zone.works[0].subworks === undefined || !zone.works[0].subworks.length )) { return 0; }
				var startday;
				if( zone.works[0].subworks.length ) {
					startday = zone.works[0].subworks[0].timeranges[0].startDay;
				} else {
					startday = zone.works[0].timeranges[0].startDay;
				}
				zone.works.forEach(function(w) {
					startday = scope.rearrangeWorkTimeranges(w, skipHolidays, startday);
				});
				return startday;
			};
			scope.rearrangeWorkTimeranges = function(work, skipHolidays, fromday ) {
				if( scope.options.canaddtimeranges == false ) { return; }
				var startday = 0;
				if( work.subworks.length == 0 ) {
					startday = (fromday!==undefined?fromday:work.timeranges[0].startDay);
					startday = scope.rearrangeSubworkTimeranges(work, skipHolidays, startday);
				} else {
					startday = (fromday!==undefined?fromday:work.subworks[0].timeranges[0].startDay);
					work.subworks.forEach(function(sw) {
						startday = scope.rearrangeSubworkTimeranges(sw, skipHolidays, startday);
					});
				}
				return startday;
			};
			scope.rearrangeSubworkTimeranges = function(subwork, skipHolidays, fromday ) {
				if( scope.options.canaddtimeranges == false ) { return; }
				if( subwork.timeranges === undefined || !subwork.timeranges.length ) { return; }
				var totalDuration = 0;
				var startday = (fromday!==undefined?fromday:subwork.timeranges[0].startDay);
				var j=startday-1;
				var workdayslength = scope.workdays.length;
				
				var newTimeranges = [];
				subwork.timeranges.forEach(function(e) {
					totalDuration += e.duration;
				});
				if( !skipHolidays ) {
					subwork.timeranges = [new models.Timerange(startday, totalDuration)];
					return (startday+totalDuration); 
				}
				while(true) { // Skip nonworking days
					if( j>=workdayslength) { scope.updateWorkdays(false, workdayslength+100);}
					if( scope.workdays[j] ) { break; }
					j++; 
				}
				var newTimerange = false;
				var duration=0;
				var startday = j+1;
				for( var i=totalDuration; i>0; j++ ) {
					if( j>=workdayslength) { scope.updateWorkdays(false, workdayslength+100);}
					if( scope.workdays[j] ) { 
						duration++;
						i--;
					} else {
						if( duration ) {
							newTimeranges.push(new models.Timerange(startday, duration));
							duration=0;
						}
						startday=j+2;
					}
				}
				if( duration ) {
					newTimeranges.push(new models.Timerange(startday, duration));
				}
				subwork.timeranges = newTimeranges;
				var lastTimerange = subwork.timeranges[subwork.timeranges.length-1];
				return lastTimerange.startDay+lastTimerange.duration;
			};
			
			scope.manageZoneInterferences = function(zone, interference) {
				if( zone === undefined ) { return; }
				var modalInstance = $uibModal.open({
					templateUrl: 'zoneinterferencesModal.html',
					controller: ['$scope', '$uibModalInstance','startdate', 'zone','interference','canaddinterferencesnotestocosts',  function (scope, $uibModalInstance, startdate, zone, interference, canaddinterferencesnotestocosts) {
						scope.data = {};
						scope.data.interferences = [];
						scope.data.zone = zone.name;
						scope.data.pickedoption = (interference ? interference.pickedoption : 'solve');
						scope.data.notes = (interference ? interference.notes : '');
						scope.data.canaddinterferencesnotestocosts = canaddinterferencesnotestocosts;
						scope.data.addnotestocosts = (interference && interference.addnotestocosts!== undefined ? interference.addnotestocosts : false);;
						scope.addNotesFromArchive = function() {
							scope.newobjects = [];
							var modalInstance = $uibModal.open({
								templateUrl: assetsURL+'directives/catobjectseditor/templates/catobjectspickerModal.html?t='+RootSeed,
								controller: ['$scope', '$uibModalInstance','objects', function (scope, $uibModalInstance, objects ) {
									scope.searchtext="";
									scope.data = {	objects: [], section: 'general', type: 'interferencesnotes', objectclass: 'multidescriptionobjectitem' };
									
									scope.cancel = function () {
										$uibModalInstance.dismiss('cancel');
									};
									scope.ok = function() {
										$uibModalInstance.close(scope.data.objects);
									};
								}],
								
								resolve: { 
									objects: function () { return scope.newobjects; }
								}
							});
							modalInstance.result.then(
								function successCallback(objects) {
									if( objects === undefined ) { return; }
									objects.forEach(function(c) {
										if( c.selected === true ) {
											c.descriptions.forEach(function(d) {
												if( d.selected === true ) {
													scope.data.notes += (scope.data.notes.length ? '\n':'')+d.name;
												}
											});
										}
									});
								}, function errorCallback() {}
							);
						};
						if( interference ) {
							var newInt = angular.copy(interference);
							newInt.works = [];
							interference.works.forEach(function(w) {
								newInt.works.push(w);
							});
							scope.data.interferences.push(newInt);
						} else {
							zone.interferences.forEach(function(i) {
								var newInt = angular.copy(i);
								newInt.works = [];
								i.works.forEach(function(w) {
									newInt.works.push(w);
								});
								scope.data.interferences.push(newInt);
							});
						}
						scope.data.interferences.forEach(function(i) {
							i.startdate = new Date(startdate.getTime()+(i.day-1)*86400000);
							i.enddate = new Date(i.startdate.getTime()+(i.duration-1)*86400000);
						});
						scope.ok = function () {
							$uibModalInstance.close({option:scope.data.pickedoption, notes:scope.data.notes, addnotestocosts:scope.data.addnotestocosts});
						};
						
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
					}],
					resolve: { 
						startdate: function() { return scope.startdate; }, 
						zone: function() { return zone;}, 
						interference: function() { return interference;}, 
						canaddinterferencesnotestocosts : function() { return scope.options.canaddinterferencesnotestocosts; }
					}
				});
				modalInstance.result.then(
					function successCallback(data) {
						switch(data.option) {
							case "solve":
								if( interference ) { 
									interference.solved = true; 
									interference.notes = data.notes;
									interference.pickedoption = data.option;
									interference.addnotestocosts = data.addnotestocosts;
								} else {
									zone.interferences.forEach(function(e) { 
										e.solved = true;
										e.notes = data.notes;
										e.pickedoption = data.option;
										e.addnotestocosts = data.addnotestocosts;
									});
								}
								break;
							case "unsolve":
								if( interference ) { 
									interference.solved = false; 
									interference.notes = data.notes;
									interference.pickedoption = data.option;
									interference.addnotestocosts = data.addnotestocosts;
								} else {
									zone.interferences.forEach(function(e) { 
										e.solved = false;
										e.notes = data.notes;
										e.pickedoption = data.option;
										e.addnotestocosts = data.addnotestocosts;
									});
								}
								break;
							case "remind":
								if( interference ) { 
									interference.solved = false; 
									interference.notes = data.notes;
									interference.pickedoption = data.option;
									interference.addnotestocosts = data.addnotestocosts;
								} else {
									zone.interferences.forEach(function(e) { 
										e.solved = false;
										e.notes = data.notes;
										e.pickedoption = data.option;
										e.addnotestocosts = data.addnotestocosts;
									});
								}
								break;
							case "hidden":
								if( interference ) { 
									interference.solved = false; 
									interference.notes = data.notes;
									interference.pickedoption = data.option;
									interference.addnotestocosts = data.addnotestocosts;
								} else {
									zone.interferences.forEach(function(e) { 
										e.solved = false;
										e.notes = data.notes;
										e.pickedoption = data.option;
										e.addnotestocosts = data.addnotestocosts;
									});
								}
								break;
							default:
								break;
						}
						scope.updateCanvas();
					}, function errorCallback() {}
				);
			};
			scope.manageContractorsInterferences = function(interference) {
				var modalInstance = $uibModal.open({
					templateUrl: 'contractorsinterferencesModal.html',
					controller: ['$scope', '$uibModalInstance','startdate','interference','interferences', function (scope, $uibModalInstance, startdate, interference, interferences) {
						scope.data = {};
						scope.data.interferences = [];
						scope.data.pickedoption = (interference ? interference.pickedoption : 'solve');
						scope.data.notes = (interference ? interference.notes : '');
						scope.data.canaddinterferencesnotestocosts = canaddinterferencesnotestocosts;
						scope.data.addnotestocosts = (interference && interference.addnotestocosts!== undefined ? interference.addnotestocosts : false);;
						scope.addNotesFromArchive = function() {
							scope.newobjects = [];
							var modalInstance = $uibModal.open({
								templateUrl: assetsURL+'directives/catobjectseditor/templates/catobjectspickerModal.html?t='+RootSeed,
								controller: ['$scope', '$uibModalInstance','objects', function (scope, $uibModalInstance, objects ) {
									scope.searchtext="";
									scope.data = {	objects: [], section: 'general', type: 'interferencesnotes', objectclass: 'multidescriptionobjectitem' };
									
									scope.cancel = function () {
										$uibModalInstance.dismiss('cancel');
									};
									scope.ok = function() {
										$uibModalInstance.close(scope.data.objects);
									};
								}],
								
								resolve: { 
									objects: function () { return scope.newobjects; }
								}
							});
							modalInstance.result.then(
								function successCallback(objects) {
									if( objects === undefined ) { return; }
									objects.forEach(function(c) {
										if( c.selected === true ) {
											c.descriptions.forEach(function(d) {
												if( d.selected === true ) {
													scope.data.notes += (scope.data.notes.length ? '\n':'')+d.name;
												}
											});
										}
									});
								}, function errorCallback() {}
							);
						};
						if( interference ) {
							var newInt = angular.copy(interference);
							newInt.contractors = [];
							interference.contractors.forEach(function(w) {
								newInt.contractors.push(w);
							});
							scope.data.interferences.push(newInt);
						} else {
							interferences.forEach(function(i) {
								var newInt = angular.copy(i);
								newInt.contractors = [];
								i.contractors.forEach(function(w) {
									newInt.contractors.push(w);
								});
								scope.data.interferences.push(newInt);
							});
						}
						scope.data.interferences.forEach(function(i) {
							i.startdate = new Date(startdate.getTime()+(i.day-1)*86400000);
							i.enddate = new Date(i.startdate.getTime()+(i.duration-1)*86400000);
						});
						scope.ok = function () {
							$uibModalInstance.close({option:scope.data.pickedoption, notes:scope.data.notes, addnotestocosts:scope.data.addnotestocosts});
						};
						
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
					}],
					resolve: { 
						startdate: function() { return scope.startdate; },  
						interference: function() { return interference;}, 
						interferences: function() { return scope.interference;},
						canaddinterferencesnotestocosts : function() { return scope.options.canaddinterferencesnotestocosts; }
					}
				});
				modalInstance.result.then(
					function successCallback(data) {
						switch(data.option) {
							case "solve":
								if( interference ) { 
									interference.solved = true; 
									interference.notes = data.notes;
									interference.pickedoption = data.option;
									interference.addnotestocosts = data.addnotestocosts;
								} else {
									scope.interferences.forEach(function(e) { 
										e.solved = true;
										e.notes = data.notes;
										e.pickedoption = data.option;
										e.addnotestocosts = data.addnotestocosts;
									});
								}
								break;
							case "unsolve":
								if( interference ) { 
									interference.solved = false; 
									interference.notes = data.notes;
									interference.pickedoption = data.option;
									interference.addnotestocosts = data.addnotestocosts;
								} else {
									scope.interferences.forEach(function(e) { 
										e.solved = false;
										e.notes = data.notes;
										e.pickedoption = data.option;
										e.addnotestocosts = data.addnotestocosts;
									});
								}
								break;
							case "remind":
								if( interference ) { 
									interference.solved = false; 
									interference.notes = data.notes;
									interference.pickedoption = data.option;
									interference.addnotestocosts = data.addnotestocosts;
								} else {
									scope.interferences.forEach(function(e) { 
										e.solved = false;
										e.notes = data.notes;
										e.pickedoption = data.option;
										e.addnotestocosts = data.addnotestocosts;
									});
								}
								break;
							default:
								break;
						}
						scope.updateCanvas();
					}, function errorCallback() {}
				);
			};
			scope.addTimerangeToWork = function() {
				if( scope.selection.type!='work' || !scope.selection.work ) { return; }
				var work = scope.selection.work;
				if( !work.timeranges ) { work.timeranges=[];}
				var timerangesCount = work.timeranges.length;
				var startday = (timerangesCount ? (work.timeranges[timerangesCount-1].startDay+work.timeranges[timerangesCount-1].duration+1):1);
				var newTimerange = new models.Timerange(startday, 10);
				work.timeranges.push(newTimerange);
				scope.resetSelection();
				scope.updateInterferences();
				scope.updateCanvas();
			};
			scope.addTimerangeToSubwork = function() {
				if( scope.selection.type!='subwork' || !scope.selection.subwork ) { return; }
				var subwork = scope.selection.subwork;
				if( !subwork.timeranges ) { subwork.timeranges=[];}
				var timerangesCount = subwork.timeranges.length;
				var startday = (timerangesCount ? (subwork.timeranges[timerangesCount-1].startDay+subwork.timeranges[timerangesCount-1].duration+1):1);
				var newTimerange = new models.Timerange(startday, 10);
				subwork.timeranges.push(newTimerange);
				scope.updateInterferences();
				scope.resetSelection();
				scope.updateCanvas();
			};
			scope.joinTimeranges = function(timerange, parent, day, duration, direction) {
				if( parent.timeranges === undefined || !parent.timeranges.length) { return; }
				var idx = parent.timeranges.indexOf(timerange);
				if( idx==-1) { return; }
				if( direction < 0 ) {
					if( idx ) {
						var prevTimerange = parent.timeranges[idx-1];
						prevTimerange.duration += duration;
						parent.timeranges.splice(idx,1);
					}
				} else {
					if( (idx+1)<parent.timeranges.length ) {
						var nextTimerange = parent.timeranges[idx+1];
						timerange.startDay = day;
						timerange.duration = duration+nextTimerange.duration;
						parent.timeranges.splice(idx+1,1);
					}
				}
				scope.resetSelection();
				scope.updateInterferences();
				scope.updateCanvas();
			};
			scope.makeWorkingdays = function() {
				if( scope.highlighteddays == null ) { return; }
				var curDay;
				curDay = new Date(scope.startdate);
				curDay.setHours(12);
				curDay = new Date(curDay.getTime()+86400000*(scope.highlighteddays.startday-1));
				for( var i=0, j=scope.highlighteddays.startday-1; i<scope.highlighteddays.duration; i++, j++) {
					if( scope.workdays[j]==true) { continue; }
					var indexfound=-1;
					for( var z=0;z<scope.nonworkingdates.length;z++) {
						var dt = scope.nonworkingdates[z];
						if( dt.getFullYear()==curDay.getFullYear() && dt.getMonth()==curDay.getMonth() && dt.getDate()==curDay.getDate()) {
							indexfound=z;
							break; 
						}
					}
					if( indexfound!=-1) {
						scope.nonworkingdates.splice(indexfound, 1);
					} else {
						indexfound=-1;
						for( var z=0;z<scope.holidays.length;z++) {
							var dt = scope.holidays[z].date;
							if( dt.getMonth()==curDay.getMonth() && dt.getDate()==curDay.getDate()) {
								if( dt.getFullYear()==2000 || (dt.getFullYear()==dt.getDate())) {
									scope.workingdates.push(curDay);
									indexfound = z;
									break;	
								}
							}
						}
						if( indexfound==-1) {
							if( scope.weekdays[curDay.getDay()].isworking == false ) {
								scope.workingdates.push(curDay);
							}
						}
					}
					scope.workdays[j] = true;
					curDay = new Date(curDay.getTime()+86400000);
				}
		      	scope.highlighteddays = null;
				scope.updateCanvas();
			};
			scope.makeNonworkingdays = function() {
				if( scope.highlighteddays == null ) { return; }
				var curDay;
				curDay = new Date(scope.startdate);
				curDay.setHours(12);
				curDay = new Date(curDay.getTime()+86400000*(scope.highlighteddays.startday-1));
				for( var i=0, j=scope.highlighteddays.startday-1; i<scope.highlighteddays.duration; i++, j++) {
					if( scope.workdays[j]==false) { continue; }
					var indexfound=-1;
					for( var z=0;z<scope.workingdates.length;z++) {
						var dt = scope.workingdates[z];
						if( dt.getFullYear()==curDay.getFullYear() && dt.getMonth()==curDay.getMonth() && dt.getDate()==curDay.getDate()) {
							indexfound=z;
							break; 
						}
					}
					if( indexfound!=-1) {
						scope.workingdates.splice(indexfound, 1);
					} else {
						if( scope.weekdays[curDay.getDay()].isworking == true ) {
							scope.nonworkingdates.push(curDay);
						}
					}
					scope.workdays[j] = false;
					curDay = new Date(curDay.getTime()+86400000);
				}
		      	scope.highlighteddays = null;
				scope.updateCanvas();
			};
			
			scope.updateInterferences = function() {
				scope.zones.forEach(function(z) {
					scope.updateZoneInterferences(z);
				});
				scope.updateCanvas();
			}
			scope.updateZoneInterferences = function(zone) {
				var tmpInterferences = {};
				var newInterferences = [];
				zone.works.forEach(function(w) {
					if( !w.subworks.length ) {
						scope.addZoneInterferences(tmpInterferences, w);
					} else {
						w.subworks.forEach(function(sw) {
							scope.addZoneInterferences(tmpInterferences, w, sw);
						});
					}
				});
				var startday, duration=0, works={}, dayCount;
				for( var day in tmpInterferences ) {
					if( tmpInterferences[day].length>1 ) {
						dayCount = parseInt(day);
						if( startday === undefined ) {
							startday = dayCount;
						} else if( dayCount != (startday+duration)) {
							var solved = false;
							var notes = '';
							var pickedoption = '';
							var addnotestocosts = false;
							for( var j=0;j<zone.interferences.length;j++ ) {
								var curInt = zone.interferences[j];
								if( curInt.day == startday && curInt.duration == duration ) {
									solved = curInt.solved;
									notes = curInt.notes;
									pickedoption = curInt.pickedoption;	
									addnotestocosts = curInt.addnotestocosts;	
									break;
								}
							}
							var worksArray = [];
							for(var key in works) {
								
								var curWork = {_id: works[key]._id, name: works[key].name, contractor: works[key].contractor.name};
							    worksArray.push(curWork);
							}
							newInterferences.push({day:startday, duration:duration, solved:solved, works:worksArray, notes:notes, pickedoption:pickedoption, addnotestocosts:addnotestocosts});
							startday = dayCount;
							duration=0;
							works = {};
						}
						duration++;
						tmpInterferences[day].forEach(function(wk) {
							if( wk.subwork ) {
								works[wk.subwork._id]=wk.subwork;
							} else {
								works[wk.work._id]=wk.work;
							}
						});
					}
				}
				if( duration ) {
					var solved = false;
					var notes = '';
					var pickedoption = '';
					var addnotestocosts = false;
					for( var j=0;j<zone.interferences.length;j++ ) {
						var curInt = zone.interferences[j];
						if( curInt.day == startday && curInt.duration == duration ) {
							solved = curInt.solved;
							notes = curInt.notes;
							pickedoption = curInt.pickedoption;	
							addnotestocosts = curInt.addnotestocosts;	
							break;
						}
					}
					var worksArray = [];
					for(var key in works) {
						var curWork = {_id: works[key]._id, name: works[key].name, contractor: works[key].contractor.name};
					    worksArray.push(curWork);
					}
					newInterferences.push({day:startday, duration:duration, solved:solved, works:worksArray, notes:notes, pickedoption:pickedoption, addnotestocosts:addnotestocosts});
				}
				zone.interferences = newInterferences;
				scope.updateContractorInterferences();
				return;
			};
			scope.addZoneInterferences = function(interferences, work, subwork) {
				var timeranges = (subwork===undefined ? work.timeranges : subwork.timeranges);
				timeranges.forEach(function(tr) {
					for( var i=tr.startDay;i<(tr.startDay+tr.duration);i++ ) {
						var key = i ;
						if( interferences[key] === undefined ) { interferences[key]=[];}
						interferences[key].push({work:work, subwork:subwork});
					}
				});
			};
			scope.updateContractorInterferences = function() {
				var tmpInterferences = {};
				var newInterferences = [];
				scope.zones.forEach(function(zone) {
					zone.works.forEach(function(w) {
						if( !w.subworks.length ) {
							scope.addContractorInterferences(tmpInterferences, w);
						} else {
							w.subworks.forEach(function(sw) {
								scope.addContractorInterferences(tmpInterferences, w, sw);
							});
						}
					});
				});
				
				var startday, duration=0, contractors=new Set(), dayCount;
				for( var day in tmpInterferences ) {
					if( tmpInterferences[day].size>1 ) {
						dayCount = parseInt(day);
						if( startday === undefined ) {
							startday = dayCount;
						} else if( dayCount != (startday+duration)) {
							var solved = false;
							var notes = '';
							var pickedoption = '';
							var addnotestocosts = false;
							for( var j=0;j<scope.interferences.length;j++ ) {
								var curInt = scope.interferences[j];
								if( curInt.day == startday && curInt.duration == duration ) {
									solved = curInt.solved;
									notes = curInt.notes;
									pickedoption = curInt.pickedoption;	
									addnotestocosts = curInt.addnotestocosts;	
									break;
								}
							}
							newInterferences.push({day:startday, duration:duration, solved:solved, contractors:contractors, notes:notes, pickedoption:pickedoption, addnotestocosts:addnotestocosts});
							startday = dayCount;
							duration=0;
							contractors = new Set();
						}
						duration++;
						tmpInterferences[day].forEach(function(wk) {
							contractors.add(wk);
						});
					}
				}
				if( duration ) {
					var solved = false;
					var notes = '';
					var pickedoption = '';
					var addnotestocosts = false;
					for( var j=0;j<scope.interferences.length;j++ ) {
						var curInt = scope.interferences[j];
						if( curInt.day == startday && curInt.duration == duration ) {
							solved = curInt.solved;
							notes = curInt.notes;
							pickedoption = curInt.pickedoption;	
							addnotestocosts = curInt.addnotestocosts;	
							break;
						}
					}
					newInterferences.push({day:startday, duration:duration, solved:solved, contractors:contractors, notes:notes, pickedoption:pickedoption, addnotestocosts:addnotestocosts});
				}
				scope.interferences = newInterferences;
			};
			scope.addContractorInterferences = function(interferences, work, subwork) {
				var timeranges = (subwork===undefined ? work.timeranges : subwork.timeranges);
				var contractor = (subwork===undefined ? work.contractor : subwork.contractor);
				timeranges.forEach(function(tr) {
					for( var i=tr.startDay;i<(tr.startDay+tr.duration);i++ ) {
						var key = i ;
						if( interferences[key] === undefined ) { interferences[key]=new Set();}
						
						interferences[key].add((contractor && contractor.name ? contractor.name : null));
					}
				});
			};
			scope.updateStartdate = function() {
				if( scope.values.startdate && scope.values.startdate.getTime ) {
					scope.values.startdate.setHours(12);
					scope.values.startdate.setMilliseconds(0);
				}
				$timeout(function(){
					scope.updateWorkdays();	
					scope.updateInterferences();
					scope.updateCanvas();
				},0);
			};
			/* end actions */
			
			/* draw functions */
			scope.updateCanvas = function() {

				var ctx = scope.canvas.getContext("2d");
				var colwidth = scope.options.colwidth;
				var startgraphx = scope.options.zoneswidth+scope.options.workswidth;
				var headerheight = ((scope.options.showcalendar ? 2 : 0 ) +(scope.options.showdaycounts ? 1 : 0 ))*scope.options.rowheight;
				var zoneheight;
				var startdate = (scope.startdate && scope.startdate.getDay) ? scope.startdate : new Date();
				var hash = scope.options._id;
				ctx.fillStyle = 'rgb(255,255,255)';
				ctx.fillRect(0,0,scope.canvas.width, scope.canvas.height);
				var curY = 0;
				var odd = true;
				var removeHiddenZones = scope.options.removehiddenzones | false;
				var workdayslength = scope.workdays.length;
				
				scope.zonesizes = [];
				// Update nonworkingdays
				if( scope.options.shownonworkingdays ) {					
					for( var x=(scope.options.zoneswidth+scope.options.workswidth), j=scope.options.startday-1;x<scope.canvas.width;x += scope.options.colwidth, j++ ) {
						if( j>=workdayslength) {
							scope.updateWorkdays(false, 100);
							workdayslength+=100;
						}
					}
				}
				// Calculate work tags width and assign tags
				scope.worktagswidth = 0;
				scope.worktags = [];
				var zoneTagIndex = -1;
				scope.zones.forEach(function(c) {
		        		if( c.visible[hash] ) {
		        			var zoneheight = (removeHiddenZones ? scope.visibleRowsCount[c._id] : scope.totalRowsCount[c._id])*scope.options.rowheight;
		        			if( zoneheight ) { // if 0 it means that we want to remove zones without works
							var workTagIndex = 0;
							var curTagIndex = -1;
							zoneTagIndex++;
							scope.worktags.push([]);
							c.works.forEach(function(d) {
								if( !removeHiddenZones || (removeHiddenZones && d.visible[hash])) {
									++workTagIndex;
									++curTagIndex;
									scope.worktags[zoneTagIndex].push(workTagIndex+'');
									scope.worktagswidth = Math.max( ( ctx.measureText( scope.worktags[zoneTagIndex][curTagIndex] ) ).width, scope.worktagswidth );
								}
								var subworkTagIndex = -1;
								d.subworks.forEach(function(e) {
									if( !removeHiddenZones || (removeHiddenZones && e.visible[hash])) {
									subworkTagIndex = (subworkTagIndex +1>=scope.worktagsletters.length) ? 0: subworkTagIndex+1;
									scope.worktags[zoneTagIndex].push((workTagIndex+'')+scope.worktagsletters[subworkTagIndex]);
									++curTagIndex;
									scope.worktagswidth = Math.max( ( ctx.measureText( scope.worktags[zoneTagIndex][curTagIndex] ) ).width, scope.worktagswidth );
									}
								});
							});
		        			}
					}
				});
				scope.worktagswidth = Math.ceil(scope.worktagswidth)+6;
				
				// Draw
				var selectedZoneCurY = -1;
				var selectedZoneHeight = -1;
				var selectedWorkCurY = -1;
				var selectedWorkHeight = -1;
				var selectedSubworkCurY = -1;
				var deltaText = scope.options.rowheight*0.5+6;
				var deltax = (scope.options.colwidth<20 ? 2 : scope.options.colwidth*0.5);
				var step = (scope.options.colwidth<20 ? 10 : 1);

				var zoneTagIndex = -1;
				scope.zones.forEach(function(c) {
		        		var zoneheight = (removeHiddenZones ? scope.visibleRowsCount[c._id] : scope.totalRowsCount[c._id])*scope.options.rowheight;
		        		if( c.visible[hash] && zoneheight ) {
			        		zoneTagIndex++;
			        		var zoneSize = { startY:curY, headerY:curY+scope.options.zonetitleheight, calendarY:0, worksY:0 };
						// Header title
						ctx.save();
						ctx.font='bold 16px arial';
						ctx.lineWidth = 1.0;
						ctx.fillStyle = (c.color ? 'rgba('+c.color+',0.8)' : 'rgba(241, 194, 50, 0.8)');
						ctx.fillRect(1,curY+1,scope.canvas.width-1, scope.options.zonetitleheight-1);
						ctx.fillStyle = 'black';
						ctx.strokeRect(1,curY+1,scope.canvas.width-1, scope.options.zonetitleheight-1);
						ctx.fillText(c.name, 6,curY+scope.options.zonetitleheight*0.5+7);
						ctx.restore();
						curY += scope.options.zonetitleheight;
						var startTitleHeightY = curY;
						var deltax = scope.options.colwidth*0.5;
	
						// Calendar
						if( scope.options.showcalendar) {
							ctx.save();
							ctx.font = 'normal 13px arial';
							ctx.strokeStyle = 'rgb(220,220,220)';
							ctx.fillStyle = 'rgba(0,0,0,1.0)';
							ctx.textAlign = 'center';
							ctx.lineWidth = 0.5;
							
							var x=(scope.options.zoneswidth+scope.options.workswidth);
							ctx.beginPath();
							ctx.rect(x-1, curY-1, scope.canvas.width-x+2, scope.options.rowheight*3+2);
							ctx.clip();
							
							var curstartdate = new Date(startdate.getTime());
							curstartdate.setHours(12,0,0,0);
							curstartdate = new Date(curstartdate.getTime()+86400000*(scope.options.startday-1));
							var monthIndex = (curstartdate.getMonth()+1)%12;
							var curdate = new Date(curstartdate.getFullYear(), curstartdate.getMonth()+1, 0);
							var daysInMonth = curdate.getDate();
							var monthWidth = daysInMonth*scope.options.colwidth;
							ctx.beginPath();
							ctx.moveTo(x, curY);
							ctx.lineTo(scope.canvas.width, curY);
							ctx.moveTo(x, curY);
							ctx.lineTo(x, curY+2*scope.options.rowheight);
							ctx.stroke();
							curstartdate.setHours(12,0,0,0);
							// Uncomment to center monthname on real width: names could be cut 
							//x -= ((curstartdate.getDate()-1)*scope.options.colwidth);
							var curYear = curdate.getFullYear();
							
							for( var curday = curstartdate.getDate(), curx = scope.options.zoneswidth+scope.options.workswidth;curday <=daysInMonth;curday++, curx += scope.options.colwidth ) {
								if( curday%step==0 ) { 
									if( scope.options.colwidth>80) {
										ctx.fillText($filter('date')(new Date(curYear, monthIndex+1, curday), 'd MMM'), curx+deltax, curY+scope.options.rowheight*2-6);
									} else {
										ctx.fillText(curday+'', curx+deltax, curY+scope.options.rowheight*2-6);
									}
									ctx.beginPath();
									ctx.moveTo(curx,curY+scope.options.rowheight);
									ctx.lineTo(curx,curY+scope.options.rowheight+zoneheight);
									ctx.stroke();
								} else {
									ctx.beginPath();
									ctx.moveTo(curx,curY+scope.options.rowheight);
									ctx.lineTo(curx,scope.options.rowheight+zoneheight);
									ctx.stroke();
								}
							}
							var monthNameWidth = monthWidth - ((curstartdate.getDate()-1 ) * scope.options.colwidth );
							do {
								ctx.font = 'bold 15px arial';
								ctx.fillText($filter('date')(curdate, "MMM yy"), x+monthNameWidth*0.5, curY+scope.options.rowheight-6);
								ctx.font = 'normal 13px arial';
								x += monthNameWidth;
								monthIndex = (curdate.getMonth()+1);
								if( monthIndex > 11 ) {
									monthIndex = 0;
									curYear++;
								}
								curdate = new Date(curYear, monthIndex+1, 0);
								daysInMonth = curdate.getDate();
								monthWidth = daysInMonth*scope.options.colwidth;
								monthNameWidth = Math.min(scope.canvas.width-x, monthWidth);
								ctx.beginPath();
								ctx.moveTo(x, curY);
								ctx.lineTo(x, curY+2*scope.options.rowheight);
								ctx.stroke();
								
								for( var curday = 1, curx = x;curday <=daysInMonth;curday++, curx += scope.options.colwidth ) {
									if( curday%step==0 ) { 
										if( scope.options.colwidth>80) {
											ctx.fillText($filter('date')(new Date(curYear, monthIndex+1, curday), 'd MMM'), curx+deltax, curY+scope.options.rowheight*2-6);
										} else {
											ctx.fillText(curday+'', curx+deltax, curY+scope.options.rowheight*2-6);
										}
										ctx.beginPath();
										ctx.moveTo(curx,curY+scope.options.rowheight);
										ctx.lineTo(curx,curY+scope.options.rowheight+zoneheight);
										ctx.stroke();
									} else {
										ctx.beginPath();
										ctx.moveTo(curx,curY+scope.options.rowheight);
										ctx.lineTo(curx,curY+scope.options.rowheight+zoneheight);
										ctx.stroke();
									}
								}
							} while( x < scope.canvas.width);
							curY += scope.options.rowheight;
							ctx.moveTo(startgraphx, curY);
							ctx.lineTo(scope.canvas.width, curY);
							
							curY += scope.options.rowheight;
							ctx.moveTo(startgraphx, curY);
							ctx.lineTo(scope.canvas.width, curY);
							ctx.stroke();
							ctx.restore();
						}
						// Vertical lines
						ctx.save();
						ctx.strokeStyle = 'rgb(220,220,220)';
						ctx.lineWidth = 0.5;
						if( scope.options.colwidth>80) {
							var colhourwidth = scope.options.colwidth/24;
							var deltahourx = colhourwidth*0.5;
							for( var x=(scope.options.zoneswidth+scope.options.workswidth), j=1;x<scope.canvas.width;x += colhourwidth, j++ ) {
								ctx.beginPath();
								ctx.moveTo(x,curY);
								ctx.lineTo(x,curY+zoneheight+scope.options.rowheight);
								ctx.stroke();
								j = (j==24?0:j);
							}
						} else {
							for( var x=(scope.options.zoneswidth+scope.options.workswidth), j=scope.options.startday;x<scope.canvas.width;x += scope.options.colwidth, j++ ) {
								ctx.beginPath();
								ctx.moveTo(x,curY);
								ctx.lineTo(x,curY+zoneheight+scope.options.rowheight);
								ctx.stroke();
							}
						}
						ctx.restore();
							
						// Days or Hours
						if( scope.options.showdaycounts ) {
							ctx.save();
							ctx.fillStyle = 'rgb(120,120,120)';
							ctx.font = (scope.options.colwidth<40 ? 'normal 12px arial' : 'normal 12px arial');
							ctx.textAlign = 'center';//(scope.options.colwidth<40 ? 'left' : 'center');
							if( scope.options.colwidth>80) {
								var colhourwidth = scope.options.colwidth/24;
								var deltahourx = colhourwidth*0.5;
								for( var x=(scope.options.zoneswidth+scope.options.workswidth), j=1;x<scope.canvas.width;x += colhourwidth, j++ ) {
									ctx.fillText(j+'', x+deltahourx, curY+scope.options.rowheight-6);
									j = (j==24?0:j);
								}
							} else {
								for( var x=(scope.options.zoneswidth+scope.options.workswidth), j=scope.options.startday;x<scope.canvas.width;x += scope.options.colwidth, j++ ) {
									if( j%step==0 ) { 
										ctx.fillText(j+'', x+deltax, curY+scope.options.rowheight-6);
									}								
								}
							}
							ctx.restore();
							curY += scope.options.rowheight;
						}
						zoneSize.calendarY = curY;

						// Show nonworkingdays
						if( scope.options.shownonworkingdays ) {
							ctx.save();
							ctx.fillStyle='rgba(200,200,200,1.0)';
							for( var x=(scope.options.zoneswidth+scope.options.workswidth), j=scope.options.startday-1;x<scope.canvas.width;x += scope.options.colwidth, j++ ) {
								if( scope.workdays[j] == false ) {
									ctx.fillRect(x,curY,scope.options.colwidth, zoneheight);
								}
							}
							ctx.restore();
						}
						
						// Zone & works rows
						ctx.save();
						var startY = curY;
        					if( scope.selection.type=='zone' && c == scope.selection.zone ) { selectedZoneCurY=zoneSize.headerY; selectedZoneHeight = zoneheight+zoneSize.calendarY-zoneSize.headerY; }
						c.works.forEach(function(d) {
							if( !removeHiddenZones || (removeHiddenZones && d.visible[hash])) {
								if( scope.selection.type=='work' && d == scope.selection.work ) { 
									selectedWorkCurY=curY;
									selectedWorkHeight = (1+(!removeHiddenZones ? d.subworks.length : d.subworks.filter(function(e) {return e.visible;}).length))*scope.options.rowheight
								}
								
								ctx.fillStyle = (odd ? 'rgba(255,255,255,0.6)' : 'rgba(230,230,230,0.6)');
								ctx.fillRect(scope.options.zoneswidth+scope.worktagswidth,curY, scope.canvas.width-scope.options.zoneswidth-scope.worktagswidth, scope.options.rowheight);
								curY += scope.options.rowheight;
								odd = !odd;
							}
							d.subworks.forEach(function(e) {
								if( !removeHiddenZones || (removeHiddenZones && e.visible[hash])) {
									if( scope.selection.type=='subwork' && e == scope.selection.subwork ) { selectedSubworkCurY=curY; }
									ctx.fillStyle = (odd ? 'rgba(255,255,255,0.6)' : 'rgba(230,230,230,0.6)');
									ctx.fillRect(scope.options.zoneswidth+scope.worktagswidth,curY, scope.canvas.width-scope.options.zoneswidth-scope.worktagswidth, scope.options.rowheight);
									curY += scope.options.rowheight;
									odd = !odd;
								}
							});
						});
						if( !removeHiddenZones ) {
							if( !c.works.length ) {
								ctx.fillStyle = (odd ? 'rgba(255,255,255,0.6)' : 'rgba(230,230,230,0.6)');
								ctx.fillRect(scope.options.zoneswidth+scope.worktagswidth,curY, scope.canvas.width-scope.options.zoneswidth-scope.worktagswidth, scope.options.rowheight);
								ctx.fillStyle = 'rgb(0,0,0)';
							}
							curY = Math.max(startY+scope.options.rowheight, curY); 
						}
						ctx.restore();
						curY = startY; 
						
						// Draw works names
						ctx.save();
						ctx.beginPath();
						ctx.rect(0,curY, (scope.options.zoneswidth+scope.options.workswidth), curY+zoneheight);
						ctx.clip();
						
						ctx.font='normal 15px arial';
						var startY = curY;
						var curIdx = -1;
						c.works.forEach(function(d) {
							if( !removeHiddenZones || (removeHiddenZones && d.visible[hash])) {
								curIdx++;
								ctx.save();
								switch(scope.options.color) {
									case 'work':
										ctx.fillStyle = (d.color ? 'rgba('+d.color+',0.8)' : 'rgba(241, 194, 50, 0.8)');
										break;
									default:
										ctx.fillStyle = (c.color ? 'rgba('+c.color+',0.8)' : 'rgba(241, 194, 50, 0.8)');
										break;
								}
								ctx.fillRect(0,curY,8,scope.options.rowheight);
								ctx.fillStyle = 'rgb(0,0,0)';
								ctx.textAlign='center';
								ctx.lineWidth = 0.5;
								ctx.strokeStyle = 'lightgray';
								ctx.strokeRect(scope.options.zoneswidth, curY, scope.worktagswidth, scope.options.rowheight );
								ctx.fillText(scope.worktags[zoneTagIndex][curIdx], scope.options.zoneswidth+scope.worktagswidth*0.5,curY+deltaText);
								ctx.textAlign='start';
								ctx.fillText(d.name, scope.options.zoneswidth+scope.worktagswidth+5, curY+deltaText);
								curY += scope.options.rowheight;
								ctx.restore();
							}
							d.subworks.forEach(function(e) {
								if( !removeHiddenZones || (removeHiddenZones && e.visible[hash])) {
									curIdx++;
									switch(scope.options.color) {
										case 'work':
											ctx.fillStyle = (d.color ? 'rgba('+d.color+',0.8)' : 'rgba(241, 194, 50, 0.8)');
											break;
										default:
											ctx.fillStyle = (c.color ? 'rgba('+c.color+',0.8)' : 'rgba(241, 194, 50, 0.8)');
											break;
									}
									ctx.fillRect(0,curY,8,scope.options.rowheight);
									ctx.fillStyle = 'rgb(0,0,0)';
									ctx.textAlign='center';
									//ctx.strokeRect(scope.options.zoneswidth, curY, scope.worktagswidth, scope.options.rowheight );
									ctx.fillText(scope.worktags[zoneTagIndex][curIdx], scope.options.zoneswidth+scope.worktagswidth*0.5,curY+deltaText);
									ctx.fillStyle = 'rgb(100,100,100)';
									ctx.textAlign='start';
									ctx.fillText("- "+e.name, scope.options.zoneswidth+scope.worktagswidth+5, curY+deltaText);
									curY += scope.options.rowheight;
								}
							});
						});
						ctx.restore();	
						
						// Vertical zone/work separation lines
						ctx.save();
						ctx.beginPath();
						ctx.strokeStyle = 'rgb(220,220,220)';
						ctx.moveTo(scope.options.zoneswidth,startY);
						ctx.lineTo(scope.options.zoneswidth,startY+zoneheight);
						ctx.stroke();
						ctx.beginPath();
						ctx.moveTo((scope.options.zoneswidth+scope.options.workswidth),startTitleHeightY);
						ctx.lineTo((scope.options.zoneswidth+scope.options.workswidth),curY+zoneheight);
						ctx.stroke();
						ctx.restore();
				
						// Timeranges
						ctx.save();
						curY = startY; 
						ctx.fillStyle='rgba(241, 194, 50, 0.8)';
						ctx.strokeStyle='rgba(255,0,0,0.9)';
						ctx.lineWidth=1;
						var radius = 6;
						var startX = (-scope.options.startday)*scope.options.colwidth+(scope.options.zoneswidth+scope.options.workswidth);
						
						ctx.beginPath();
						ctx.rect((scope.options.zoneswidth+scope.options.workswidth),0, scope.canvas.width-(scope.options.zoneswidth+scope.options.workswidth), scope.canvas.height);
						ctx.clip();
						
						var selectedTimerange = (scope.selection.type=='timerange' ? scope.selection.timerange : null);
						var selectedTotaltimerange = (scope.selection.type=='totaltimerange' ? scope.selection.work : null);
	        				var fromY = curY;
	        			
						c.works.forEach(function(d) {
							if( !removeHiddenZones || (removeHiddenZones && d.visible[hash]) ) {
								var subworks = d.subworks;
								if( subworks.length == 0) {
									if( !d.visible[hash] ) {
										ctx.fillStyle = 'rgba(220,220,220,0.8)';
									} else {
										switch(scope.options.color) {
											case 'work':
												ctx.fillStyle = (d.color ? 'rgba('+d.color+',0.8)' : 'rgba(241, 194, 50, 0.8)');
												break;
											default:
												ctx.fillStyle = (c.color ? 'rgba('+c.color+',0.8)' : 'rgba(241, 194, 50, 0.8)');
												break;
										}
									}
									
									d.timeranges.forEach(function(tr) {
										if( selectedTimerange != tr) {
											scope.drawTimerange(ctx, startX, curY, tr);
										}
									});
									curY += scope.options.rowheight;
								} else {
									var parentY = curY;
									var startDay = 999999;
									var endDay = -999999;
									curY += scope.options.rowheight;
									d.subworks.forEach(function(sw) {
										if( !sw.visible[hash] ) {
											ctx.fillStyle = 'rgba(220,220,220,0.8)';
										} else {
											switch(scope.options.color) {
												case 'work':
													ctx.fillStyle = (d.color ? 'rgba('+d.color+',0.8)' : 'rgba(241, 194, 50, 0.8)');
													break;
												default:
													ctx.fillStyle = (c.color ? 'rgba('+c.color+',0.8)' : 'rgba(241, 194, 50, 0.8)');
													break;
											}
										}
										if( !removeHiddenZones || (removeHiddenZones && sw.visible[hash]) ) {
											sw.timeranges.forEach(function(tr) {
												startDay = Math.min(startDay, tr.startDay);
												endDay = Math.max(endDay, tr.startDay+tr.duration);
												if( selectedTimerange != tr && selectedTotaltimerange != d) {
													scope.drawTimerange(ctx, startX, curY, tr);
												}
											});
											curY += scope.options.rowheight;
										}
									});
									if( !d.visible[hash] ) {
										ctx.fillStyle = 'rgba(220,220,220,0.8)';
									} else {
										switch(scope.options.color) {
											case 'work':
												ctx.fillStyle = (d.color ? 'rgba('+d.color+',0.8)' : 'rgba(241, 194, 50, 0.8)');
												break;
											default:
												ctx.fillStyle = (c.color ? 'rgba('+c.color+',0.8)' : 'rgba(241, 194, 50, 0.8)');
												break;
										}
									}
									if( selectedTotaltimerange != d ) {
										scope.drawTotaltimerange(ctx, startX, parentY, startDay, endDay);
									}
								}
							}
						});
						
						if( !removeHiddenZones ) { 
							curY = Math.max(fromY+scope.options.rowheight, curY);
						}
						ctx.restore();
						
						// Interferences
						if( scope.options.showinterferences) {
							curY = startY;
							startX = (-scope.options.startday)*scope.options.colwidth+(scope.options.zoneswidth+scope.options.workswidth);
							
							ctx.save();
							ctx.lineWidth=1;
							// Contractors interferences
							if( scope.options.showdaycounts ) {
								ctx.beginPath();
								ctx.rect((scope.options.zoneswidth+scope.options.workswidth),0, scope.canvas.width-(scope.options.zoneswidth+scope.options.workswidth), headerheight+1);
								ctx.clip();
								curY = headerheight-scope.options.rowheight;
								// Disabled 
								//scope.interferences.forEach(function(interference) {
								//	if( scope.options.showinterferences == 2 || (scope.options.showinterferences == 1 && interference.solved==false) ) {
								//		ctx.strokeStyle = interference.solved ? 'rgba(102,204,0,0.6)' : 'rgba(204,204,0,0.6)';
								//		ctx.fillStyle = interference.solved ? 'rgba(153,255,51,0.6)' : 'rgba(255,255,102,0.6)';
								//		ctx.fillRect(startX+interference.day*scope.options.colwidth, curY, interference.duration*scope.options.colwidth,scope.options.rowheight*0.4); 
								//		ctx.strokeRect(startX+interference.day*scope.options.colwidth+1, curY+1, interference.duration*scope.options.colwidth-2,scope.options.rowheight*0.4-2); 
								//	}
								//});
							}
							ctx.restore();
							// Zone interferences
							ctx.save();
							ctx.lineWidth=2;
							ctx.beginPath();
							ctx.rect((scope.options.zoneswidth+scope.options.workswidth),startY, scope.canvas.width-(scope.options.zoneswidth+scope.options.workswidth), startY+zoneheight);
							ctx.clip();
							c.interferences.forEach(function(interference) {
								if( scope.options.showinterferences == 2 || (scope.options.showinterferences == 1 && interference.solved==false) ) {
									ctx.strokeStyle = interference.solved ? 'rgba(102,204,0,0.6)' : 'rgba(204,204,0,0.6)';
									ctx.fillStyle = interference.solved ? 'rgba(153,255,51,0.3)' : 'rgba(255,255,102,0.3)';
									ctx.fillRect(startX+interference.day*scope.options.colwidth, startY, interference.duration*scope.options.colwidth,zoneheight); 
									ctx.strokeRect(startX+interference.day*scope.options.colwidth+1, startY+1, interference.duration*scope.options.colwidth-2,zoneheight-2); 
								}
							});
							ctx.restore();
						}
						zoneSize.worksY = startY+zoneheight;
						scope.zonesizes.push(zoneSize);
						curY = startY+zoneheight+10;
					} else {
			        		var zoneSize = { startY:curY, headerY:curY, calendarY:curY, worksY:curY };
						scope.zonesizes.push(zoneSize);
					}
		        });
		        
				// Draw selections
				if( selectedZoneCurY != -1 ) {
				    ctx.save();
        			ctx.strokeStyle='rgba(255,0,0,0.8)';
        			ctx.lineWidth=2;
        			ctx.strokeRect(2, selectedZoneCurY+2, scope.canvas.width-4, selectedZoneHeight-2);
        			ctx.restore();
				} else if( selectedWorkCurY != -1 ) {
				    ctx.save();
        			ctx.strokeStyle='rgba(255,0,0,0.8)';
        			ctx.lineWidth=2;
        			ctx.strokeRect(scope.options.zoneswidth+1, selectedWorkCurY+1, scope.canvas.width-scope.options.zoneswidth-4, selectedWorkHeight-2);
        			ctx.restore();
				} else if( selectedSubworkCurY != -1 ) {
				    ctx.save();
        			ctx.strokeStyle='rgba(255,0,0,0.8)';
        			ctx.lineWidth=2;
        			ctx.strokeRect(scope.options.zoneswidth+1, selectedSubworkCurY+1, scope.canvas.width-scope.options.zoneswidth-4, scope.options.rowheight);
        			ctx.restore();
				}
				if( scope.highlighteddays ) {
    				var fromX = scope.options.workswidth+scope.options.zoneswidth + (scope.highlighteddays.startday-scope.options.startday)*scope.options.colwidth;
    				var deltaX = scope.highlighteddays.duration*scope.options.colwidth;
        			ctx.save();
        			ctx.beginPath();
        			ctx.fillStyle='rgba(255,0,0,0.2)';
        			scope.zonesizes.forEach(function(z) {
        				ctx.rect(fromX, z.headerY, deltaX, z.worksY-z.headerY);
						ctx.fill();
        			});
        			ctx.closePath();
        			ctx.restore();
				}
				/*ctx.strokeStyle='rgba(0,0,0,1.0)';
				ctx.lineWidth=2;
				ctx.strokeRect(1,1,scope.canvas.width-2, scope.canvas.height-2);
				ctx.lineWidth=1;*/
	  			scope.currentImageData = ctx.getImageData(0,0, scope.canvas.width, scope.canvas.height);
			
			};
			scope.drawTask = function(ctx, fromX, fromY, duration) {
				var startX = fromX;
				var startY = fromY+3;
				var endX = startX+duration*scope.options.colwidth;
				var endY = fromY+scope.options.rowheight-3;
				var radius = 6;
				
				ctx.beginPath();
				ctx.moveTo(startX+radius, startY);
				ctx.lineTo(endX-radius, startY);
				ctx.quadraticCurveTo(endX, startY, endX, startY+radius);
				ctx.lineTo(endX, endY-radius);
				ctx.quadraticCurveTo(endX, endY, endX-radius, endY);
				ctx.lineTo(startX+radius, endY);
				ctx.quadraticCurveTo(startX, endY, startX, endY-radius);
				ctx.lineTo(startX, startY+radius);
				ctx.quadraticCurveTo(startX, startY, startX+radius, startY);
				ctx.closePath();
				ctx.fill();
			};
			scope.drawTimerange = function(ctx, fromX, fromY, timerange) {
				var startX = fromX+(timerange.startDay)*scope.options.colwidth;
				var endX = startX+timerange.duration*scope.options.colwidth;
				if( startX > scope.canvas.width || endX<(scope.options.zoneswidth+scope.options.workswidth)) {
					return;
				}
				var startY = fromY+3;
				var endY = fromY+scope.options.rowheight-3;
				var radius = 6;
				
				ctx.beginPath();
				ctx.moveTo(startX+radius, startY);
				ctx.lineTo(endX-radius, startY);
				ctx.quadraticCurveTo(endX, startY, endX, startY+radius);
				ctx.lineTo(endX, endY-radius);
				ctx.quadraticCurveTo(endX, endY, endX-radius, endY);
				ctx.lineTo(startX+radius, endY);
				ctx.quadraticCurveTo(startX, endY, startX, endY-radius);
				ctx.lineTo(startX, startY+radius);
				ctx.quadraticCurveTo(startX, startY, startX+radius, startY);
				ctx.closePath();
				ctx.fill();
			};
			scope.drawTotaltimerange = function(ctx, fromX, fromY, startday, endday) {
				
				var startX = fromX+startday*scope.options.colwidth;
				var endX = fromX+endday*scope.options.colwidth;
				if( startX > scope.canvas.width || endX<(scope.options.zoneswidth+scope.options.workswidth)) {
					return;
				}
				var startY = fromY+10;
				var endY = fromY+scope.options.rowheight-10;
				var radius = 3;
				
				ctx.beginPath();
				ctx.moveTo(startX+radius, startY);
				ctx.lineTo(endX-radius, startY);
				ctx.quadraticCurveTo(endX, startY, endX, startY+radius);
				ctx.lineTo(endX, endY-radius);
				ctx.quadraticCurveTo(endX, endY, endX-radius, endY);
				ctx.lineTo(startX+radius, endY);
				ctx.quadraticCurveTo(startX, endY, startX, endY-radius);
				ctx.lineTo(startX, startY+radius);
				ctx.quadraticCurveTo(startX, startY, startX+radius, startY);
				ctx.closePath();
				ctx.fill();
			};
			scope.drawTotalAndSingletimeranges = function(ctx, fromX, fromY, startday, endday, work) {
				
				var startX = fromX+startday*scope.options.colwidth;
				var endX = fromX+endday*scope.options.colwidth;
				if( startX > scope.canvas.width || endX<(scope.options.zoneswidth+scope.options.workswidth)) {
					return;
				}
				var startY = fromY+10;
				var endY = fromY+scope.options.rowheight-10;
				var radius = 3;
				var removeHiddenZones = scope.options.removeHiddenZones;
				ctx.beginPath();
				ctx.moveTo(startX+radius, startY);
				ctx.lineTo(endX-radius, startY);
				ctx.quadraticCurveTo(endX, startY, endX, startY+radius);
				ctx.lineTo(endX, endY-radius);
				ctx.quadraticCurveTo(endX, endY, endX-radius, endY);
				ctx.lineTo(startX+radius, endY);
				ctx.quadraticCurveTo(startX, endY, startX, endY-radius);
				ctx.lineTo(startX, startY+radius);
				ctx.quadraticCurveTo(startX, startY, startX+radius, startY);
				ctx.closePath();
				ctx.fill();
				
				var srcStartday = 99999, deltaday = 0;
				work.subworks.forEach(function(e) {
					e.timeranges.forEach(function(tr) {
						srcStartday = Math.min(srcStartday, tr.startDay);
					});
				});
				deltaday = startday-srcStartday;
				work.subworks.forEach(function(e) {
					fromY += scope.options.rowheight;
					e.timeranges.forEach(function(tr) {
						if( !removeHiddenZones || (removeHiddenZones && e.visible[hash])) {
							scope.drawTask(ctx, fromX+(tr.startDay+deltaday)*scope.options.colwidth, fromY, tr.duration);
						}
					});
				});
			};
			/* end draw function */
			
			/* accessory functions */
			scope.visibility = function() {
				var obj = {};
				obj[scope.options._id] = true;
				return obj;
			};
			/* end accessory functions */
			/* init */
			scope.updateWorkdays();
			if( !scope.options._id ) { scope.options._id = models.clientId();} // Update hash for options
			var hash = scope.options._id;
			scope.zones.forEach(function(c) {
				if( c._id === undefined ) { c._id = models.clientId();}
				if( c.visible === undefined || c.visible[hash] === undefined ) { c.visible = {};}
				c.visible[hash] = (c.visible[hash]!==undefined ? c.visible[hash] :true);
				scope.visibleRowsCount[c._id] = 0;
				scope.totalRowsCount[c._id] = 0;
				if( !c.works ) { c.works = [];}
				c.works.forEach(function(d) {
					if( d.visible === undefined || d.visible[hash] === undefined ) { d.visible = {};}
					d.visible[hash] = (d.visible[hash]!==undefined ? d.visible[hash] :true);
					if( !d.subworks ) { d.subworks = [];}
					d.subworks.forEach(function(e) {
						if( e.visible === undefined || e.visible[hash] === undefined ) { e.visible = {};}
						e.visible[hash] = (e.visible[hash]!==undefined ? e.visible[hash] :true );
					});
					scope.visibleRowsCount[c._id] += (1+d.subworks.length);
					scope.totalRowsCount[c._id] += (1+d.subworks.length);
				});
			});
			scope.zones.forEach(function(c) {
				scope.updateZoneInterferences(c);
			});
			scope.canvas.focus();
		}
	};
}]).directive('ngTouchmove', [function() {
  return {
    controller: ['$scope', '$element', '$attrs', function ($scope, $element, $attrs) {
	  
      $element.bind('touchstart', onTouchStart);
      function onTouchStart(event) {
        event.preventDefault();
      	var method = $element.attr('data-ng-touchstart');
	  	$scope.$event = event;
	  	$scope.$apply(method);
	  	
        $element.bind('touchmove', onTouchMove);
        $element.bind('touchend', onTouchEnd);
      };
      
      function onTouchMove(event) {
          var method = $element.attr('data-ng-touchmove');
          $scope.$event = event;
          $scope.$apply(method);
      };
      
      function onTouchEnd(event) {
        event.preventDefault();
        
        $element.unbind('touchmove', onTouchMove);
        $element.unbind('touchend', onTouchEnd);
      	var method = $element.attr('data-ng-touchend');
	  	$scope.$event = event;
	  	$scope.$apply(method);
      };
    }]
  };
}]);
