angular.module('worksiteMessages', [])
.directive('worksiteMessage',[function()  {
    return {
		restrict: 'C',
		templateUrl: assetsURL+'components/worksite-messages/templates/worksite-message.template.html',
        scope: { message: '=', toggledetailaction: '&', index: '@', selectedindex: '=' },
		link: function (scope, elem, attrs) {
			scope.$watchGroup(['index', 'selectedindex'], function(val) {
				if( val === undefined ) { return; }
				scope.showdetail = (scope.index == scope.selectedindex);
			});
			scope.toggleShowDetail = function() {
				if( scope.toggledetailaction ) {
					scope.toggledetailaction()(scope.index);
				}
			};
		}
	}
}])
.directive('worksiteMessagesList',[function()  {
    return {
		restrict: 'C',
		templateUrl: assetsURL+'components/worksite-messages/templates/worksite-messages-list.template.html',
        scope: { messages: '=' },
		link: function (scope, elem, attrs) {
			scope.selectedindex = -1;
			scope.toggleDetailView = function(index) {
				scope.selectedindex = scope.selectedindex == index ? -1 : index;
			};
		}
	}
}]);
