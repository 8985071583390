angular.module('worksiteEnterprisesDocuments', [])
.directive('worksiteEnterprisesDocument', ['$timeout','$uibModal','models','$http', function ($timeout, $uibModal, models, $http ) {
    return {
		scope: { refdoc : '=', enterpriseid: '@', worksite: '=', validateaction: '&', saveaction: '&' },
		restrict: 'C',
		templateUrl: assetsURL+'components/worksite-enterprises-documents/templates/worksite-enterprises-document.template.html',
		link: function (scope, elem, attrs) {
			scope.isValid = true;
			scope.error = null;
			scope.validateDoc = function() {
				if( scope.refdoc.required ) {
					if( scope.refdoc.files.length ) {
						if( scope.refdoc.validity.type > 0 ) {
							var expirydate = scope.refdoc.expirydate ? new Date(scope.refdoc.expirydate) : new Date("2000-01-01");
							var now = new Date();
							if( expirydate.getTime() < now.getTime() ) {
								scope.error = "Documento scaduto";
								scope.isValid = false;
								return;
							}
						} 
					} else {
						scope.isValid = false;
						scope.error = "Documento obbligatorio mancante";
						return;
					}
				} else {
					if( scope.refdoc.files.length ) {
						if( scope.refdoc.validity.type > 0 ) {
							var expirydate = scope.refdoc.expirydate ? new Date(scope.refdoc.expirydate) : new Date("2000-01-01");
							var now = new Date();
							if( expirydate.getTime() < now.getTime() ) {
								scope.isValid = false;
								scope.error = "Documento scaduto";
								return;
							}
						} 
					}
				}
				scope.isValid = true;
				scope.error = null;
			};
			scope.$watch('refdoc', function(val) {
				if( val === undefined ) { return; }
				scope.validateDoc();
			}, true);
			
			scope.validateFile = function(index) {
				if( index < 0 || index > scope.refdoc.files.length-1 ) { return; }
				if( scope.validateaction ) {
					scope.validateaction()(scope.refdoc, index);
				}
			};

			scope.toggleRequired = function() {
				if( scope.saveaction ) {
					scope.refdoc.required = !scope.refdoc.required;
					scope.validateDoc();
					scope.saveaction()();
				}
			};

			scope.downloadDocFile = function(index) {
				if( index >= 0 && index < scope.refdoc.files.length ) {
					var downloadingDoc = scope.refdoc.files[index];
					$timeout(function () {
						var a = document.createElement('a');
						document.body.appendChild(a);
						a.download = downloadingDoc.name;
						a.href = appURL+"/services/enterprises-docs/download/"+downloadingDoc._id;
						a.click();
						document.body.removeChild(a);
					}, 200);
				}
			};

			scope.validateDoc();
		}
	};
}]).directive('worksiteEnterprisesDocuments', ['$timeout', '$http','$uibModal', 'models', function ($timeout, $http, $uibModal, models) {
    return {
		scope: { refdocuments: '=', section: '@', enterpriseid: '@', worksite: '=', validatorid: '@'},
		restrict: 'C',
		templateUrl: assetsURL+'components/worksite-enterprises-documents/templates/worksite-enterprises-documents.template.html',
		link: function (scope, elem, attrs) {
		
			scope.addDocumentType = function() {
				var modalInstance = $uibModal.open({
					templateUrl: assetsURL+'components/worksite-enterprises-documents/templates/worksite-enterprises-document-addTypeModal.html',
					controller: ['$http', '$scope', '$uibModalInstance', 'section','refdocuments','worksite','enterpriseid', function ($http, scope, $uibModalInstance, section, refdocuments, worksite, enterpriseid ) {
						scope.isLoading = true;
						scope.availabledoctypes = [];
						scope.selectAll = function() {
							availabledoctypes.forEach(function(doctype) {
								doctype.selected = true;
							});
						};
						scope.deselectAll = function() {
							availabledoctypes.forEach(function(doctype) {
								doctype.selected = false;
							});
						};
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.ok = function () {
							scope.isLoading = true;
							availabledoctypes.forEach(function(doctype) {
								var newDocType = new models.RefEnterpriseDoc(doctype.type);
								refdocuments.push(newDocType);
							});
							$http.post(appURL+'/services/enterprises-docs/updateworksiterelated', {worksite:worksite, enterpriseId: enterpriseid, filesIdsToBeAdded: [], filesIdsToBeRemoved:[] }).then( 
								function successCallback( response ) { 
									scope.isLoading = false;
									$uibModalInstance.close(null);
								}, function errorCallback( response ) {
									alert("Errore di sistema. Riprova più tardi.");
									scope.isLoading = false;
									$uibModalInstance.close(null);
								}
							);
						};
						
						$http.get(appURL+'/crud/enterprisedoctypes?section='+encodeURIComponent(section)).then( 
							function successCallback( response ) { 
								if( response.data === undefined || !response.data.length ) {
									alert("Nessun documento disponibile per questa categoria.");
									scope.isLoading = false;
									$uibModalInstance.close(null);
									return;
								}
								var doctypes = response.data;
								var hashedrefdocs = {};
								refdocuments.forEach(function(refdoc) {
									hashedrefdocs[refdoc.type]=true;
								});
								console.log(hashedrefdocs);
								doctypes.forEach(function(doctype) {
									console.log(doctype.type);
									if( hashedrefdocs[doctype.type] === undefined ) {
										var newDoctype = doctype;
										newDoctype.selected = false;
										scope.availabledoctypes.push(newDoctype);
										console.log("adding");
									}
								});
								scope.isLoading = false;
								console.log(scope.availabledoctypes);
							}, function errorCallback( response ) {
								alert("Errore di sistema. Riprova più tardi.");
								scope.isLoading = false;
								$uibModalInstance.close(null);
							}
						);
					}],
					resolve: { 
						section: function() { return scope.section; },
						refdocuments: function() { return scope.refdocuments; },
						worksite: function() { return scope.worksite; },
						enterpriseid: function() { return scope.enterpriseid; }
					}
				});
				modalInstance.result.then(
					function successCallback(data) {
					}, function errorCallback() {
					}
				);
			};
			
			scope.saveAction = function() {
				$http.post(appURL+'/services/enterprises-docs/updateworksiterelated', {worksite:scope.worksite, enterpriseId: scope.enterpriseid, filesIdsToBeAdded: [], filesIdsToBeRemoved:[] }).then( 
					function successCallback( response ) { 
					}, function errorCallback( response ) {
						alert("Errore di sistema. Riprova più tardi.");
					}
				);
			};
			
			scope.validateDocFile = function(refdoc, index) {
				var modalInstance = $uibModal.open({
					size: 'fullscreen',
					templateUrl: assetsURL+'components/worksite-enterprises-documents/templates/worksite-enterprises-document-validateModal.html',
					controller: ['$http', '$sce', '$scope', '$uibModalInstance', 'refdoc','file','validatorid','worksite', 'enterpriseid', function ($http, $sce, scope, $uibModalInstance, refdoc, file, validatorid, worksite, enterpriseid ) {
						scope.isLoading = true;
						scope.validationpages = [];
						scope.pageIndex = 0;
						scope.validation = null;
						scope.replaceValidationIndex = -1;
						scope.url = null;
						scope.showDoc = false;
						scope.toggleShowDoc = function() {
							scope.showDoc = !scope.showDoc;
						};
						scope.maxHeight = $( window ).height()-200;
						
						scope.goBack = function() {
							if( scope.pageIndex>0 ) { scope.pageIndex = scope.pageIndex-1; }	
						};
						scope.goForward = function() {
							if( scope.pageIndex<scope.validation.pages.length ) { scope.pageIndex = scope.pageIndex+1; }	
						};
						
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.ok = function () {
							scope.isLoading = true;
							if( scope.replaceValidationIndex == -1 ) {
								file.validations.push(scope.validation);
							} else {
								file.validations[replaceValidationIndex]=scope.validation;
							}
							refdoc.validationstatus = scope.validation.status+1;
							$http.post(appURL+'/services/enterprises-docs/updateworksiterelated', {worksite:worksite, enterpriseId: enterpriseid, filesIdsToBeAdded: [], filesIdsToBeRemoved:[] }).then( 
								function successCallback( response ) { 
									scope.isLoading = false;
									$uibModalInstance.close(null);
								}, function errorCallback( response ) {
									alert("Errore di sistema. Riprova più tardi.");
									scope.isLoading = false;
									$uibModalInstance.close(null);
								}
							);
						};
						
						$http.get(appURL+'/crud/enterprisedoctypes?type='+encodeURIComponent(refdoc.type)).then( 
							function successCallback( response ) { 
								var customvalidation = false;
								if( response.data && response.data.length ) {
									customvalidation = response.data[0].customvalidation === undefined ? false : response.data[0].customvalidation;
									scope.validationpages = response.data[0].validationpages || [];
								}
								// Check if validation is already present
								for( var i=file.validations.length-1;i>=0;i-- ) {
									if( file.validations[i].validatorid == validatorid ) {
										// Check if section descriptions are the same
										if( customvalidation == true ) {
											var templateValidationHash = "";
											scope.validationpages.forEach(function(page) {
												templateValidationHash += page.sections.length;
												page.sections.forEach(function(section) {
													templateValidationHash += section.description;
												});
											});
											
											var validationHash = "";
											file.validations[i].pages.forEach(function(page) {
												validationHash += page.sections.length;
												page.sections.forEach(function(section) {
													validationHash += section.description;
												});
											});
											if( templateValidationHash == validationHash ) {
												scope.validation = JSON.parse(JSON.stringify(file.validations[i]));
												scope.validation.deadlinedate = new Date(scope.validation.deadlinedate);
												break;
											} else {
												scope.replaceValidationIndex = i;
												break;
											}
										} else {
											scope.validation = JSON.parse(JSON.stringify(file.validations[i]));
											scope.validation.deadlinedate = new Date(scope.validation.deadlinedate);
											scope.validation.pages = [];
											break;
										}
									} 
								}
								
								if( !scope.validation ) {
									scope.validation = new models.RefEnterpriseDocFileValidation(validatorid);
									scope.validationpages.forEach(function(page) {
										scope.validation.pages.push(new models.RefEnterpriseDocFilePageValidation(page.description, page.sections));
									});
								}
								$http.get(appURL+"/services/enterprises-docs/download/"+file._id, { responseType:'arraybuffer' } ).then( 
									function successCallback(response) {
										var pdf = new Blob([response.data], {type: 'application/pdf'});
										var pdfURL = URL.createObjectURL(pdf);
										scope.url = $sce.trustAsResourceUrl(pdfURL);
										scope.showDoc = true;
										scope.isLoading = false;
									}, function errorCallback() {
										scope.isLoading = false;
									}
								);							
							}, function errorCallback( response ) {
								alert("Errore di sistema. Riprova più tardi.");
								scope.isLoading = false;
								$uibModalInstance.close(null);
							}
						);
					}],
					resolve: { 
						refdoc: function() { return refdoc; },
						file: function() { return refdoc.files[index]; },
						validatorid: function() { return scope.validatorid; },
						worksite: function() { return scope.worksite; },
						enterpriseid: function() { return scope.enterpriseid; }
					}
				});
				modalInstance.result.then(
					function successCallback(data) {
					}, function errorCallback() {
					}
				);
			};
			
		}
	};
}]);

