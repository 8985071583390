angular.module('worksiteEnterprises', [])
.directive('worksiteEnterprise',['$uibModal','$http','models',function($uibModal, $http, models)  {
    return {
		transclude: true,
		templateUrl: assetsURL+'components/worksite-enterprises/templates/worksite-enterprise.template.html',
        scope: { enterprise: '=', subcontractors:'=', deletecallback:'&'},
		link: function ($scope, elem, attrs) {
			$scope.editEnterprise = function() {
				var modalInstance = $uibModal.open({
					templateUrl: assetsURL+'components/worksite-enterprises/templates/worksite-enterprise-editModal.html',
					size: 'lg',
					controller: ['$scope', '$uibModalInstance', 'contact', function (scope, $uibModalInstance, contact ) {
						
						scope.updatecontacts = true;
						scope.object = (contact === undefined ? {} : angular.copy(contact));
						if( scope.object._id === undefined ) {
							scope.object._id = models.clientId();
						}
						scope.originalid = scope.object._id;
						scope.updatestoredcontactdisabled = false;
						scope.$watch('object', function(newval) {
							if( newval === undefined ) { return; }
							if( scope.object._id !== scope.originalid ) {
								scope.updatecontacts = false;
								scope.updatestoredcontactdisabled = true;
							}
						}, true);
						scope.getContacts = function(val) {
							return $http.get( appURL+'/utils/contacts'+( val ? '?search=' + val+"&type=society" : '' ) ).then( 
								function successCallback( response ) {
									return response.data; 
								}, function errorCallback( response ) {}
							);
						};
						scope.getAteco = function(val) {
							return $http.get( appURL+'/utils/ateco'+( val ? '?search=' + encodeURIComponent(val) : '' ) ).then( 
								function successCallback( response ) {
									return response.data; 
								}, function errorCallback( response ) {}
							);
						};
						scope.autocompleteContact = function($item) {
							var isfreelancer = scope.object.isfreelancer;
							scope.object = $item;
							if( scope.object.isfreelancer === undefined ) {
								scope.object.isfreelancer = isfreelancer;
							}
						};
						scope.autocompleteAteco = function($item) {
							scope.object.ateco.code = $item.code;
							scope.object.ateco.description = $item.description;
						};
						
						scope.getCities = function(val) {
							return $http.get( appURL+'/utils/cities'+( val ? '?search=' + encodeURIComponent(val) : '' ) ).then( 
								function successCallback( response ) {
									return response.data; 
								}, function errorCallback( response ) {}
							);
						};
					
						scope.autocompleteAddress=function(address) {
							$http.get( appURL+'/utils/cities?search='+encodeURIComponent(address.city), {}).then(
								function successCallback( response ) {
									var data=response.data;
									if( data.length ) {
										if( data[0].zipcode ) { address.zipcode=data[0].zipcode;}
										if( data[0].stateshort ) { address.state=data[0].stateshort;}
										if( data[0].region ) { address.region=data[0].region;}
										address.country = 'Italia';
									}
								}, function errorCallback( response ) {}
							);
						};
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.ok = function() {
							$uibModalInstance.close({object: scope.object, update: scope.updatecontacts, originalid: scope.originalid});
						};
					}],
					resolve: {
						contact: function () { return $scope.enterprise.contact; }
					}
				});
				modalInstance.result.then(
					function successCallback(data) {
						if( data.update == true ) {
							$http.post(appURL+'/crud/contacts', {'object':data.object }).then(
								function successCallback(response){							
									data.object._id = response.data;
									$scope.enterprise.contact = angular.copy(data.object);
									if( data.originalid != data.object._id  ) {
										for( var i=0;i<$scope.subcontractors.length;i++ ) {
											if( $scope.subcontractors[i].parentcontractor && $scope.subcontractors[i].parentcontractor._id ) { 
												if( $scope.subcontractors[i].parentcontractor._id == data.originalid ) {
													$scope.subcontractors[i].parentcontractor._id = data.object._id;
												}
											}
										}
									}
								}, function errorCallback(response){
								}
							);
						} else {
							$scope.enterprise.contact = angular.copy(data.object);
						}
					}, function errorCallback() {}
				);
			};
			
			$scope.resetEnterprise = function() {
				$scope.enterprise.contact = undefined;
				if( $scope.deletecallback !== undefined ) {
					$scope.deletecallback();
				}
			};
			$scope.$watch('subcontractors', function(newValue) {
				if( newValue ) {
					$scope.updateContractors();
				}
			}, true);
			$scope.updateContractors = function() {
				$scope.enterprises=[];
				if( !$scope.subcontractors ) { return;}
				var found=false;
				var tmpContractors = [];
				for( var i=0;i<$scope.subcontractors.length;i++ ) {
					if( !found && $scope.subcontractors[i].contact ) { 
						if( $scope.enterprise.contact && $scope.subcontractors[i].contact._id==$scope.enterprise.contact._id) { 
							found=true;
						} else {
							var cnt = $scope.subcontractors[i].contact;
							tmpContractors.push({_id:cnt._id, fullname:cnt.iscompany?cnt.companyname:(cnt.firstname+' '+cnt.lastname)});
						}
					}
				}
				$scope.enterprises=tmpContractors;
			};
		}
	}
}])
.directive('worksiteEnterprisesList',['$uibModal','$http','models',function($uibModal, $http, models)  {
    return {
		transclude: true,
		templateUrl: assetsURL+'components/worksite-enterprises/templates/worksite-enterprises-list.template.html',
        scope: { enterprises: '=', linkaction: '&' },
		link: function ($scope, elem, attrs) {
			$scope.openLink = function(enterprise) {
				if( $scope.linkaction && enterprise.referencedenterprise ) {
					$scope.linkaction()(enterprise.referencedenterprise._id);
				}
			};
		}
	}
}]);
