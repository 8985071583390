/* catobjectseditor directive */
/* sidebar/mainview editing interface */
angular.module('catobjectseditor',[]).directive('catobjectseditor',['$http','$routeParams','$rootScope','$uibModal', function ($http,$routeParams,$rootScope, $uibModal) {
    return {
		scope: { type: '@', sections:'=', objectclass:'@', title:'@', descriptiontitle:'@', field1title:'@', field1type: '@', field1list:'=', field1default:'@', tip:'@', userichtext:'@', sheeturl: '@',  sheetcrudurl: '@', allowfullimage:'@', pickfrom:'@'},
		restrict: 'C',
		templateUrl: assetsURL+'directives/catobjectseditor/templates/catobjectseditor.html?t='+RootSeed,
		link: function (scope, elem, attrs) {	
			scope.categories=[];
			scope.sheeturl=(scope.sheeturl===undefined?'schede':scope.sheeturl);
			scope.sheetcrudurl=(scope.sheetcrudurl===undefined?'worksheets?work':scope.sheetcrudurl);
			scope.showcategories=true;
			scope.objects=[];
			scope.descriptions=[];
			scope.sheets=[];
			scope.category={selection:-1, edit:-1, inEditObject:{}};
			scope.object={selection:-1, edit:-1, inEditObject:{}};
			scope.description={ edit:-1, inEditObject:{}};
			scope.prescription = { edit: -1, inEditObject:{}};
			scope.currentUser = $rootScope.currentUser;
			scope.defaultSection = (scope.sections && scope.sections.length) ? scope.sections[0].value : '';
			scope.loaded = false;
			scope.ImageBucket = ImageBucket;
			
			/* categories function */
			scope.selectcategory = function(index) {
				scope.loaded = false;
				scope.category.selection = index;
				if( index>=0 && index<scope.categories.length ) {
					scope.getObjects();
				}
			};
			scope.getCategories = function() {
			    $http.get(appURL+'/crud/'+scope.type+'categories').then( 
				    function successCallback(response) {
					    var data=response.data;
				    	scope.categories = data;
				    	scope.categories.forEach(function(c) {
					    	if( c.shared === undefined) {c.shared=false;}
					    	if( c.section === undefined) {c.section="";}
				    	});
						scope.category={selection:-1, edit:-1, inEditObject:{}};
				    	if( scope.categories.length ) {
					    	scope.category.selection = 0;
							scope.getObjects();
				    	}
				    }, function errorCallback(response) {
					    scope.categories = [];
						scope.category={selection:-1, edit:-1, inEditObject:{}};
					    alert("Errore temporaneo. Riprova.");
				    }
				);
			};
			scope.deleteObjectcategory = function(objectid) {
				if( confirm('Sei sicuro di voler cancellare questa categoria?')) {
			    	$http.delete(appURL+'/crud/'+scope.type+'categories'+'/'+objectid).then(
				    	function successCallback(response){
					    	scope.category.edit = -1;
							scope.getCategories();
						}, function errorCallback(response) {
							if( response.status==406 ) { // 
								alert("ATTENZIONE! Non puoi cancellare questa categoria dal momento che contiene dei rischi. Elimina prima i singoli rischi. "); 
							} else {
								alert('Errore temporaneo. Riprova.');
							}
						}
					);
				} 
			};
			scope.saveCategory = function() {
				var obj = (scope.category.edit==-1 ? {name:'Nuova categoria', color:'255,0,0', shared: false, section:scope.defaultSection} : {_id: scope.categories[scope.category.edit]._id, name: scope.category.inEditObject.name, color: scope.category.inEditObject.color, shared: scope.category.inEditObject.shared, section: scope.category.inEditObject.section} );
				
			    $http.post(appURL+'/crud/'+scope.type+'categories', {'object':obj}).then( 
				    function successCallback(response) {
					    scope.category.edit = -1;
				    	scope.getCategories();
				    }, function errorCallback( response ) {
					    alert('Errore temporaneo riprova');
				    }
				);
			};
			/* end categories functions */
			
			/* general objects functions */
			scope.resetObjectEdit = function() {
				scope.object={selection:-1, edit:-1, inEditObject:{}};
			};
			scope.getObjects = function() {
				if( scope.category.selection==-1 ) { return; }
				
			    $http.get(appURL+'/crud/'+scope.type+'?category='+scope.categories[ scope.category.selection ]._id ).then( 
				    function successCallback(response) {
					    var data=response.data;
					    scope.loaded = true;
				    	scope.objects = data;
				    	scope.objects.forEach(function(c) {
					    	if( c.shared === undefined) {c.shared=false;}
				    	});
						scope.object={selection:-1, edit:-1, inEditObject:{}};
						if( $routeParams.id ) {
							for( var j=0;j<scope.objects.length;j++) {
								if( scope.objects[j]._id == $routeParams.id ) {scope.selectObject(j); }
							}
						}
					}, function errorCallback(response) {
					    scope.loaded = true;
				    	scope.objects = [];
						scope.object={selection:-1, edit:-1, inEditObject:{}};
						alert('Errore temporaneo. Riprova.');
				    }
				);
			};
			scope.isSaveEditEnabled = function(obj) {
				return angular.equals(obj, scope.object.inEditObject);
			};
			scope.editObject = function(idx) {
				if( scope.category.selection==-1 || !scope.objects ) { return; }
				if( idx<0 || idx >= scope.objects.length ) { return; } 
				scope.object={selection:-1, edit:idx, inEditObject:angular.copy(scope.objects[idx])};
			};		
			scope.deleteObject = function(objectid) {
				if( confirm('Sei sicuro di voler cancellare questo oggetto? Cancellerai anche le descrizioni e non potrai recuperarle')) {
			    	$http.delete(appURL+'/crud/'+scope.type+'/'+objectid ).then( 
				    	function successCallback( response ) {
							scope.object={selection:-1, edit:-1, inEditObject:{}};
					    	scope.getObjects();
						}, function errorCallback(response) {
					    	alert("Errore temporaneo. Riprova.");
							scope.object={selection:-1, edit:-1, inEditObject:{}};
					    	scope.getObjects();
						}
					);
				} 
			};
			scope.cancelEditObject = function() {
				const editIdx = scope.object.edit;
				if( editIdx<0 || editIdx >= scope.objects.length ) { return; }
				scope.object.edit=-1;
				if( scope.objects[editIdx]._id === undefined ) {
					scope.objects.splice(editIdx,1);
				}
			}
			scope.saveObject = function() {
				if( scope.category.selection==-1 ) { return; }
				
				var obj = angular.copy(scope.object.inEditObject);
			    $http.post(appURL+'/crud/'+scope.type, {'object':obj}).then( 
				    function successCallback(response) {
					    scope.object.edit = -1;
				    	scope.getObjects();
				    }, function errorCallback(response) {
					    alert('Errore temporaneo. Riprova.');
				    }
				);
			};
			
			// Next two methods can be customized for different object types
			scope.addNewObject = function() {
				if( scope.category.selection==-1 || !scope.objects ) { return; }
				var obj = { name: 'Nuovo oggetto', category: scope.categories[ scope.category.selection ]._id, shared: false, account: scope.currentUser.account };
				
				/* ---- Customize for new objects ---- */
				if( scope.objectclass=='descriptionobjectitem' ) {
					obj.description = 'Nuova descrizione';
				}
				if( scope.objectclass=='imageobjectitem' ) {
					obj.description = 'Nuova descrizione';
					obj.image = undefined;
				}
				if( scope.objectclass=='costobjectitem' ) {
					obj.code = '';
					obj.unit = 'corpo';
					obj.price = 0;
					obj.decimaldigits = 0;
					obj.addDuration = false;
					obj.type = ( scope.category.selection != -1 ? scope.categories[scope.category.selection].section : 'A');
				}
				if( scope.objectclass=='prescriptionobjectitem' ) {
					obj.description = 'Nuova descrizione';
				}
				scope.objects.push(obj);
				scope.object={selection:-1, edit:scope.objects.length-1, inEditObject:angular.copy(obj)};
				/* ---- End ---- */
			};
			scope.selectObject = function(idx) {
				if( scope.category.selection==-1 || !scope.objects ) { return; }
				if( idx<0 || idx >= scope.objects.length ) { return; } 
				if( idx == scope.object.selection ) { 
					scope.object={selection:-1, edit:-1, inEditObject:{}};
					return;
				}
				scope.object={selection:idx, edit:-1, inEditObject:{}};
				/* ---- Customize for object selection ---- */
				if( scope.objectclass=="multidescriptionobjectitem" ) {
				    $http.get(appURL+'/crud/'+scope.type+'descriptions'+'?object='+scope.objects[idx]._id ).then( 
					    function successCallback(response) {
						    var data=response.data;
					    	scope.descriptions = data;
						    scope.description.edit = -1;
						    scope.prescription.edit = -1;
					    }, function errorCallback(response) {
							scope.descriptions=[];
						    scope.description.edit = -1;
						    scope.prescription.edit = -1;
						    alert("Errore temporaneo. Riprova.");
					    }
					);
			    }
			    if( scope.objectclass=="worksheetobjectitem") {
				    $http.get(appURL+'/crud/'+scope.sheetcrudurl+'='+scope.objects[idx]._id ).then( 
					    function successCallback(response) {
					    	scope.sheets = response.data;
					    }, function errorCallback(response) {
							scope.sheets=[];
						    alert("Errore temporaneo. Riprova.");
					    }
					);
			    }
				/* ---- End ---- */
			};
			/* end general objects functions */
			
			/* costobjectitem custom functions */
			scope.changeDecimalDigits = function( curObj ) {
				switch( curObj.unit ) {
					case 'corpo':
					case 'cad':
					case 'giorno':
					case 'mese':
						curObj.decimaldigits = 0;
						break;
					case 'ora':
						curObj.decimaldigits = 1;
						break;
					default:
						curObj.decimaldigits = 2;
						break;
				}	
			};
			/* */
			/* multidescriptionobjectitem custom functions */
			scope.editDescription = function(idx) {
				if( scope.category.selection==-1 || !scope.objects ) { return; }
				if( scope.object.selection==-1 || !scope.descriptions) { return; }
				if( idx<0 || idx >= scope.descriptions.length ) { return; } 
				scope.description={edit:idx, inEditObject:angular.copy(scope.descriptions[idx])};
			};
			scope.addNewDescription = function() {
				if( scope.category.selection==-1 ) { return; }
				if( scope.object.selection==-1 ) { return; }
				var obj = { name: 'Nuova descrizione', object: scope.objects[ scope.object.selection ]._id, shared: false, account: scope.currentUser.account };
				if( scope.field1title ) { obj.field1 = (scope.field1default ? scope.field1default : 'Nuovo valore');}
				scope.descriptions.push(obj);
				scope.description={edit:scope.descriptions.length-1, inEditObject:angular.copy(obj)};
			};
						
			
			scope.saveDescription = function() {
				if( scope.category.selection==-1 ) { return; }
				if( scope.object.selection==-1 ) { return; }
				
				var obj = angular.copy(scope.description.inEditObject);
			    $http.post(appURL+'/crud/'+scope.type+'descriptions', {'object':obj}).then( 
				    function successCallback(response) {
					    scope.description.edit = -1;
						scope.prescription.edit = -1;
				    	scope.getDescriptions();
				    }, function errorCallback(response) {
					    scope.description.edit = -1;
					    scope.prescription.edit = -1;
				    }
				);
			};
			scope.deleteDescription = function(objectid) {
				if( confirm('Sei sicuro di voler cancellare questa descrizione? Non potrai  recuperarla')) {
			    	$http.delete(appURL+'/crud/'+scope.type+'descriptions'+'/'+objectid).then(
				    	function successCallback(response){
						    scope.description.edit = -1;
							scope.prescription.edit = -1;
					    	scope.getDescriptions();
						}, function errorCallback(response) {
					    	alert("Errore temporaneo. Riprova.");
						    scope.description.edit = -1;
							scope.prescription.edit = -1;
					    	scope.getDescriptions();
						}
					);
				} 
			};
			scope.getDescriptions = function() {
				if( scope.category.selection==-1 ) { return; }
				if( scope.object.selection==-1 ) { return; }
			    $http.get(appURL+'/crud/'+scope.type+'descriptions'+'?object='+scope.objects[ scope.object.selection ]._id ).then( 
				    function successCallback(response) {
				    	scope.descriptions = response.data;
				    	scope.descriptions.forEach(function(c) {
					    	if( c.shared === undefined) {c.shared=false;}
				    	});
						scope.description={edit:-1, inEditObject:{}};
				    }, function errorCallback(response) {
				    	scope.descriptions = response.data;
						scope.description={edit:-1, inEditObject:{}};
					    alert("Errore temporaneo. Riprova.");
				    }
				);
			};
			
			scope.addNewPrescriptionsFromArchive = function() {
				scope.prescription.edit = -1;
				if( scope.category.selection==-1 ) { return; }
				if( scope.object.selection==-1 ) { return; }
				if( scope.description.edit == -1 ) { return; }
				if( scope.objectclass!=='multidescriptionobjectitem'  ) { return; }
				
				var modalInstance = $uibModal.open({
					templateUrl: assetsURL+'directives/catobjectseditor/templates/catobjectspickerModal.html?t='+RootSeed,
					controller: ['$scope', '$uibModalInstance','section','type','objectclass', function (scope, $uibModalInstance, section, type, objectclass ) {
						scope.data = {	objects:[], section: section, type: type, objectclass: objectclass, name : '' };
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.ok = function() {
							$uibModalInstance.close(scope.data.objects);
						};
					}],
					
					resolve: { 
						section: function () { return scope.categories[scope.category.selection].section; },
						objectclass: function () { return scope.objectclass; },
						type: function () { return scope.pickfrom; }
					}
				});
				modalInstance.result.then(
					function successCallback(objects) {
						if( objects === undefined ) { return; }
						var desc = scope.description.inEditObject;
						if( desc.prescriptions === undefined ) { desc.prescriptions = []; }
						objects.forEach(function(obj) {
							if( obj.selected === true ) {
								obj.descriptions.forEach(function(c) {
									if( c.selected == true ) {
										var descFound=false;
										for( var j=0;j<desc.prescriptions.length;j++ ) {
											if( c.name == desc.prescriptions[j] ) {
												descFound = true;
												break;
											}
										}
										if( descFound == false ) {
											desc.prescriptions.push(c.name);
										}
									}
								});
							}
						});
					}, function errorCallback() {}
				);
			};
			scope.editPrescription = function(idx) {
				if( scope.category.selection==-1 || !scope.objects ) { return; }
				if( scope.object.selection==-1 || !scope.descriptions) { return; }
				if( scope.description.edit==-1 || !scope.description.inEditObject.prescriptions) { return; }
				if( idx<0 || idx >= scope.description.inEditObject.prescriptions.length ) { return; } 
				scope.prescription={edit:idx, inEditObject:angular.copy( scope.description.inEditObject.prescriptions[idx] )};
			};
			scope.deletePrescription = function(idx) {
				if( scope.category.selection==-1 || !scope.objects ) { return; }
				if( scope.object.selection==-1 || !scope.descriptions) { return; }
				if( scope.description.edit==-1 || !scope.description.inEditObject.prescriptions) { return; }
				if( idx<0 || idx >= scope.description.inEditObject.prescriptions.length ) { return; } 
				scope.prescription.edit = -1
				scope.description.inEditObject.prescriptions.splice(idx,1);
				
			};
			scope.savePrescription = function(idx) {
				if( scope.category.selection==-1 || !scope.objects ) { return; }
				if( scope.object.selection==-1 || !scope.descriptions) { return; }
				if( scope.description.edit==-1 || !scope.description.inEditObject.prescriptions) { return; }
				if( idx<0 || idx >= scope.description.inEditObject.prescriptions.length ) { return; } 
				scope.prescription.edit = -1
				scope.description.inEditObject.prescriptions[idx]= angular.copy(scope.prescription.inEditObject);			
			};
			/* END multidescriptionobjectitem custom functions */
			scope.getCategories();
					
		}
	};
}]);	

/* catobjectsarray directive */
angular.module('catobjectsarray',[]).directive('catobjectsarray',['$uibModal','$rootScope', function ($uibModal,$rootScope) {
    return {
		scope: { objects: '=', section: '@', type: '@', item: '@', subitem: '@', attributeitem: '@', attributes: '=', objectclass: '@', disableedititem: '@', descriptiontitle:'@', field1title:'@', field1type: '@', field1list:'=', field1default:'@', userichtext:'@', allowfullimage:'@', hidenewbutton:'@', dropfield1:'=', userisklevel:'=?', useworksiteprescriptions:'=?', usepreviousworksiteprescriptions:'=?', useimages:'=?', useaddnotestocostsoptions:'=?', hideitemcolumn:'=?'},
		restrict: 'C',
		templateUrl: assetsURL+'directives/catobjectseditor/templates/catobjectsarray.html?t='+RootSeed,
		link: function (scope, elem, attrs) {	
			scope.object={edit:-1, inEditObject:{}};
			scope.userisklevel = (scope.userisklevel === undefined ? false : scope.userisklevel);
			scope.useworksiteprescriptions = (scope.useworksiteprescriptions === undefined ? false : scope.useworksiteprescriptions);
			scope.usepreviousworksiteprescriptions = (scope.usepreviousworksiteprescriptions === undefined ? false : scope.usepreviousworksiteprescriptions);
			scope.useimages = (scope.useimages === undefined ? false : scope.useimages);
			scope.useaddnotestocostsoptions = (scope.useaddnotestocostsoptions === undefined ? false : scope.useaddnotestocostsoptions);
			scope.hideitemcolumn = (scope.hideitemcolumn === undefined ? false : scope.hideitemcolumn);
			scope.currentUser = $rootScope.currentUser;
			scope.description={ pedit:-1, edit:-1, inEditObject:{}};
			scope.ImageBucket = ImageBucket;
			scope.editObject = function(idx) {
				if( scope.disableedititem === true ) { return; }
				if( idx<0 || idx >= scope.objects.length ) { return; } 
				scope.object={edit:idx, inEditObject: angular.copy(scope.objects[idx])};
			};		
			scope.deleteObject = function(idx) {
				if( scope.disableedititem === true ) { return; }
				if( confirm('Sei sicuro di voler cancellare questo oggetto?')) {
				    scope.objects.splice(idx,1);
				    scope.resetEditObject();
				} 
			};
			scope.resetEditObject = function() {
				scope.object={edit:-1, inEditObject:{}};
			};
			scope.saveObject = function(idx) {
				if( scope.disableedititem === true ) { return; }
				scope.objects[idx] = angular.copy(scope.object.inEditObject);
				/*
				if( scope.attributeitem !== undefined ) {
					scope.objects[idx].attribute=scope.object.inEditObject.attribute;
				}*/
				scope.resetEditObject();
			};
			scope.addNewObject = function() {
				if( scope.disableedititem === true ) { return; }
				var newObj = {name:'Nuovo'};
				
				/* ----- Customize object creation ---- */
				if( scope.objectclass==='multidescriptionobjectitem') {
					newObj.descriptions = [];
					if( scope.attributeitem !== undefined ) {
						newObj.attribute='';
					}
					if( scope.userisklevel ) {
						newObj.risklevel = 2;
					}
					if( scope.useworksiteprescriptions ) {
						newObj.prescriptionlevel = 0;
						newObj.prescriptionclosed = 1;
						newObj.prescriptiondeadline = new Date();
					}
					if( scope.useimages ) {
						newObj.images = [];
					}
				}
				if( scope.objectclass==='prescriptionobjectitem') {
					delete newObj.name;
					newObj.description = "Nuova descrizione";
					newObj.field1="Impresa affidataria";
				}
				/* ----- End ----- */
				scope.objects.push(newObj);
			};
			scope.addNewObjectsFromArchive = function() {
				if( scope.disableedititem === true ) { return; }
				scope.newobjects = [];
				var modalInstance = $uibModal.open({
					templateUrl: assetsURL+'directives/catobjectseditor/templates/catobjectspickerModal.html?t='+RootSeed,
					controller: ['$scope', '$uibModalInstance','section','objects','type','objectclass', 'sheetcrudurl','dropfield1','userisklevel','useworksiteprescriptions','useaddnotestocostsoptions',    function (scope, $uibModalInstance, section,objects, type, objectclass, sheetcrudurl, dropfield1, userisklevel, useworksiteprescriptions, useaddnotestocostsoptions ) {
						scope.searchtext="";
						scope.data = {	objects: [], section: section, type: type, objectclass: objectclass, sheetcrudurl: sheetcrudurl, dropfield1: dropfield1, userisklevel: userisklevel, useworksiteprescriptions: useworksiteprescriptions, useaddnotestocostsoptions: useaddnotestocostsoptions };
						
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.ok = function() {
							$uibModalInstance.close(scope.data.objects);
						};
					}],
					
					resolve: { 
						section: function () { return scope.section; },
						objects: function () { return scope.newobjects; },
						objectclass: function () { return scope.objectclass; },
						sheetcrudurl: function () { return scope.sheetcrudurl; },
						type: function () { return scope.type; },
						dropfield1: function () { return scope.dropfield1; },
						userisklevel: function () { return scope.userisklevel; },
						useworksiteprescriptions: function () { return scope.useworksiteprescriptions; },
						useaddnotestocostsoptions: function () { return scope.useaddnotestocostsoptions; }
					}
				});
				modalInstance.result.then(
					function successCallback(objects) {
						if( objects === undefined ) { return; }
						objects.forEach(function(c) {
							if( c.selected === true ) {
								var newObject = {name:c.name};
								/* ----- Customize object creation ---- */
								if( scope.objectclass==='multidescriptionobjectitem'  ) {
									newObject.descriptions=[];
									if( scope.attributeitem !== undefined ) {
										newObject.attribute='';
									}
									if( scope.userisklevel ) {
										newObject.risklevel=c.risklevel;
									}
									if( scope.useworksiteprescriptions ) {
										newObject.prescriptionlevel = c.prescriptionlevel;
										newObject.prescriptionclosed = c.prescriptionclosed;
										newObject.prescriptiondeadline = (c.prescriptiondeadline ? c.prescriptiondeadline : new Date());
									}
									if( scope.useimages ) {
										newObject.images=[];
									}
									c.descriptions.forEach(function(d) {
										if( d.selected === true ) {
											var newDesc = {name:d.name};
											if( d.field1 ) { 
												if( scope.field1default && scope.field1default.length ) {
													newDesc.field1 = scope.field1default;
												} else {
													newDesc.field1 = d.field1;
												}
											}
											if( d.addnotetocosts !== undefined ) { 
												newDesc.addnotetocosts = d.addnotetocosts;
											}
											newObject.descriptions.push(newDesc);
										}
									});
								}
								if( scope.objectclass==='descriptionobjectitem') {
									newObject.description=c.description;
								}
								if( scope.objectclass==='imageobjectitem') {
									newObject.image=c.image;
									newObject.description=c.description;
								}
								if( scope.objectclass==='worksheetobjectitem') {
									newObject = angular.copy(c);
									if( newObject._id ) { delete newObject._id; }
								}
								if( scope.objectclass==='prescriptionobjectitem') {
									delete newObject.name;
									newObject.description=c.description;
									newObject.field1="Impresa affidataria";
								}
								/* ----- End ----- */
								var found=false;
								for(var i=0; i<scope.objects.length;i++ ) {
									if( scope.objectclass==='prescriptionobjectitem' ) {
										if(scope.objects[i].description == newObject.description ) {
											found = true;
										}
									} else if(scope.objects[i].name == newObject.name ) {
										if( scope.objectclass==='multidescriptionobjectitem' ) {
											var descFound=false;
											var cObj = scope.objects[i];
											newObject.descriptions.forEach(function(dsc) {
												for( var j=0;j<cObj.descriptions.length;j++ ) {
													if( cObj.descriptions[j].name == dsc.name ) {
														descFound = true;
														break;
													}
												}
												if( descFound == false ) {
													cObj.descriptions.push(dsc);
												}
											});
										}
										found = true;
									}
								}
								if( found==false ) {
									scope.objects.push(newObject);
								}
								if( scope.objectclass==='prescriptionobjectitem' ) {
									scope.objects.sort(function(a, b) {
										var nameA=a.description, nameB=b.description;
										return (nameA < nameB) ? -1 : ((nameA > nameB) ? 1 : 0);
									});
								} else {
									scope.objects.sort(function(a, b) {
										var nameA=a.name, nameB=b.name;
										return (nameA < nameB) ? -1 : ((nameA > nameB) ? 1 : 0);
									});
								}
							}
						});
					}, function errorCallback() {}
				);
			};
			
			scope.addNewDescriptionsFromArchive = function(object) {
				if( !object ) { return; }
				var modalInstance = $uibModal.open({
					templateUrl: assetsURL+'directives/catobjectseditor/templates/catobjectsdescriptionspickerModal.html?t='+RootSeed,
					controller: ['$scope', '$uibModalInstance','section','type','objectclass','name','dropfield1','useaddnotestocostsoptions',  function (scope, $uibModalInstance, section, type, objectclass, name,dropfield1, useaddnotestocostsoptions ) {
						scope.data = {	descriptions:[], section: section, type: type, objectclass: objectclass, name : name, dropfield1 : dropfield1, useaddnotestocostsoptions: useaddnotestocostsoptions };
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.ok = function() {
							$uibModalInstance.close(scope.data.descriptions);
						};
					}],
					
					resolve: { 
						section: function () { return scope.section; },
						objectclass: function () { return scope.objectclass; },
						name: function () { return object.name; },
						type: function () { return scope.type; },
						dropfield1: function () { return scope.dropfield1; },
						useaddnotestocostsoptions: function () { return scope.useaddnotestocostsoptions; }
					}
				});
				modalInstance.result.then(
					function successCallback(descriptions) {
						if( descriptions === undefined ) { return; }
						if( scope.objectclass!=='multidescriptionobjectitem'  ) { return; }
						descriptions.forEach(function(c) {
							if( c.selected === true ) {
								var newDesc = {name:c.name};
								if( c.field1 || scope.field1default ) { 
									if( scope.field1default && scope.field1default.length ) {
										newDesc.field1 = scope.field1default;
									} else {
										newDesc.field1 = c.field1;
									}
								}
								if( c.addnotetocosts !== undefined ) { 
									newDesc.addnotetocosts = c.addnotetocosts;
								}
								var descFound=false;
								for( var j=0;j<object.descriptions.length;j++ ) {
									if( newDesc.name == object.descriptions[j].name ) {
										descFound = true;
										break;
									}
								}
								if( descFound == false ) {
									object.descriptions.push(newDesc);
								}
							}
						});
					}, function errorCallback() {}
				);
			};
			
			// multidescriptionobjectitem part 
			scope.resetEditDescription = function() {
				scope.description={ pedit:-1, edit:-1, inEditObject:{}};
			};
			scope.addNewDescription = function(idx) {
				if( idx<0 || idx >= scope.objects.length ) { return; } 
				var newDesc = {name:'Nuova descrizione'};
				if( scope.field1title ) { 
					newDesc.field1 = (scope.field1default?scope.field1default:"Nuovo valore");
				}
				if( scope.useaddnotestocostsoptions ) { 
					newDesc.addnotetocosts = false;
				}
				scope.objects[idx].descriptions.push(newDesc);
				scope.description={pedit: idx, edit:scope.objects[idx].descriptions.length-1, inEditObject:angular.copy(newDesc)};
			};
			
			scope.editDescription = function(pidx, idx) {
				if( pidx<0 || pidx >= scope.objects.length ) { return; } 
				if( idx<0 || idx >= scope.objects[pidx].descriptions.length ) { return; } 
				var newDesc = {name:scope.objects[pidx].descriptions[idx].name};
				if( scope.field1title ) { newDesc.field1 = (scope.objects[pidx].descriptions[idx].field1 ? scope.objects[pidx].descriptions[idx].field1 : "Nuovo valore");}
				if( scope.useaddnotestocostsoptions ) { newDesc.addnotetocosts = (scope.objects[pidx].descriptions[idx].addnotetocosts ? scope.objects[pidx].descriptions[idx].addnotetocosts : false);}
				scope.description={pedit: pidx, edit:idx, inEditObject:newDesc};
			};
			scope.deleteDescription = function() {
				var pidx = scope.description.pedit;
				var idx = scope.description.edit;
				if( pidx<0 || pidx >= scope.objects.length ) { return; } 
				if( idx<0 || idx >= scope.objects[pidx].descriptions.length ) { return; } 
				if( confirm('Sei sicuro di voler cancellare questa descrizione? Non potrai  recuperarla')) {
				    scope.resetEditDescription();
				    scope.objects[pidx].descriptions.splice(idx,1);
				} 
			};
			scope.saveDescription = function() {
				var pidx = scope.description.pedit;
				var idx = scope.description.edit;
				if( pidx<0 || pidx >= scope.objects.length ) { return; } 
				if( idx<0 || idx >= scope.objects[pidx].descriptions.length ) { return; } 
				scope.objects[pidx].descriptions[idx] = angular.copy(scope.description.inEditObject);
				scope.resetEditDescription();
			};
		}
	};
}]);

/* catobjectslist directive */
angular.module('catobjectslist',[]).directive('catobjectslist',[function () {
    return {
		scope: { objects: '=', item: '@', subitem: '@',userichtext:'@'},
		restrict: 'C',
		templateUrl: assetsURL+'directives/catobjectseditor/templates/catobjectslist.html?t='+RootSeed,
		link: function (scope, elem, attrs) {	
		}
	};
}]);

/* catobjectspicker directive */
angular.module('catobjectspicker',[]).directive('catobjectspicker', ['$http', function ($http) {
    return {
		scope: {  type: '@',  section: '@', objects: '=', objectclass: '@', sheetcrudurl: '@', filter:'=', dropfield1:'=', userisklevel:'=', useworksiteprescriptions:'=', useaddnotestocostsoptions:'='},
		restrict: 'C',
		templateUrl: assetsURL+'directives/catobjectseditor/templates/catobjectspicker.html?t='+RootSeed,
		link: function (scope, elem, attrs) {	
			scope.objects=[];
			scope.loaded= false;
			scope.categories = [];
			scope.catKeys = {};
			scope.sheetcrudurl=(scope.sheetcrudurl===undefined?'worksheets?work':scope.sheetcrudurl);
			scope.ImageBucket = ImageBucket;
			/*scope.$watch('filter', function(newVal) {
				scope.refreshData();
			});*/
			scope.togglecategory = function(category) {
				if( category !== undefined ) {
					category.open = !category.open;
				}
			}
		    scope.refreshData = function() {
				$http.get(appURL+'/crud/'+scope.type+'categories').then( 
					function successCallback(response) {
						var data=response.data;
						scope.loaded = true;
						if( data.length == 0 ) {
							alert("Nessuna categoria trovata");
							return;
						}
						
						scope.categories = data;
						var idx = 0;
						for( var idx=0;idx<scope.categories.length;idx++ ) {
							var c = scope.categories[idx];
							c.open = false;
							c.objects = [];
							scope.catKeys[c._id]=idx;
						}
						//scope.categories[0].open = true;
					    $http.post(appURL+'/crud/'+scope.type+'/categorysection/'+scope.section).then( 
						    function successCallback(response) {
							    var data=response.data;
							    scope.loaded = true;
						    	scope.objects = data;
						    	scope.objects.forEach(function(c) {
							    	c.selected = false;
							    	var catIndex = scope.catKeys[c.category];
							    	if( catIndex !== undefined ) {
								    	scope.categories[catIndex].objects.push(c);
							    	}
									/* ---- CUSTOMIZE objectclass type if necessary ---- */
									if( scope.objectclass === 'multidescriptionobjectitem' ) {
							    		c.descriptions=[];
							    		if( scope.userisklevel ) {
								    		c.risklevel = 2;
							    		}
							    		if( scope.useworksiteprescriptions ) {
								    		c.prescriptionlevel = 0;
								    		c.prescriptionclosed = 1;
								    		c.prescriptiondeadline = new Date();
							    		}
							    	}
									if( scope.objectclass === 'worksheetobjectitem' ) {
							    		c.sheets=[];
							    	}
									/* ---- END CUSTOMIZE ---- */
						    	});
						    	
								/* ---- CUSTOMIZE objectclass type if necessary ---- */
						    	for( var i=0;i<scope.objects.length; i++ ) {
							    	scope.$watch('objects['+i+']', function(newValue, oldValue) {
								    	if( newValue === undefined) { return; }
										/* ---- CUSTOMIZE objectclass type if necessary ---- */
										if( scope.objectclass === 'multidescriptionobjectitem' ) {
									    	if( newValue.selected != oldValue.selected ) {
										    	if( newValue.selected == true ) {
											    	newValue.loading=true;
											    	var params = {object : newValue._id};
											    	if( scope.filter && scope.filter.length ) {
												    	params.search = scope.filter;
											    	}
													$http.get(appURL+'/crud/'+scope.type+'descriptions', {params : params}).then( 
														function successCallback(response) {
															var data=response.data;
															newValue.descriptions = data;
															newValue.descriptions.forEach(function(c) {
																if( scope.dropfield1 ) { delete c.field1; }
																if( scope.useaddnotestocostsoptions ) { c.addnotetocosts = c.addnotetocosts === undefined ? false : c.addnotetocosts; }
																c.selected = false;
															});
															newValue.loading=false;
														}, function errorCallback(response) {
															newValue.descriptions = [];
															newValue.loading=false;
															alert("Errore temporaneo. Riprova.");
														}
													);
												} else {
													newValue.descriptions.forEach(function(c) {
														c.selected = false;
													});
												}
									    	}
								    	}
								    	
										if( scope.objectclass === 'worksheetobjectitem' ) {
									    	if( newValue.selected != oldValue.selected ) {
										    	if( newValue.selected == true ) {
											    	newValue.loading=true;
											    	var params = {object : newValue._id};
											    	if( scope.filter && scope.filter.length ) {
												    	params.search = scope.filter;
											    	}
													$http.get(appURL+'/crud/'+scope.sheetcrudurl+'='+newValue._id+'&fulldata=true').then( 
														function successCallback(response) {
															newValue.sheets = response.data;
															newValue.sheets.forEach(function(c) {
																c.selected = false;
															});
															newValue.loading=false;
														}, function errorCallback(response) {
															newValue.sheets = [];
															newValue.loading=false;
															alert("Errore temporaneo. Riprova.");
														}
													);
												} else {
													newValue.sheets.forEach(function(c) {
														c.selected = false;
													});
												}
									    	}
								    	}
										/* ---- END CUSTOMIZE ---- */
							    	}, true);
						    	}
							},  function errorCallback(response) {
								scope.objects = [];
							    scope.loaded = true;
						    	alert("Errore temporaneo. Riprova.");
							}
						);
					}, function errorCallback(response) {
					    scope.loaded = true;
				    	alert("Errore temporaneo. Riprova.");
					}
				);
			}
		    
		    scope.refreshData();
		}
	}
}]);
/* catobjectspicker directive */
angular.module('catobjectsdescriptionspicker',[]).directive('catobjectsdescriptionspicker', ['$http', function ($http) {
    return {
		scope: {  type: '@',  section: '@', descriptions:'=', objectclass: '@', name:'@', dropfield1:'=', useaddnotestocostsoptions:'=' },
		restrict: 'C',
		templateUrl: assetsURL+'directives/catobjectseditor/templates/catobjectsdescriptionspicker.html?t='+RootSeed,
		link: function (scope, elem, attrs) {	
			scope.descriptions=[];
			scope.loaded= false;
			scope.ImageBucket = ImageBucket;
		    scope.refreshData = function() {
			    $http.post(appURL+'/crud/'+scope.type+'/categorysection/'+scope.section, {name: scope.name}).then( 
				    function successCallback(response) {
					    var data=response.data;
				    	if( !data.length ) { 
					    	scope.loaded=true;
					    	return; 
					    }
						var params = {object : data[0]._id};
						$http.get(appURL+'/crud/'+scope.type+'descriptions', {params : params}).then( 
							function successCallback(response) {
								var data=response.data;
								scope.descriptions = data;
								scope.descriptions.forEach(function(c) {
									if( scope.dropfield1 ) { delete c.field1; }
									if( scope.useaddnotestocostsoptions ) { c.addnotetocosts = c.addnotetocosts === undefined ? false : c.addnotetocosts; }
									c.selected = false;
								});
								scope.loaded=true;
							}, function errorCallback(response) {
								scope.descriptions = [];
								scope.loaded=true;
								alert("Errore temporaneo. Riprova.");
							}
						);
					}, function errorCallback(response) {
						scope.descriptions = [];
					    scope.loaded = true;
				    	alert("Errore temporaneo. Riprova.");
					}
				);
			    return;
			}
		    scope.refreshData();
		}
	}
}]);

