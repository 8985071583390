angular.module('worksiteUsers', [])
.directive('worksiteUser', ['$rootScope', '$uibModal','$timeout','$http','ngClipboard', function ($rootScope, $uibModal, $timeout, $http, ngClipboard) {
    return {
		scope: { user : '=',  contact : '=', enterprise: '=?', worksite: '=', toggledetailaction: '&', index: '@', selectedindex: '=', saveaction: '&'  },
		restrict: 'C',
		templateUrl: assetsURL+'components/worksite-users/templates/worksite-user.template.html',
		link: function (scope, elem, attrs) {
			scope.updating = false;
			scope.link = null;
			scope.toggleShowDetail = function() {
				if( scope.toggledetailaction ) {
					scope.toggledetailaction()(scope.index);
				}
			};
			scope.$watch('user.sharecode', function(val) {
				if( val === undefined ) { 
					scope.link = null;
					return; 
				}
				scope.link = worksiteURL+"#/login/"+scope.worksite.shareCode+"/"+scope.user.sharecode;
			}, true);
			scope.copyToClipboard = function() {
				ngClipboard.toClipboard(scope.link);
			}
			scope.activateUser = function() {
				var modalInstance = $uibModal.open({
					templateUrl: assetsURL+'components/worksite-users/templates/worksite-user-activateModal.html',
					size: 'lg',
					controller: ['$scope', '$uibModalInstance', 'user', 'contact', 'enterprise','worksite','saveaction',  function (scope, $uibModalInstance, user, contact, enterprise, worksite, saveaction ) {
						scope.loading = enterprise !== undefined ? true : false;
						scope.data = { worksiteid: worksite._id, fullname:"", customText:"", emails: [], activationmode: 'ext_create', foundenterprises:{}, sendemail:true };
						if( worksite.messagesender ) { scope.data.fullname = worksite.messagesender; }
						scope.curstep = 0;
						scope.user = user;
						scope.contact = contact;
						scope.enterprise = enterprise;
						scope.data.contact = contact;
						scope.data.emails = JSON.parse(JSON.stringify(contact.emails));
						scope.data.level = enterprise ? 2 : 1;
						scope.$watch('data.activationmode', function(val) {
							if( val === undefined ) { return; }
							scope.data.customText = "Il cantiere '"+worksite.subject+"' a "+worksite.place.city+" utilizza la piattaforma Sicurezza Semplice Online per condividere documenti, avvisi e verbali. Il tecnico incaricato potrà validare i documenti ricevuti e chiedere eventuali integrazioni velocemente ed in totale rispetto della privacy. ";
							if( scope.activationmode == 'ext_share' ) {
								scope.data.customText += "Congratulazioni! Stai già usando i servizi di Sicurezza Semplice Online. Segui le istruzioni per condividere i documenti del tuo archivio ed accedere a tutta la documentazione di cantiere messa a disposizione dal tecnico.";
							}
							if( scope.activationmode == 'ext_create' ) {
								scope.data.customText += "E' la prima volta che usi Sicurezza Semplice Online ma non preoccuparti! In pochi semplici passi potrai creare il tuo profilo personale, caricare i documenti richiesti e condividerli con il tecnico. Segui queste semplici istruzioni.";
							}
							if( scope.activationmode == 'int_create' || scope.activationmode == 'int_share' ) {
								scope.data.customText += "Per questo cantiere il tecnico provvederà a caricare direttamente i documenti che gli fornirai. Potrai comunque accedere alla piattaforma per visionare la documentazione messa a disposizione.";
							}
						});
						scope.goForward = function() { 
							scope.curstep = 1;
						};
						scope.goBack = function() { 
							scope.curstep = 0;
						};
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.ok = function() {
							scope.loading = true;
							$http.post(appURL+'/services/worksites-users/activate', {object: scope.data} ).then( 
								function successCallback(response) {
									var result = response.data;
									user.level = scope.data.level;
									user.activationdate = new Date();
									user.deactivationdate = null;
									user.sharecode = result.sharecode;
									switch( scope.data.activationmode ) {
										case 'ext_share':
											enterprise.referencedenterprise._id = scope.data.foundenterprises.external._id;
											enterprise.referencedenterprise.account = scope.data.foundenterprises.external.account;
											enterprise.referencedenterprise.status = 1;
											user.enterpriseid = scope.data.foundenterprises.external._id;
											user.enterpriseaccount = scope.data.foundenterprises.external.account;
											user.status = 1;
											break;
										case 'ext_create':
											enterprise.referencedenterprise._id = result.enterpriseid;
											enterprise.referencedenterprise.account = result.accountid;
											enterprise.referencedenterprise.status = 1;
											user.enterpriseid = result.enterpriseid;
											user.enterpriseaccount = result.accountid;
											user.status = 1;
											break;
										case 'int_share':
											enterprise.referencedenterprise._id = scope.data.foundenterprises.external._id;
											enterprise.referencedenterprise.account = null;
											enterprise.referencedenterprise.status = 2;
											user.enterpriseid = scope.data.foundenterprises.external._id;
											user.status = 2;
											break;
										case 'int_create':
											enterprise.referencedenterprise._id = result.enterpriseid;
											enterprise.referencedenterprise.account = null;
											enterprise.referencedenterprise.status = 2;
											user.enterpriseid = result.enterpriseid;
											user.status = 2;
											break;
										default:
											user.status = 2;
											break;
									}
									var successCallback = function() {
										scope.loading = false;
										$uibModalInstance.close();
									};
									
									var errorCallback = function() {
										scope.loading = false;
										$uibModalInstance.dismiss('cancel');
										alert("Attenzione! Non è stato possibile salvare le modifiche. Riprova pi&ugrave; tardi")
									};
									saveaction()(successCallback, errorCallback);
								}, function errorCallback(response) {
									scope.loading = false;
									$uibModalInstance.dismiss('cancel');
									alert("Attenzione! Non è stato possibile inviare l'invito. Riprova pi&ugrave; tardi")
								}
							);
						};
						
						if( enterprise ) {
							if( contact.fiscalid && contact.fiscalid.length ) {
								$http.post(appURL+'/services/worksites-users/retrieveenterprise', {fiscalid:contact.fiscalid} ).then( 
									function successCallback(response) {
										scope.data.foundenterprises = response.data;
										if( scope.data.foundenterprises.external ) {
											scope.data.activationmode = "ext_share";
										}
										scope.loading = false;
									}, function errorCallback(response) {
										scope.loading = false;
									}
								);
							} else {
								scope.loading = false;
								scope.data.activationmode = "ext_create";
							}
						} else {
							scope.data.activationmode = "";
						}
					}],
					resolve: {
						user: function () { return scope.user; },
						contact: function () { return scope.contact.contact; },
						enterprise: function () { return scope.enterprise; },
						worksite: function () { return scope.worksite; },
						saveaction: function () { return scope.saveaction; }
					}
				});
				modalInstance.result.then(
					function successCallback(data) {
					}, function errorCallback() {
					}
				);
			};
			scope.$watchGroup(['index', 'selectedindex'], function(val) {
				if( val === undefined ) { return; }
				scope.showdetail = (scope.index == scope.selectedindex);
			});

			scope.deactivateUser = function() {
				var modalInstance = $uibModal.open({
					templateUrl: assetsURL+'components/worksite-users/templates/worksite-user-deactivateModal.html',
					size: 'lg',
					controller: ['$scope', '$uibModalInstance', 'user', 'contact','enterprise', 'saveaction',  function (scope, $uibModalInstance, user, contact, enterprise, saveaction ) {
						scope.loading = false;
						scope.contact = contact;
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
						scope.ok = function() {
							user.deactivationdate = new Date();
							if( enterprise && enterprise.referencedenterprise ) {
								enterprise.referencedenterprise.status = 0;
							}
							user.status = 0;	
							user.sharecode = null;
							scope.loading = true;
							var successCallback = function() {
								scope.loading = false;
								$uibModalInstance.close();
							};
							
							var errorCallback = function() {
								scope.loading = false;
								$uibModalInstance.dismiss('cancel');
								alert("Attenzione! Non è stato possibile salvare le modifiche. Riprova pi&ugrave; tardi")
							};
							saveaction()(successCallback, errorCallback);
						};
					}],
					resolve: {
						user: function () { return scope.user; },
						contact: function () { return scope.contact.contact; },
						enterprise: function () { return scope.enterprise; },
						saveaction: function () { return scope.saveaction; }
					}
				});
				modalInstance.result.then(
					function successCallback(data) {
					}, function errorCallback() {
					}
				);
			};
		}
	};
}]).directive('worksiteUsers', ['$timeout', '$http','$uibModal', 'warningService','models', function ($timeout, $http, $uibModal, warningService, models) {
    return {
		scope: { users: '=', contacts:'=', enterprises:'=?', worksite: '=', saveaction: '&' },
		restrict: 'C',
		templateUrl: assetsURL+'components/worksite-users/templates/worksite-users.template.html',
		link: function (scope, elem, attrs) {
			scope.selectedindex = -1;
			scope.toggleDetailView = function(index) {
				scope.selectedindex = scope.selectedindex == index ? -1 : index;
			};
			scope.asyncSaveAction = function(successCallback, errorCallback) {
				if( scope.saveaction ) {
					scope.saveaction()(successCallback, errorCallback);
				}
			}
		}
	};
}]);
