angular.module('worksiteDocuments', [])
.directive('worksiteDocument', ['$timeout', function ($timeout) {
    return {
		scope: { worksitedoc : '=',  canedit:'=', editaction:'&', deleteaction:'&', index: '@'  },
		restrict: 'C',
		templateUrl: assetsURL+'components/worksite-documents/templates/worksite-document.template.html',
		link: function (scope, elem, attrs) {
			scope.downloadDoc = function() {
	            $timeout(function () {
	                var a = document.createElement('a');
	                document.body.appendChild(a);
	                a.download = scope.worksitedoc.filename;
	                a.href = appURL+"/services/worksites-docs/download/"+scope.worksitedoc.fileId;
	                a.click();
	                document.body.removeChild(a);
	            }, 200);
			}
			scope.deleteDoc = function() {
				if( scope.canedit ) {
					scope.deleteaction()(scope.index); 
				}
			};
			scope.editDoc = function() {
				if( scope.canedit ) {
					scope.editaction()(scope.worksitedoc); 
				}
			};
		}
	};
}]).directive('worksiteDocuments', ['$timeout', '$http','$uibModal', 'warningService','models', function ($timeout, $http, $uibModal, warningService, models) {
    return {
		scope: { worksitedocsection: '=', sharedpscdocs: '=', saveaction: '&', job: '=' },
		restrict: 'C',
		templateUrl: assetsURL+'components/worksite-documents/templates/worksite-documents.template.html',
		link: function (scope, elem, attrs) {
			
			scope.typeshareddocs = [];
			scope.updateAvalaibleTypes = function() {
				scope.typeshareddocs = [];
				scope.sharedpscdocs.forEach(function(sharedDoc) {
					if( scope.worksitedocsection.doctypes.indexOf(sharedDoc.type) != -1 ) {
						var available = true;
						scope.worksitedocsection.docs.forEach(function(doc) {
							if( sharedDoc.type == doc.type && doc.issuedate && sharedDoc.lastpdfdate == doc.issuedate.toISOString() ) {
								available = false;
							}
						});
						if( available ) {
							scope.typeshareddocs.push(sharedDoc);
						}
					}
				});
			};
			
			scope.$watch('sharedpscdocs', function(val) {
				if( val === undefined ) { return; }
				scope.updateAvalaibleTypes();
			}, true );
			
			scope.$watchCollection('worksitedocsection.docs', function(val) {
				if( val === undefined ) { return; }
				scope.updateAvalaibleTypes();
			}, true );

			scope.linkWorksiteDoc = function(type) { 
				scope.editWorksiteDoc(null, null);
			};

			scope.addWorksiteDoc = function(event) { 
				$timeout( function() { (elem.find("input:file"))[0].click(); },0 ); 
			};
			
			scope.editWorksiteDoc = function(doc, file) {
				var modalInstance = $uibModal.open({
					templateUrl: assetsURL+'components/worksite-documents/templates/worksite-document-editModal.html',
					controller: ['$scope', '$uibModalInstance', 'doc','file','job', 'filteredshareddocs','worksitedocsection', 'doctypes', 'saveaction',  function (scope, $uibModalInstance, doc, file, job, filteredshareddocs, worksitedocsection, doctypes, saveaction) {
						scope.progress = 1;
						scope.uploading=false;
						scope.xhr = null;
						scope.uploadresponse = {};
						
						scope.data = {};
						scope.isNew = (doc ? false : true);
						scope.isSharedDoc = ((doc && doc.fileId === undefined) || (!doc && !file));
						scope.specifyissuedate = true;
						scope.filteredshareddocs = filteredshareddocs;
						scope.normalizeFilename = function(filename) {
							var newFilename = "";
							for( var i=0; i<filename.length; i++ ) {
								if( filename[i]=="(") {
									break;
								}
								newFilename += filename[i];
							}
							newFilename = newFilename.trim().split(" ").join("_");
							return (job ? job+"_":"")+newFilename;
						};
						if( scope.isNew ) {
							if( scope.isSharedDoc ) {
								scope.data.selectedSharedDoc = scope.filteredshareddocs[0];
								scope.data.description = scope.normalizeFilename(scope.data.selectedSharedDoc.description);
								scope.data.issuedate = new Date(scope.data.selectedSharedDoc.lastpdfdate);
								scope.data.revision = scope.data.selectedSharedDoc.revision;
								scope.$watch('data.selectedSharedDoc', function(val) {
									if( val === undefined ) { return; }
									scope.data.description = scope.normalizeFilename(scope.data.selectedSharedDoc.description);
								});
							} else {
								scope.data.file = file;
								scope.data.description = scope.normalizeFilename(file.name);
								scope.data.issuedate = new Date();
								scope.data.revision = 0;
							}
							scope.data.notes = "";
						} else {
							if( !scope.isSharedDoc ) {
								scope.data.description = doc.description;
								scope.data.issuedate = doc.issuedate ?  new Date(doc.issuedate) : new Date();
								scope.data.revision = doc.revision;
							}
							scope.data.notes = doc.notes;
						}
						
						scope.createCORSRequest = function() {
							var xhr = new XMLHttpRequest();
							if ("withCredentials" in xhr) {
								return xhr;
							} else if (typeof XDomainRequest != "undefined") {
								xhr = new XDomainRequest();
								return xhr;
							} 
							return null;
						};
						
						scope.cancelUpload = function(errorText) {
							$http.post(appURL+'/services/worksites-docs/delete', { id: scope.uploadresponse.id } ).then( 
								function successCallback(data) {
									scope.uploading=false;
									scope.xhr = null;
									scope.uploadresponse = {};
									alert(errorText);
								}, function errorCallback(response) {
									scope.uploading=false;
									scope.xhr = null;
									scope.uploadresponse = {};
									alert(errorText);
								}
							);
						}
						scope.abortUpload = function() {
							if( scope.xhr ) {
								scope.xhr.abort();
								scope.cancelUpload("Operazione interrotta");
							}
						};
						
						scope.uploadProgress = function(evt) {
							if (evt.lengthComputable) {
								scope.$apply(function () {
									scope.progress = Math.round(evt.loaded * 100 / scope.uploadresponse.totalsize );
								});
							}
						};
						scope.uploadComplete = function(evt) {
							scope.$apply( function() {
								if( scope.xhr.status !=200 ) {
									scope.cancelUpload("Errore generico di caricamento. Riprova.");
								} else {
									scope.uploadresponse.doc.fileId = scope.uploadresponse.id;
									worksitedocsection.docs.push(scope.uploadresponse.doc);
									scope.progress = 100;
									scope.error = undefined;
									scope.uploadresponse = {};
									
									var successCallback = function() {
										scope.uploading = false;
										$uibModalInstance.close(scope.data);
									};
									
									var errorCallback = function() {
										scope.uploading = false;
										$uibModalInstance.dismiss('cancel');
										alert("Attenzione! Non è stato possibile salvare le modifiche. Riprova pi&ugrave; tardi")
									};
									saveaction()(successCallback, errorCallback);	
								}
							});
						};
						
						scope.uploadFailed = function(evt) {
							scope.$apply( function() {
								scope.cancelUpload("Errore generico di caricamento. Riprova.");
							});
						};
			
						scope.gcpFilePoster = function(signedurl, filetype, data) {
							var gcpxhr = scope.createCORSRequest();
							gcpxhr.upload.addEventListener("progress", scope.uploadProgress, false);
							gcpxhr.addEventListener("load", scope.uploadComplete, false);
							gcpxhr.addEventListener("error", scope.uploadFailed, false);
							gcpxhr.open('PUT', signedurl, true);
							gcpxhr.setRequestHeader('Content-Type', filetype);
							scope.xhr = gcpxhr;
							gcpxhr.send(data);
						};
						
						scope.uploadFileData = function( file, doc ) {
							$http.post(appURL+'/services/worksites-docs/file', { filetype:file.type, name: file.name } ).then( 
								function successCallback(response) {
									scope.uploadresponse.id = response.data._id;
									scope.uploadresponse.totalsize = file.size;
									scope.uploadresponse.doc = doc;
									var reader = new FileReader();
									reader.onloadend = function() {
										scope.gcpFilePoster(response.data.signedurl, file.type, reader.result );
									}
									reader.readAsArrayBuffer(file);
								}, function errorCallback(response) {
									scope.cancelUpload("Errore generico di caricamento. Riprova.");
								}
							);
						};
						scope.ok = function () {
							scope.uploading = true;
							var successSaveCallback = function() {
								scope.uploading = false;
								$uibModalInstance.close(null);
							};
							
							var errorSaveCallback = function() {
								scope.uploading = false;
								$uibModalInstance.dismiss('cancel');
								alert("Attenzione! Non è stato possibile salvare le modifiche. Riprova pi&ugrave; tardi")
							};
							
							var data = scope.data;
							if( doc ) {
								if( doc.fileId ) {
									doc.description = data.description;
									doc.issuedate = data.issuedate;
									doc.revision = data.revision;
								}
								doc.notes = data.notes;
								saveaction()(successSaveCallback, errorSaveCallback);
							} else {
								if( data.file ) {
									var re = /(?:\.([^.]+))?$/;
									var ext = re.exec(data.file.name)[1]; 
									var filename = (data.description.length ? data.description+"."+ext : data.file.name);
									var newDoc = new models.WorksiteDocument( data.description, data.issuedate, data.revision, data.notes, filename, doctypes[0]);
									scope.uploadFileData( data.file, newDoc );
								} else {
									var filename = (data.description.length ? data.description+".pdf" : "Senza titolo.pdf");
									$http.post(appURL+'/services/worksites-docs/copy',{pdf:data.selectedSharedDoc.pdf, type:'pscs', filename:filename}).then(
										function successCallback(response) {
											if( response.data && response.data._id ) {
												var newDoc = new models.WorksiteDocument( data.description, data.issuedate, data.revision, data.notes, filename, data.selectedSharedDoc.type);
												newDoc.fileId = response.data._id;
												worksitedocsection.docs.push(newDoc);
												saveaction()(successSaveCallback, errorSaveCallback);
											} else {
												$uibModalInstance.dismiss('cancel');
												alert('Si è verificato un errore. Si prega di riprovare');
											}
										}, function errorCallback(response) {
											$uibModalInstance.dismiss('cancel');
											alert('Si è verificato un errore. Si prega di riprovare');
										}
									);
								}
							}
						};
						
						scope.cancel = function () {
							$uibModalInstance.dismiss('cancel');
						};
					}],
					resolve: { 
						doc: function() { return doc; }, 
						file: function() { return file; },
						job: function() { return scope.job; },
						filteredshareddocs: function() { return scope.typeshareddocs; },
						worksitedocsection: function() { return scope.worksitedocsection; },
						doctypes: function() { return scope.worksitedocsection.doctypes; },
						saveaction: function () { return scope.saveaction; }
					}
				});
				modalInstance.result.then(
					function successCallback(data) {
						elem.find("input:file").val(null);
					}, function errorCallback() {
						elem.find("input:file").val(null);
					}
				);
			};

			scope.deleteWorksiteDoc = function(index) {
				if( index >= 0 && index < scope.worksitedocsection.docs.length ) {
					var deletingDoc = scope.worksitedocsection.docs[index];
					var confirmDeleteDoc = function() {
						deletingDoc.deleting = true;
						$http.post(appURL+'/services/worksites-docs/delete',{id:deletingDoc.fileId}).then(
							function successCallback(response) {
								scope.worksitedocsection.docs.splice(index,1);
								var successSaveCallback = function() {
									deletingDoc.deleting = false;
								};
								
								var errorSaveCallback = function() {
									deletingDoc.deleting = false;
									alert("Attenzione! Non è stato possibile salvare le modifiche. Riprova pi&ugrave; tardi")
								};
								scope.saveaction()(successSaveCallback, errorSaveCallback);	
								
							}, function errorCallback(response) {
								deletingDoc.deleting = false;
								alert('Si è verificato un errore. Si prega di riprovare');
							}
						);
					};
					warningService.showModal("warning", "Il documento sarà eliminato e non potrà essere recuperato. Sei sicuro di voler continuare?", "Annulla", "Elimina", null, confirmDeleteDoc );
				}
			};
			
			scope.loadWorksiteDoc = function(event) {
				if( !event ) { return; }
	        	var file = null;
				if( event.target ) { // select file
		        	if( !event.target.files ) return;
		        	if( !event.target.files.length ) return;
					file = event.target.files[0];
	        	}
	        	
				if( file ) { // new selected or dropped file
					var re = /(?:\.([^.]+))?$/;
					var ext = re.exec(file.name)[1]; 
					if( ext === undefined ) {
						elem.find("input:file").val(null);
						alert("Il nome del file non ha estensione. Aggiungi l'estensione per identificare il tipo di file.");
						return;
					} 
					var filesize = ((file.size/1024)/1024).toFixed(1); // MB
					if( filesize > 20 ) {
						elem.find("input:file").val(null);
						alert("Il documento ("+filesize+"Mb) non deve superare i 20Mb di dimensione.");
						return;
					}
					scope.editWorksiteDoc(null, file);
				} 
			};

			scope.asyncSaveAction = function(successCallback, errorCallback) {
				if( scope.saveaction ) {
					scope.saveaction()(successCallback, errorCallback);
				}
			};

			elem.find("input:file").bind('change', scope.loadWorksiteDoc);
		}
	};
}]);

